import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import AdminNavbar from '../components/navbar/AdminNavbar';

const AdminLayout = () => {
    const [isNavExpanded, setIsNavExpanded] = useState(true);

    const toggleNavbar = () => {
        setIsNavExpanded(!isNavExpanded);
    };

    return (
        <div className="flex flex-grow">
            <aside className={`transition-width duration-300 pr-5`}>
                <AdminNavbar
                    isExpanded={isNavExpanded}
                    toggleNavbar={toggleNavbar}
                />
            </aside>
            <div className="flex flex-col w-full h-full mr-5">
                <Outlet />
            </div>
        </div>
    );
};

export default AdminLayout;
