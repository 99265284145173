import React, { useState, useEffect } from "react";
import Box3 from "./Box3";
import { Typography, Button, IconButton } from "@mui/material";
import plusImage from '../../../assets/images/ui/7. add.png';
import minusImage from '../../../assets/images/ui/8. minus.png';

const parseQuiz = (text, answer, handleInputChange, baseIndex, download) => {
    const parts = text.split('**');
    return parts.reduce((acc, part, index) => {
        if (index === 0) {
            return [part];
        }
        if (download) {
            return [
                ...acc,
                    <span key={baseIndex + index - 1}  style={{textDecoration:'underline', textUnderlineOffset:'5px'}}>{answer[baseIndex + index - 1] || ''}</span>,
                part
            ];
        }        
        return [
            ...acc,
            <input
                key={baseIndex + index - 1}
                style={{ flex: '1', backgroundColor: '', border: '1px solid #000', width: '300px' }}
                type="text"
                value={answer[baseIndex + index - 1] || ''}
                onChange={(e) => handleInputChange(baseIndex + index - 1, e)}
            />,
            part
        ];
    }, []);
};

export default function QuizBox5({ title, content, quizlist, answer, setAnswer, handleInputChange, download }) {
    const [additionalQuizzes, setAdditionalQuizzes] = useState([]);
    
    const addInput = () => {
        const newInputs = [...additionalQuizzes, quizlist.length * 2 + additionalQuizzes.length];
        setAdditionalQuizzes(newInputs);
        setAnswer([...answer, '', '']);
    };

    useEffect(() => {
        const additionalInputs = (answer.length - quizlist.length * 2) / 2;
        setAdditionalQuizzes(Array.from({ length: additionalInputs }, (_, i) => quizlist.length * 2 + i * 2));
    }, [answer, quizlist.length]);

    const removeInput = (index) => {
        const newAdditionalQuizzes = [...additionalQuizzes];
        newAdditionalQuizzes.splice(index, 1);
        setAdditionalQuizzes(newAdditionalQuizzes);

        const baseIndex = quizlist.length * 2 + index * 2;
        const newAnswer = [...answer];
        newAnswer.splice(baseIndex, 2);
        setAnswer(newAnswer);
    };

    const combinedContent = [
        <Typography key="main-content" sx={{fontSize:'20pt', mb:1}}>{content}</Typography>,
        ...quizlist.map((q, index) => (
            <div key={index} style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                <Typography sx={{fontSize:'20pt', mb:1}}>{parseQuiz(q, answer, handleInputChange, index * 2, download)}</Typography>
            </div>
        )),
        ...additionalQuizzes.map((inputIndex, index) => (
            <div key={inputIndex} style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                <Typography sx={{fontSize:'20pt', mb:1}}>{parseQuiz(quizlist[0], answer, handleInputChange, inputIndex, download)}</Typography>
                {!download &&
                    <IconButton
                        onClick={() => removeInput(index)}
                        sx={{
                            borderRadius: '100px',
                        }}
                    >
                        <img src={minusImage} width={24} style={{filter:'invert(27%) sepia(85%) saturate(6919%) hue-rotate(354deg) brightness(99%) contrast(127%)'}} />
                        <Typography sx={{color:'#000', fontSize:'16pt', ml:1}}>삭제</Typography>
                    </IconButton>
            }
            </div>
        ))
    ];

    return (
        <>
            <Box3 title={title} content={combinedContent}>
                {!download &&
                    <IconButton onClick={addInput}>
                        <img src={plusImage} width={24} style={{filter:'invert(45%) sepia(82%) saturate(1857%) hue-rotate(161deg) brightness(94%) contrast(101%)'}} />
                        <Typography sx={{color:'#000', fontSize:'16pt', ml:1}}>추가</Typography>
                    </IconButton>
                }
            </Box3>
        </>
    );
}
