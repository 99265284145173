import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { loggingSOFAccess } from '../../services/SOFLogServices';

export const useCustomPrompt = (message, when) => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const handleNavigation = (event) => {
            if (when) {
                // Prevent default navigation
                event.preventDefault();
                // Show confirmation dialog
                if (window.confirm(message)) {
                    // Allow navigation by invoking navigate with a URL
                    console.log('loggingSOFAccess3');
                    loggingSOFAccess('exit');
                    navigate(event.target.href);
                }
            }
        };

        // Attach event listeners to all links
        const links = document.querySelectorAll('a');
        links.forEach((link) =>
            link.addEventListener('click', handleNavigation),
        );

        return () => {
            links.forEach((link) =>
                link.removeEventListener('click', handleNavigation),
            );
        };
    }, [when, message, navigate]);

    // Optionally handle the case for browser back/forward navigation
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (when) {
                event.preventDefault();
                event.returnValue = message;
            }
        };

        const handleUnload = () => {
            if (when) {
                console.log('User is leaving the page');
                console.log('loggingSOFAccess4');
                loggingSOFAccess('exit');
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('unload', handleUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('unload', handleUnload);
        };
    }, [when, message]);
};
