import React from 'react';
import { useNavigate } from 'react-router-dom';

import '../../index.css';

import {
    AppBar,
    Box,
    Toolbar,
    Typography,
    Button,
    Divider,
} from '@mui/material';

const NoAuthenticatedHeader = () => {
    const navigate = useNavigate();

    const buttonStyle = {
        color: 'black',
        fontSize: '24px',
        fontWeight: '400',
    };

    const customDivider = () => {
        return (
            <div className="px-10">
                <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    style={{
                        border: '1px solid #ABABAB',
                        height: '12px',
                    }}
                />
            </div>
        );
    };

    return (
        <div className="flex flex-col">
            <Box sx={{ flexGrow: 1 }}>
                <AppBar
                    position="static"
                    style={{
                        backgroundColor: 'rgba(0,0,0, 0)',
                        boxShadow: 'none',
                        borderBottom: '1px solid #D9D9D9',
                    }}
                    // elevation={0}
                    // boxshadow="none"
                >
                    <Toolbar>
                        <Button color="inherit" onClick={() => navigate('/')}>
                            <div className="flex flex-col">
                                <Typography
                                    style={{ fontWeight: '700', lineHeight: 1 }}
                                >
                                    <span
                                        style={{
                                            fontSize: '48px',
                                            display: 'block',
                                            color: 'black',
                                        }}
                                    >
                                        SAVE
                                    </span>
                                    <span
                                        style={{
                                            fontSize: '13px',
                                            fontWeight: '400',
                                            textTransform: 'none',
                                            color: 'black',
                                        }}
                                    >
                                        Yes, To Life Always
                                    </span>
                                </Typography>
                            </div>
                        </Button>

                        <div className="flex flex-grow" />
                        <div>{customDivider()}</div>
                        <Button
                            color="inherit"
                            className="mx-3"
                            style={buttonStyle}
                            onClick={() => navigate('/')}
                        >
                            홈
                        </Button>
                        <div>{customDivider()}</div>
                        <Button
                            color="inherit"
                            className="mx-3"
                            style={buttonStyle}
                            onClick={() => navigate('/board')}
                        >
                            게시판
                        </Button>
                        <div>{customDivider()}</div>
                        <Button
                            color="inherit"
                            className="mx-3"
                            style={buttonStyle}
                            onClick={() => navigate('/help')}
                        >
                            도움말
                        </Button>
                        <div>{customDivider()}</div>
                        <div className="flex flex-grow" />
                        <div>{customDivider()}</div>
                        <Button
                            color="inherit"
                            className="mx-3"
                            style={buttonStyle}
                            onClick={() => navigate('/login')}
                        >
                            로그인
                        </Button>
                        <div>{customDivider()}</div>
                        <div className="flex flex-grow" />
                    </Toolbar>
                </AppBar>
            </Box>
        </div>
    );
};

export default NoAuthenticatedHeader;
