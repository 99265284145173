import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
} from '@mui/material';

import { fetchUsersInfo } from '../../services/AdminServices';

function createData(id, password, phone, birth) {
    return { id, password, phone, birth };
}

const UsersInfo = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [visibleUsers, setVisibleUsers] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    useEffect(() => {
        fetchUsersInfo().then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    createRows(data);
                    console.log(data);
                });
            } else {
                alert('Failed to fetch users info');
            }
        });
    }, []);

    const createRows = (usersInfo) => {
        const rows = [];
        usersInfo.forEach((user) => {
            rows.push(
                createData(
                    user.id,
                    user.password,
                    user.phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'),
                    user.resident_number.slice(0, 6),
                ),
            );
        });
        setUsers(rows);
    };

    useEffect(() => {
        createVisibleRows();
    }, [page, rowsPerPage, users]);

    const createVisibleRows = () => {
        const start = page * rowsPerPage;
        const end = start + rowsPerPage;
        setVisibleUsers(users.slice(start, end));
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <div className="flex w-full flex-col">
            <div className="mb-7">
                <Typography fontSize="36px" fontWeight="700">
                    회원 정보
                </Typography>
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">아이디</TableCell>
                            <TableCell align="center">비밀번호</TableCell>
                            <TableCell align="center">연락처</TableCell>
                            <TableCell align="center">생년월일</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {visibleUsers.map((user) => (
                            <TableRow
                                key={user.id}
                                sx={{
                                    '&:last-child td, &:last-child th': {
                                        border: 0,
                                    },
                                }}
                            >
                                <TableCell align="center">{user.id}</TableCell>
                                <TableCell align="center">
                                    {user.password}
                                </TableCell>
                                <TableCell align="center">
                                    {user.phone}
                                </TableCell>
                                <TableCell align="center">
                                    {user.birth}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={users.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
            />
        </div>
    );
};
export default UsersInfo;
