import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { useCustomPrompt } from '../components/hook/usePrompt';

const MainLayout = () => {
    const location = useLocation();
    const [isSurveyPage, setIsSurveyPage] = useState(false);

    useEffect(() => {
        setIsSurveyPage(
            location.pathname.includes('/survey') ||
                location.pathname.includes('/SOF')
        );
    }, [location.pathname]);

    let padding = 'p-5';
    if (location.pathname.includes('/admin/activityinfo')) {
        padding = 'pt-5';
    } else if (location.pathname.includes('/survey')) {
        padding = '';
    } else if (location.pathname.includes('/download')) {
        padding = '';
    }

    useCustomPrompt('페이지를 나가시겠습니까?', isSurveyPage);

    return (
        <div className="flex flex-col min-h-screen relative">
            <Header />
            <div className={`flex-grow w-full ${padding}`}>
                <Outlet />
            </div>
            <Footer />
        </div>
    );
};

export default MainLayout;
