import React from 'react';
import Box3 from './Box3';
import QuizBox3 from './QuizBox3.5';
import YouTube from 'react-youtube';
import Typography from '@mui/material/Typography';
import MoviePlayer from '../Module/MoviePlayer';


const parsecontent = (content) => {
  return content.split('\n').map((line, index) => {
      const parts = line.split('*');
      return (
          <Typography key={index} sx={{fontSize:'20pt', mb: 1}}>
              {parts.map((part, idx) => (
                  idx % 2 === 1 ? <u key={idx} style={{textUnderlineOffset:'5px'}}>{part}</u> : part
              ))}
              <br />
          </Typography>
      );
  });
}

export default function Quiz5Module ({from_, content, subtitle, subcontent, quiz, answer, setAnswer, handleInputChange, download }) {
  const components = [];
  const parts = content.split('\n');

  parts.forEach((part, index) => {
    if (part.includes('!@#')) {
      components.push(
        <MoviePlayer index={from_} autoplay={false} />
      )
    } else if (part.includes('$%^')) {
    //   components.push(<Box3 key={index} title={'실습'} quizlist={quiz.map((sentence, idx)=>(
    //     <div key={idx}>
    //       {parseQuiz(sentence)}
    //     </div>
    // ))} />);
      components.push(<Box3 key={index} title={subtitle.trim()} content={parsecontent(subcontent.trim())} />);
    } else {
      components.push(<Typography key={index} sx={{fontSize:'20pt', mb:1}} >{part}</Typography>);
    }
  });
  components.push(<QuizBox3 key={parts.length} title={'실습'} quizlist={quiz} setAnswer={setAnswer} answer={answer} handleInputChange={handleInputChange} download={download} />)
  return <div style={{display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',  // Horizontal center
    justifyContent: 'center',  // Vertical center
    width: '100%',}}>{components}</div>;
};