import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import MenuIcon from '@mui/icons-material/Menu';

import { Button, Typography, styled, Tooltip, Box } from '@mui/material';

const AdminNavbar = ({ isExpanded, toggleNavbar }) => {
    const navigate = useNavigate();

    return (
        <>
            <div>
                <Tooltip title="Toggle Navbar" arrow>
                    <Button onClick={toggleNavbar}>
                        <MenuIcon fontSize="large" color="black" />
                    </Button>
                </Tooltip>
            </div>

            <div
                className="flex flex-col justify-center transition-width duration-300"
                style={{ backgroundColor: '#526C6F', color: 'white' }}
            >
                {isExpanded ? (
                    <List
                        component="div"
                        aria-labelledby="nested-list-subheader"
                        sx={{ minWidth: '200px' }}
                    >
                        <ListItemButton
                            onClick={() => navigate(`accessinfo`)}
                            sx={{ mb: 2 }}
                        >
                            <Typography fontSize="24px" fontWeight="400">
                                접속기록
                            </Typography>
                        </ListItemButton>
                        <ListItemButton
                            onClick={() => navigate(`surveyinfo`)}
                            sx={{ mb: 2 }}
                        >
                            <Typography fontSize="24px" fontWeight="400">
                                설문조사
                            </Typography>
                        </ListItemButton>
                        <ListItemButton
                            onClick={() => navigate(`homeworkinfo`)}
                            sx={{ mb: 2 }}
                        >
                            <Typography fontSize="24px" fontWeight="400">
                                과제
                            </Typography>
                        </ListItemButton>
                    </List>
                ) : null}
            </div>
        </>
    );
};

export default AdminNavbar;
