import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
    Button,
    TextField,
    Typography,
    Divider,
    InputAdornment,
    FormControl,
    InputLabel,
    OutlinedInput,
} from '@mui/material';

import { AuthContext } from '../../core/auth';
import { UserContext } from '../../core/user';

import { fetchSignIn } from '../../services/AuthServices';

import journey_bro from '../../assets/images/etc/23_Journey_bro.png';

const Login = () => {
    const navigate = useNavigate();

    const { authState, setAuthState } = useContext(AuthContext);
    const { userState, setUserState } = useContext(UserContext);

    const [id, setId] = useState('');
    const [password, setPassword] = useState('');

    const [idError, setIdError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [isError, setIsError] = useState(false);

    const onClickSignIn = async () => {
        if (validate()) {
            setAuthState({ isLoading: true });
            await fetchSignIn(id, password)
                .then((response) => {
                    if (response.ok) {
                        setUserState({ isLoading: true });
                        setAuthState({
                            isAuthenticated: true,
                            isLoading: false,
                        });
                        setPassword('');

                        response.json().then((data) => {
                            if (data.is_admin) {
                                navigate('/');
                                navigate(0);
                            } else {
                                if (data.isFirstLogin) {
                                    navigate('/help');
                                    navigate(0);
                                } else {
                                    navigate('/');
                                    navigate(0);
                                }
                            }
                        });
                    } else if (response.status === 400) {
                        setIsError(true);
                        // alert('Invalid id or password.');
                    } else {
                        alert('Unknown error. Please try again later.');
                    }
                })
                .catch((error) => {
                    alert('Network error. Please try again later.');
                });
            setAuthState({ isLoading: false });
        }
    };

    const validate = () => {
        const isValidId = validateId();
        const isValidPassword = validatePassword();

        return isValidId && isValidPassword;
    };

    const validateId = () => {
        if (id.length === 0) {
            setIdError('Please enter your ID.');
            return false;
        }
        setIdError('');
        return true;
    };

    const validatePassword = () => {
        if (password.length === 0) {
            setPasswordError('Please enter your password.');
            return false;
        }
        setPasswordError('');
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault(); // 폼의 기본 제출 동작 방지
        onClickSignIn();
    };

    return (
        <div className="flex w-full flex-col">
            <div className="flex flex-col w-full items-center justify-center ">
                <img
                    src={journey_bro}
                    style={{ width: '272px', height: '255' }}
                />
                <Typography
                    className="pb-7"
                    style={{ fontSize: '28px', fontWeight: '400' }}
                >
                    세이브(SAVE)에 오신 것을 환영합니다!
                </Typography>
                <div>
                    <Typography
                        className="pb-5"
                        style={{
                            textAlign: 'center',
                            fontSize: '20px',
                            fontWeight: '400',
                        }}
                    >
                        세이브는 암환자 분들께서 경험하실 수 있는 마음의
                        어려움을 다루기 위한 심리 교육 프로그램입니다.
                    </Typography>
                    <Typography
                        className="pb-16"
                        style={{
                            textAlign: 'center',
                            fontSize: '15px',
                            fontWeight: '400',
                        }}
                    >
                        *세이브는 “삶에게 언제나 ‘네’라고 응답하다”라는 뜻의
                        이탈리아어 {'('}
                        <span style={{ fontWeight: '700' }}>S</span>i,&nbsp;
                        <span style={{ fontWeight: '700' }}>A</span>lla&nbsp;
                        <span style={{ fontWeight: '700' }}>V</span>ieta Sempr
                        <span style={{ fontWeight: '700' }}>e</span>, Yes, To
                        Life Always{')'}의 약자입니다.
                    </Typography>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="grid items-center justify-center mb-5">
                    <OutlinedInput
                        id="filled-start-adornment"
                        variant="standard"
                        style={{
                            border: '1px solid #000000',
                            borderRadius: '20px',
                            height: '40px',
                        }}
                        startAdornment={
                            <InputAdornment position="start">
                                ID |{' '}
                            </InputAdornment>
                        }
                        fullWidth={true}
                        onChange={(e) => setId(e.target.value)}
                    />
                </div>
                <div className="grid items-center justify-center mb-5">
                    <OutlinedInput
                        id="filled-start-adornment"
                        variant="standard"
                        style={{
                            border: '1px solid #000000',
                            borderRadius: '20px',
                            height: '40px',
                        }}
                        startAdornment={
                            <InputAdornment position="start">
                                PW |{' '}
                            </InputAdornment>
                        }
                        fullWidth={true}
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                {isError && (
                    <div className="flex w-full justify-center mb-5">
                        <Typography color="error">
                            아이디 혹은 비밀번호가 일치하지 않습니다. 다시
                            입력해주세요.
                        </Typography>
                    </div>
                )}
                <div className="flex items-center justify-center mb-5">
                    <Button
                        tabIndex="submit"
                        variant="contained"
                        style={{
                            backgroundColor: '#526C6F',
                            color: 'white',
                            fontSize: '20px',
                            fontWeight: '700',
                            width: '255px',
                            height: '40px',
                            borderRadius: '20px',
                        }}
                        onClick={onClickSignIn}
                    >
                        로그인
                    </Button>
                </div>
            </form>
            <div className="flex flex-row items-center justify-center">
                <Button
                    color="primary"
                    style={{
                        textDecoration: 'underline',
                        textUnderlineOffset: '4px',
                        fontWeight: 'bold',
                        color: '#000000',
                    }}
                    className="mr-10"
                    onClick={() => navigate('/find-Id')}
                >
                    아이디 찾기
                </Button>

                <Button
                    color="primary"
                    style={{
                        textDecoration: 'underline',
                        textUnderlineOffset: '4px',
                        fontWeight: 'bold',
                        color: '#000000',
                    }}
                    className="mx-10"
                    onClick={() => navigate('/find-Password')}
                >
                    비밀번호 찾기
                </Button>

                <Button
                    color="primary"
                    style={{
                        textDecoration: 'underline',
                        textUnderlineOffset: '4px',
                        fontWeight: 'bold',
                        color: '#000000',
                    }}
                    className="ml-10"
                    onClick={() => navigate('/signup/agreement')}
                >
                    회원가입
                </Button>
            </div>
        </div>
    );
};
export default Login;
