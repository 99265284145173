import React, { useEffect, useRef } from 'react';
import { IconButton, Typography } from '@mui/material';
import ReplayIcon from '../../../assets/images/ui/1. replay.png';
import S1_1_mp3 from '../../../assets/mp3/S/1-1.mp3';
import S1_2_mp3 from '../../../assets/mp3/S/1-2.mp3';
import S1_3_mp3 from '../../../assets/mp3/S/1-3.mp3';
import S1_4_mp3 from '../../../assets/mp3/S/1-4.mp3';
import S1_5_mp3 from '../../../assets/mp3/S/1-5.mp3';
import S1_6_mp3 from '../../../assets/mp3/S/1-6.mp3';
import S1_7_mp3 from '../../../assets/mp3/S/1-7.mp3';
import S2_1_mp3 from '../../../assets/mp3/S/2-1.mp3';
import S2_2_mp3 from '../../../assets/mp3/S/2-2.mp3';
import S2_3_mp3 from '../../../assets/mp3/S/2-3.mp3';
import S2_4_mp3 from '../../../assets/mp3/S/2-4.mp3';
import S2_5_mp3 from '../../../assets/mp3/S/2-5.mp3';
import S2_6_mp3 from '../../../assets/mp3/S/2-6.mp3';
import S2_7_mp3 from '../../../assets/mp3/S/2-7.mp3';
import S2_8_mp3 from '../../../assets/mp3/S/2-8.mp3';
import O1_1_mp3 from '../../../assets/mp3/O/1-1.mp3';
import O1_2_mp3 from '../../../assets/mp3/O/1-2.mp3';
import O1_3_mp3 from '../../../assets/mp3/O/1-3.mp3';
import O1_4_mp3 from '../../../assets/mp3/O/1-4.mp3';
import O1_5_mp3 from '../../../assets/mp3/O/1-5.mp3';
import O1_6_mp3 from '../../../assets/mp3/O/1-6.mp3';
import O1_7_mp3 from '../../../assets/mp3/O/1-7.mp3';
import O1_8_mp3 from '../../../assets/mp3/O/1-8.mp3';
import O2_1_mp3 from '../../../assets/mp3/O/2-1.mp3';
import O2_2_mp3 from '../../../assets/mp3/O/2-2.mp3';
import O2_3_mp3 from '../../../assets/mp3/O/2-3.mp3';
import O2_4_mp3 from '../../../assets/mp3/O/2-4.mp3';
import O2_5_mp3 from '../../../assets/mp3/O/2-5.mp3';
import O2_6_mp3 from '../../../assets/mp3/O/2-6.mp3';
import O2_7_mp3 from '../../../assets/mp3/O/2-7.mp3';
import O2_8_mp3 from '../../../assets/mp3/O/2-8.mp3';
import F1_1_mp3 from '../../../assets/mp3/F/1-1.mp3';
import F1_2_mp3 from '../../../assets/mp3/F/1-2.mp3';
import F1_3_mp3 from '../../../assets/mp3/F/1-3.mp3';
import F1_4_mp3 from '../../../assets/mp3/F/1-4.mp3';
import F1_5_mp3 from '../../../assets/mp3/F/1-5.mp3';
import F1_6_mp3 from '../../../assets/mp3/F/1-6.mp3';
import F1_7_mp3 from '../../../assets/mp3/F/1-7.mp3';
import F1_8_mp3 from '../../../assets/mp3/F/1-8.mp3';
import F1_9_mp3 from '../../../assets/mp3/F/1-9.mp3';
import F2_1_mp3 from '../../../assets/mp3/F/2-1.mp3';
import F2_2_mp3 from '../../../assets/mp3/F/2-2.mp3';
import F2_3_mp3 from '../../../assets/mp3/F/2-3.mp3';
import F2_4_mp3 from '../../../assets/mp3/F/2-4.mp3';
import F2_5_mp3 from '../../../assets/mp3/F/2-5.mp3';
import F2_6_mp3 from '../../../assets/mp3/F/2-6.mp3';
import F2_7_mp3 from '../../../assets/mp3/F/2-7.mp3';
import F2_8_mp3 from '../../../assets/mp3/F/2-8.mp3';

// mp3 파일 목록과 해당하는 문자열 이름을 짝지어 놓는 객체
const mp3Map = {
    S1_1_mp3: S1_1_mp3,
    S1_2_mp3: S1_2_mp3,
    S1_3_mp3: S1_3_mp3,
    S1_4_mp3: S1_4_mp3,
    S1_5_mp3: S1_5_mp3,
    S1_6_mp3: S1_6_mp3,
    S1_7_mp3: S1_7_mp3,
    S2_1_mp3: S2_1_mp3,
    S2_2_mp3: S2_2_mp3,
    S2_3_mp3: S2_3_mp3,
    S2_4_mp3: S2_4_mp3,
    S2_5_mp3: S2_5_mp3,
    S2_6_mp3: S2_6_mp3,
    S2_7_mp3: S2_7_mp3,
    S2_8_mp3: S2_8_mp3,
    O1_1_mp3: O1_1_mp3,
    O1_2_mp3: O1_2_mp3,
    O1_3_mp3: O1_3_mp3,
    O1_4_mp3: O1_4_mp3,
    O1_5_mp3: O1_5_mp3,
    O1_6_mp3: O1_6_mp3,
    O1_7_mp3: O1_7_mp3,
    O1_8_mp3: O1_8_mp3,
    O2_1_mp3: O2_1_mp3,
    O2_2_mp3: O2_2_mp3,
    O2_3_mp3: O2_3_mp3,
    O2_4_mp3: O2_4_mp3,
    O2_5_mp3: O2_5_mp3,
    O2_6_mp3: O2_6_mp3,
    O2_7_mp3: O2_7_mp3,
    O2_8_mp3: O2_8_mp3,
    F1_1_mp3: F1_1_mp3,
    F1_2_mp3: F1_2_mp3,
    F1_3_mp3: F1_3_mp3,
    F1_4_mp3: F1_4_mp3,
    F1_5_mp3: F1_5_mp3,
    F1_6_mp3: F1_6_mp3,
    F1_7_mp3: F1_7_mp3,
    F1_8_mp3: F1_8_mp3,
    F1_9_mp3: F1_9_mp3,
    F2_1_mp3: F2_1_mp3,
    F2_2_mp3: F2_2_mp3,
    F2_3_mp3: F2_3_mp3,
    F2_4_mp3: F2_4_mp3,
    F2_5_mp3: F2_5_mp3,
    F2_6_mp3: F2_6_mp3,
    F2_7_mp3: F2_7_mp3,
    F2_8_mp3: F2_8_mp3,
};

// MP3 플레이어 컴포넌트
const MP3Player = ({ mp3 }) => {
    const audioRef = useRef(null); // audio 요소에 접근하기 위한 ref 생성

    // 다시 듣기 버튼 클릭 시 오디오 재생 함수
    const handleReplay = () => {
        if (audioRef.current) {
            audioRef.current.currentTime = 0; // 재생 시작 시간을 0으로 설정
            audioRef.current.play()
            .then(() => {
                console.log('Audio is playing');
            })
            .catch((error) => {
                console.error('자동 재생은 브라우저에서 자동 차단 됩니다.', error);
            });
        }
    };

    useEffect(() => {
        setTimeout(() => {
            handleReplay(); // 컴포넌트가 마운트되면 바로 오디오 재생
        }, 0);
    }, [audioRef, mp3]);

    // mp3 문자열을 mp3Map에서 실제 파일 경로로 변환
    const mp3Src = mp3Map[mp3] || null;

    return (
        <div>
            {/* mp3가 유효한 경우에만 오디오 요소를 렌더링 */}
            {mp3Src ? <audio ref={audioRef} src={mp3Src} style={{ display: 'none' }} /> : null}
            <IconButton
                sx={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                }}
                onClick={handleReplay} // 클릭 핸들러 추가
            >
                <div style={{ display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={ReplayIcon} alt="Replay" style={{ width: '31px'}} />
                    <Typography sx={{ fontSize: '16px', color: '#000' }}>
                        다시 듣기
                    </Typography>
                </div>
            </IconButton>
        </div>
    );
};

export default MP3Player;
