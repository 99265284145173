import React from 'react';
import Box3 from './Box3';
import QuizBox3 from './QuizBox3';
import Typography from '@mui/material/Typography';

const parsecontent = (content) => {
  return content.split('\n').map((line, index) => {
      const parts = line.split('*');
      return (
          <Typography key={index} sx={{fontSize:'20pt', marginTop:'10px'}}>
              {parts.map((part, idx) => (
                  idx % 2 === 1 ? <u key={idx} style={{textUnderlineOffset:8}}>{part}</u> : part
              ))}
              <br />
          </Typography>
      );
  });
}

export default function Quiz4Module ({content, subtitle, subcontent, quiz, answer, setAnswer, handleInputChange }) {
  const components = [];
  const parts = content.split('\n');

  parts.forEach((part, index) => {
    if (part.includes('!@#')) {
      components.push(<Box3 key={index} title={subtitle.trim()} content={parsecontent(subcontent.trim())} />);
    } else if (part.includes('$%^')) {
    //   components.push(<Box3 key={index} title={'실습'} quizlist={quiz.map((sentence, idx)=>(
    //     <div key={idx}>
    //       {parseQuiz(sentence)}
    //     </div>
    // ))} />);
      components.push(<QuizBox3 key={index} title={'실습'} quizlist={quiz} setAnswer={setAnswer} answer={answer} handleInputChange={handleInputChange} />);
    } else {
      components.push(<p key={index} style={{fontSize:'20pt', marginTop:'10px'}} >{part}</p>);
    }
  });

  return <div>{components}</div>;
};