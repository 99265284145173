import React, { useState, useEffect } from 'react';
import { 
    fetchGetAllS1,
    fetchGetAllS2,
    fetchGetAllO1,
    fetchGetAllO2,
    fetchGetAllF1,
    fetchGetAllF2,
    fetchUpdateSOF,
} from '../../services/SOFServices';
import { Button, TextField, Box, Typography } from '@mui/material';

const ModuleUpdate = () => {
    const [s1, setS1] = useState([]);
    const [s2, setS2] = useState([]);
    const [o1, setO1] = useState([]);
    const [o2, setO2] = useState([]);
    const [f1, setF1] = useState([]);
    const [f2, setF2] = useState([]);
    const [editModules, setEditModules] = useState([]);
    const [isWhat, setIsWhat] = useState('');

    useEffect(() => {
        handleAny();
    }, []);

    const handleAny = () => {
        fetchGetAllS1().then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    setS1(data);
                });
            }
        });
        fetchGetAllS2().then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    setS2(data);
                });
            }
        });
        fetchGetAllO1().then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    setO1(data);
                });
            }
        });
        fetchGetAllO2().then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    setO2(data);
                });
            }
        });
        fetchGetAllF1().then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    setF1(data);
                });
            }
        });
        fetchGetAllF2().then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    setF2(data);
                });
            }
        });
    }

    const handleInputChange = (index, field, value) => {
        const newModules = [...editModules];
        newModules[index] = { ...newModules[index], [field]: value };
        setEditModules(newModules);
    };

    const handleEditClick = (modules, sof) => {
        setIsWhat(sof);
        setEditModules(modules);
    };

    const handleUpdateModule = async () => {
        editModules.forEach(async (module) => {
            try {
                const response = await fetchUpdateSOF(isWhat, module);
                if (response.ok) {
                    console.log('Update success');
                }
            } catch (error) {
                console.error('Error updating module:', error);
            }
        });
        handleAny();
    }

    return (
        <div style={{width:'100%'}}>
            <Button variant={isWhat === 's1' ? 'contained' : 'text'} onClick={() => handleEditClick(s1, 's1')}>S1</Button>
            <Button variant={isWhat === 's2' ? 'contained' : 'text'} onClick={() => handleEditClick(s2, 's2')}>S2</Button>
            <Button variant={isWhat === 'o1' ? 'contained' : 'text'} onClick={() => handleEditClick(o1, 'o1')}>O1</Button>
            <Button variant={isWhat === 'o2' ? 'contained' : 'text'} onClick={() => handleEditClick(o2, 'o2')}>O2</Button>
            <Button variant={isWhat === 'f1' ? 'contained' : 'text'} onClick={() => handleEditClick(f1, 'f1')}>F1</Button>
            <Button variant={isWhat === 'f2' ? 'contained' : 'text'} onClick={() => handleEditClick(f2, 'f2')}>F2</Button>
            <Button variant='contained' sx={{ position: 'absolute', right: '10%' }} onClick={() => handleUpdateModule()}>저장</Button>
            {editModules.length > 0 ? editModules.map((module, index) => (
                <Box key={index} sx={{ mt:2, marginBottom: 2, border: '1px solid #000', padding: 2}}>
                    <Typography
                        variant="h1"
                        sx={{ marginBottom: 2 }}
                    >
                        {index+1} / {module.type}
                    </Typography>
                    <TextField
                        label="Title"
                        value={module.title}
                        onChange={(e) => handleInputChange(index, 'title', e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Content"
                        value={module.content}
                        disabled={module.content === ''}
                        onChange={(e) => handleInputChange(index, 'content', e.target.value)}
                        multiline
                        rows={4}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Subtitle"
                        value={module.subtitle}
                        disabled={module.subtitle === ''}
                        sx={{ backgroundColor: module.subtitle === '' ? '#f0f0f0' : '#fff' }}
                        onChange={(e) => handleInputChange(index, 'subtitle', e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Subcontent"
                        value={module.subcontent}
                        disabled={module.subcontent === ''}
                        sx={{ backgroundColor: module.subcontent === '' ? '#f0f0f0' : '#fff' }}
                        onChange={(e) => handleInputChange(index, 'subcontent', e.target.value)}
                        multiline
                        rows={2}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Quiz"
                        value={module.quiz.join('\n')}
                        disabled={module.quiz.length === 0 || module.quiz[0] === ''}
                        sx={{ backgroundColor: module.quiz.length === 0 || module.quiz[0] === '' ? '#f0f0f0' : '#fff' }}
                        onChange={(e) => handleInputChange(index, 'quiz', e.target.value.split('\n'))}
                        multiline
                        rows={3}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="From"
                        value={module.from_}
                        disabled={module.from_ === ''}
                        sx={{ backgroundColor: module.from_ === '' ? '#f0f0f0' : '#fff' }}
                        onChange={(e) => handleInputChange(index, 'from_', e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Blue"
                        value={module.blue}
                        onChange={(e) => handleInputChange(index, 'blue', e.target.value === 'true')}
                        fullWidth
                        margin="normal"
                    />
                </Box>
            )) : null}
        </div>
    );
}

export default ModuleUpdate;
