import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';

import PropTypes from 'prop-types';

import { CircularProgress } from '@mui/material';

import { UserContext } from '../../core/user';
import { AuthContext } from '../../core/auth';

const RedirectionRoute = () => {
    const { userState } = useContext(UserContext);
    const { authState } = useContext(AuthContext);

    if (userState?.isLoading) {
        return <CircularProgress />;
    }

    if (!authState.isAuthenticated) {
        return <Navigate to="/login" />;
    } else if (!userState.is_admin) {
        return <Navigate to="/sof" />;
    } else {
        return <Navigate to="/admin/home" />;
    }
};

RedirectionRoute.propTypes = {
    children: PropTypes.node,
};

export default RedirectionRoute;

// if (!userState) {
//     return <Navigate to="/login" />;
// } else if (!userState.is_admin) {
//     return <Navigate to="/home" />;
// } else {
//     return <Navigate to="/admin/usersinfo" />;
// }
