import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    Button,
} from '@mui/material';

import {
    fetchUsersInfo,
    fetchSurveyResult,
    fetchSurveyResultsTableColumn,
} from '../../services/AdminServices';

import { fetchSurveyExcel } from '../../services/ExcelServies';

function createData(
    uid,
    id,
    pre_result,
    post_result,
    s_result,
    o_result,
    f_result,
) {
    return {
        uid,
        id,
        pre_result,
        post_result,
        s_result,
        o_result,
        f_result,
    };
}

const SurveyInfo = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visibleUsers, setVisibleUsers] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [columns, setColumns] = useState({
        survey_count: 0,
        s2_count: 0,
        o2_count: 0,
        f2_count: 0,
    });

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchSurveyResultsTableColumn();

            if (response.ok) {
                response.json().then((data) => {
                    setColumns(data);
                });
            }

            const response1 = await fetchSurveyResult();
            if (response1.ok) {
                response1.json().then((data) => {
                    console.log('result', data);
                    createRows(data.result);
                });
            } else {
                alert('Failed to fetch users info');
            }
        };

        fetchData();
        setLoading(false);
    }, []);

    const createRows = (usersInfo) => {
        const rows = [];
        usersInfo.forEach((user) => {
            rows.push(
                createData(
                    user.uid,
                    user.id,
                    user.pre_result,
                    user.post_result,
                    user.s_survey,
                    user.o_survey,
                    user.f_survey,
                ),
            );
        });
        setUsers(rows);
    };

    useEffect(() => {
        createVisibleRows();
    }, [page, rowsPerPage, users]);

    const createVisibleRows = () => {
        const start = page * rowsPerPage;
        const end = start + rowsPerPage;
        setVisibleUsers(users.slice(start, end));
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const renderTableCells = (count) => {
        const cells = [];
        for (let i = 0; i < count; i++) {
            cells.push(
                <TableCell
                    align="center"
                    key={i}
                    style={{ border: '1px  solid #000000' }}
                >
                    {i + 1}번
                </TableCell>,
            );
        }
        cells.push(
            <TableCell align="center" style={{ border: '1px  solid #000000' }}>
                총점
            </TableCell>,
        );
        return cells;
    };

    const renderSurveyResult = (data, length) => {
        const cells = [];
        let sum = 0;
        if (data === null) {
            for (let i = 0; i < length; i++) {
                cells.push(
                    <TableCell
                        align="center"
                        key={i}
                        style={{ border: '1px  solid #000000' }}
                    ></TableCell>,
                );
            }
            cells.push(
                <TableCell
                    align="center"
                    style={{ border: '1px  solid #000000' }}
                ></TableCell>,
            );
        } else {
            for (let i = 0; i < length; i++) {
                cells.push(
                    <TableCell
                        align="center"
                        key={i}
                        style={{ border: '1px  solid #000000' }}
                    >
                        {data[i]}
                    </TableCell>,
                );
                sum += parseInt(data[i], 10);
            }
            cells.push(
                <TableCell
                    align="center"
                    style={{ border: '1px  solid #000000' }}
                >
                    {sum}
                </TableCell>,
            );
        }
        return cells;
    };

    const handleDownload = async () => {
        const response = await fetchSurveyExcel();
        if (response.ok) {
            console.log('Excel file downloaded successfully');
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'access_info.xlsx';
            a.click();
            window.URL.revokeObjectURL(url);
        } else {
            console.error('Failed to download Excel file');
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    } else {
        return (
            <div className="flex w-full flex-col pr-10">
                <div className="flex flex-row mb-7">
                    <Typography fontSize="36px" fontWeight="700">
                        설문조사
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={handleDownload}
                        style={{ marginLeft: '20px' }}
                    >
                        다운로드
                    </Button>
                </div>
                <div className="flex w-full flex-col">
                    <TableContainer
                        component={Paper}
                        // style={{ overflowX: 'auto' }}
                    >
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        align="center"
                                        style={{
                                            borderLeft: '1px  solid #000000',
                                            borderTop: '1px  solid #000000',
                                            borderBottom: '0px',
                                            borderRight: '1px  solid #000000',
                                        }}
                                    >
                                        아이디
                                    </TableCell>

                                    <TableCell
                                        align="center"
                                        colSpan={columns['survey_count'] + 1}
                                        style={{ border: '1px  solid #000000' }}
                                    >
                                        1차 설문
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        colSpan={columns['s2_count'] + 1}
                                        style={{ border: '1px  solid #000000' }}
                                    >
                                        S 모듈 만족도
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        colSpan={columns['o2_count'] + 1}
                                        style={{ border: '1px  solid #000000' }}
                                    >
                                        O 모듈 만족도
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        colSpan={columns['f2_count'] + 1}
                                        style={{ border: '1px  solid #000000' }}
                                    >
                                        F 모듈 만족도
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        colSpan={columns['survey_count'] + 1}
                                        style={{ border: '1px  solid #000000' }}
                                    >
                                        2차 설문
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        align="center"
                                        style={{
                                            borderLeft: '1px  solid #000000',
                                            borderTop: '0px  solid #000000',
                                            borderRight: '1px  solid #000000',
                                            borderBottom: '1px  solid #000000',
                                        }}
                                    ></TableCell>
                                    {renderTableCells(columns['survey_count'])}
                                    {renderTableCells(columns['s2_count'])}
                                    {renderTableCells(columns['o2_count'])}
                                    {renderTableCells(columns['f2_count'])}
                                    {renderTableCells(columns['survey_count'])}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {visibleUsers.map((user) => (
                                    <TableRow
                                        key={user.id}
                                        sx={{
                                            '&:last-child td, &:last-child th':
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        <TableCell
                                            align="center"
                                            style={{
                                                border: '1px  solid #000000',
                                            }}
                                        >
                                            {user.id}
                                        </TableCell>
                                        {renderSurveyResult(
                                            user.pre_result
                                                ? user.pre_result
                                                : null,
                                            columns['survey_count'],
                                        )}
                                        {renderSurveyResult(
                                            user.s_result
                                                ? user.s_result
                                                : null,
                                            columns['s2_count'],
                                        )}
                                        {renderSurveyResult(
                                            user.o_result
                                                ? user.o_result
                                                : null,
                                            columns['o2_count'],
                                        )}
                                        {renderSurveyResult(
                                            user.f_result
                                                ? user.f_result
                                                : null,
                                            columns['f2_count'],
                                        )}
                                        {renderSurveyResult(
                                            user.post_result
                                                ? user.post_result
                                                : null,
                                            columns['survey_count'],
                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10]}
                        component="div"
                        count={users.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                    />
                </div>
            </div>
        );
    }
};
export default SurveyInfo;
