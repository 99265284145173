import React, { useContext, useState, useEffect,} from 'react';
import { useParams, } from 'react-router-dom';

import { Button, Typography, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { fetchCommentByPid, fetchCommentContent, fetchDeleteCommentByCid, fetchUpdateCommentByCid } from '../../services/BoardServices';
import { UserContext } from '../../core/user';

const Comment = () => {
    const pid = useParams().pid;
    const { userState } = useContext(UserContext);
    const [content, setContent] = useState('');
    const [comments, setComment] = useState([]);
    const [cid, setCid] = useState(0);
    const [commentNick, setCommentNick] = useState('');
    const [currentComment, setCurrentComment] = useState('');
    const [showInputBox, setShowInputBox] = useState(false);

    const loadComment = () => {
        fetchCommentByPid(pid).then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    //console.log("commentsss: ", data);
                    setComment(data);
                });
            } else {
                //console.log('comment load error!');
                setComment([]);
            }
        });
    };

    useEffect(() => {
        loadComment();
    }, [comments]);

    const handleContent = (event) => {
        setContent(event.target.value);
    };

    const handletoSubmit = (event) => {
        event.preventDefault();

        const commentInfo = {
            pid: pid,
            nick: userState.nickname,
            content: content,
        }; 
        

        fetchCommentContent(commentInfo).then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    //console.log(data);
                });
            } else {
                console.log("comment save error");
            }
        }); 
        setContent('');
        loadComment();
    };

    const handletoDeleteComment = async (cid) => {
        if (window.confirm('해당 댓글을 삭제하시겠습니까?')) {
            try {
                await fetchDeleteCommentByCid(cid);
                loadComment();
            } catch (error) {
                console.log('comment delete error!', error);
            }
        }
    };

    const buttonStyle = {
        backgroundColor: '#526C6F',
        color: 'white',
        borderRadius: '15px',
        minWidth: '120px',
        height: '48px',
        fontSize: '24px',
        padding: '0 14px 0 14px',
    };



    const handleShowInputBox = (cid, nick, content) => {
        setShowInputBox(true);
        setCid(cid);
        setCommentNick(nick);
        setCurrentComment(content);

    };

    const handleInputChange = (event) => {
        setCurrentComment(event.target.value);
    };

    const handleCancelInput = () => {
        setShowInputBox(false);
        setCurrentComment('');
    };
    
    const handletoModifyComment = () => {
        if (window.confirm('해당 댓글을 수정하시겠습니까?')) {
            event.preventDefault();

            const commentInfo = {
                pid: pid,
                nick: commentNick,
                content: currentComment,
            };     

            fetchUpdateCommentByCid(cid, commentInfo).then((response) => {
                if (response.ok) {
                    response.json().then((data) => {
                        //console.log("modified comment send data: ", data);
                    });
                } else {
                    console.log('comment modi error!');
                }
            });

            setShowInputBox(false);
            setCurrentComment('');
            loadComment();
        }
    }

    return (
        <div>
            <div style={{marginTop: '30px'}}>
                <Typography variant="h6" > 댓글 </Typography>
                {comments.length === 0 ? (
                    <Typography variant="body1" style={{marginTop: '10px', color: '#d9d9d9' }}> 아직 댓글이 없습니다. </Typography>
                ) : (
                    <table style={{width: '100%', marginTop: '10px' }}>
                            {comments
                                .sort((a, b) => a.cid - b.cid)                    
                                .map((comment, index) => (
                                <tbody key = {index} style={{border: '1px solid #000000',}}>
                                    <tr>
                                        <td style={{paddingLeft: '10px', paddingTop: '10px', fontWeight: 'bold', width: '10%',}}>{comment.nick}</td>
                                        <td rowSpan={2}>{comment.content}</td>
                                        {(userState?.is_admin || userState?.nickname == comment.nick) && (
                                            <td rowSpan={2} style={{width:'8%', }}>
                                                <IconButton aria-label="edit" sx={{mr: 1, }} onClick={()=>handleShowInputBox(comment.cid, comment.nick, comment.content)}>
                                                    <EditIcon/>
                                                </IconButton>
                                                <IconButton aria-label="delete" sx={{mr: 1, }} onClick={()=>handletoDeleteComment(comment.cid)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </td>
                                        )}
                                    </tr>
                                    <tr>
                                        <td style={{paddingLeft: '10px', paddingBottom: '10px', width: '10%', color: 'gray', fontSize: '90%'}}>{comment.date.split('T')[0]}</td>
                                    </tr>
                                </tbody>

                            ))}
                    </table>
                )}
            </div>
            {showInputBox && (
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px', }}>
                            <Typography variant="h6" sx={{ mt: 2 }}> 댓글 수정 </Typography>
                            <textarea
                                type="text"
                                onChange={handleInputChange}
                                value={currentComment}
                                style={{ padding: '10px', border: '1px solid #000000', height: '50px', marginTop: '10px', paddingLeft: '10px'}}
                            />
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '15px' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={buttonStyle}
                                    onClick={handletoModifyComment}
                                >
                                    수정
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={buttonStyle}
                                    sx={{marginLeft: '10px'}}
                                    onClick={handleCancelInput}
                                >
                                    취소
                                </Button>
                            </div>
                        </div>
                    )}
            {userState != null && (
                <>
                    <Typography variant="h6" sx={{ mt: 2 }}> 댓글 작성 </Typography>
            
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px', border: '1px solid #000000'}}>
                        <Typography
                            sx={{ height: '50px', lineHeight: '50px', paddingLeft: '10px', }}
                        > 
                            {userState.nickname} 
                        </Typography>
                        <textarea
                            placeholder="내용(작성)"
                            onChange={handleContent}
                            value={content}
                            style={{ borderTop: '1px solid #000000', height: '150px', paddingLeft: '10px', resize: 'none'}}
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '15px' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            style={buttonStyle}
                            onClick={handletoSubmit}
                        >
                            등록
                        </Button>
                    </div>
                    
                </>
            )}
        </div>
    );
}

export default Comment;