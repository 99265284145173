import React, { useContext, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, TextField, Typography, Divider, Box } from '@mui/material';

import { fetchSignUp } from '../../services/AuthServices';

const Signup = () => {
    const navigate = useNavigate();

    const [id, setId] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [residentNumber, setResidentNumber] = useState([
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
    ]);
    const [nickname, setNickname] = useState('');
    const inputRefs = useRef([]);

    const handleChange = (index, value) => {
        const newResidentNumber = [...residentNumber];
        newResidentNumber[index] = value;
        setResidentNumber(newResidentNumber);
    };

    const handleKeyDown = (index, event) => {
        if (event.key === 'Backspace' && event.target.value === '') {
            inputRefs.current[index - 1]?.focus();
        } else if (
            event.target.value.length === 1 &&
            event.key !== 'Backspace'
        ) {
            if (index === 6) {
                // 뒷자리 첫 번째 숫자 입력 후 포커스 이동 중지
                return;
            }
            inputRefs.current[index + 1]?.focus();
        }
    };

    const onclickSignup = () => {
        if (password !== passwordConfirm) {
            alert('비밀번호가 일치하지 않습니다.');
            return;
        }

        const residentNumberString = residentNumber.join('');
        let singupInfo = {
            resident_number: residentNumberString,
            id: id,
            password: password,
            phone: phoneNumber,
            is_admin: false,
            nickname: nickname,
        };

        fetchSignUp(singupInfo).then((response) => {
            if (response.status === 200) {
                alert('회원가입이 완료되었습니다.');
                navigate('/');
            } else {
                alert('회원가입에 실패하였습니다.');
            }
        });
    };
    return (
        <div className="flex w-full">
            <div className="flex w-full items-center justify-center flex-col">
                <div className="flex items-center justify-center flex-col pb-6">
                    <div className="flex w-full">
                        <Typography
                            fontWeight="400"
                            fontSize="24px"
                            className="pb-2"
                        >
                            주민등록번호(앞자리) *
                        </Typography>
                    </div>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {residentNumber.slice(0, 6).map((number, index) => (
                            <TextField
                                key={index}
                                value={number}
                                onChange={(e) =>
                                    handleChange(index, e.target.value)
                                }
                                onKeyDown={(e) => handleKeyDown(index, e)}
                                inputRef={(input) =>
                                    (inputRefs.current[index] = input)
                                }
                                variant="outlined"
                                size="small"
                                inputProps={{ maxLength: 1 }}
                                style={{
                                    width: '48px',
                                    marginRight: index !== 5 ? '15px' : '10px',
                                }}
                            />
                        ))}
                        <span style={{ marginRight: '10px', fontSize: '30px' }}>
                            -
                        </span>
                        <TextField
                            key={6}
                            value={residentNumber[6]}
                            onChange={(e) => handleChange(6, e.target.value)}
                            onKeyDown={(e) => handleKeyDown(6, e)}
                            inputRef={(input) => (inputRefs.current[6] = input)}
                            variant="outlined"
                            size="small"
                            inputProps={{ maxLength: 1 }}
                            style={{ width: '40px', marginRight: '5px' }}
                        />
                        {(() => {
                            const spans = [];
                            for (let i = 0; i < 6; i++) {
                                spans.push(
                                    <span
                                        key={i}
                                        style={{
                                            marginRight: '5px',
                                            fontSize: '30px',
                                        }}
                                    >
                                        {i === 7 ? residentNumber[i] : '*'}
                                    </span>,
                                );
                            }
                            return spans;
                        })()}
                    </Box>
                </div>

                <div className="flex items-center justify-center flex-col pb-6">
                    <div className="flex w-full flex-row pb-2">
                        <Typography fontWeight="400" fontSize="24px">
                            아이디 *
                        </Typography>

                        <div className="grow"></div>
                        <Typography color="#191FBF">
                            영문자, 숫자만 입력가능. 최소 3자이상 입력하세요.
                        </Typography>
                    </div>
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        value={id}
                        style={{ width: '545px' }}
                        onChange={(e) => setId(e.target.value)}
                    />
                </div>

                <div className="flex items-center justify-center flex-col pb-6">
                    <div className="flex w-full flex-row pb-2">
                        <Typography fontWeight="400" fontSize="24px">
                            비밀번호 *
                        </Typography>
                        <div className="grow"></div>
                        <Typography color="#191FBF">
                            8~16자의 영문 대/소문자, 숫자, 특수문자를 사용해
                            주세요.
                        </Typography>
                    </div>
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        value={password}
                        type="password"
                        style={{ width: '545px' }}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>

                <div className="flex items-center justify-center flex-col pb-6">
                    <div className="flex w-full flex-row pb-2">
                        <Typography fontWeight="400" fontSize="24px">
                            비밀번호 확인 *
                        </Typography>
                    </div>
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        value={passwordConfirm}
                        type="password"
                        style={{ width: '545px' }}
                        onChange={(e) => setPasswordConfirm(e.target.value)}
                    />
                </div>

                <div className="flex items-center justify-center flex-col pb-6">
                    <div className="flex w-full flex-row pb-2">
                        <Typography fontWeight="400" fontSize="24px">
                            연락처 *
                        </Typography>
                        <div className="grow"></div>
                        <Typography color="#191FBF">
                            연락처 입력을 통해 아이디 찾기가 가능합니다.
                        </Typography>
                    </div>
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        value={phoneNumber}
                        style={{ width: '545px' }}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                </div>
                <div className="flex items-center justify-center flex-col pb-6">
                    <div className="flex w-full flex-row pb-2">
                        <Typography fontWeight="400" fontSize="24px">
                            닉네임 *
                        </Typography>
                    </div>
                    <div className="pb-5">
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            value={nickname}
                            style={{ width: '545px' }}
                            onChange={(e) => setNickname(e.target.value)}
                        />
                    </div>
                    <div className="flex w-full">
                        <div className="grow" />
                        <Button
                            variant="contained"
                            color="primary"
                            style={{
                                backgroundColor: '#526C6F',
                                color: 'white',
                                fontSize: '20px',
                                fontWeight: '700',

                                borderRadius: '20px',
                            }}
                            onClick={onclickSignup}
                        >
                            가입하기
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Signup;
