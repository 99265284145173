// Quiz3Module.js
import React from 'react';
import Box2 from './Box2';
import QuizBox2 from './QuizBox2';

export default function Quiz3Module ({ content, subtitle, subcontent, quiz, answer, setAnswer, handleInputChange }) {
  const components = [];
  const parts = content.split('\n');
  let inputIndex = 0;

  parts.forEach((part, index) => {
    if (part.includes('!@#')) {
      components.push(<Box2 key={index} title={subtitle.trim()} content={subcontent.trim()} />);
    } else if (part.includes('$%')) {
      components.push(
        <input 
          style={{ flex: '1', backgroundColor: '', border: '1px solid #000' }} 
          type="text" 
          value={answer[0] || ''} 
          onChange={(e) => handleInputChange(0, e)}
        />
      );     
      inputIndex++;
    } else if (part.includes('^&*')) {
      const example = part.split('^&*')[1].trim();
      components.push(
        <QuizBox2 
          key={index} 
          content={example} 
          quizlist={quiz} 
          answer={answer} 
          setAnswer={setAnswer}
          handleInputChange={handleInputChange} 
          inputIndex={inputIndex} 
        />
      );
      inputIndex += quiz.length;  // Assuming `quiz` is an array of quiz items, so we increment `inputIndex` by its length.
    } else {
      components.push(<p key={index}>{part}</p>);
    }
  });

  return <div>{components}</div>;
};
