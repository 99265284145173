import AppConfig from '../core/config';

const fetchMypageResult = async () => {
    const url = `${AppConfig.api_base_url}/mypage/result`;

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    });

    return response;
};

export { fetchMypageResult };
