import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, Typography, styled, Tooltip, Box } from '@mui/material';

import {
    fetchGetMenu,
    fetchGetAllO1,
    fetchGetAllO2,
    fetchGetAllF1,
    fetchGetAllF2,
    fetchGetAllS1,
    fetchGetAllS2,
    fetchInitSOF,
    fetchFinishSOF,
} from '../../services/SOFServices';

import { loggingSOFAccess } from '../../services/SOFLogServices';

const VerticalNavbar = ({
    curStep,
    isExpanded,
    toggleNavbar,
    setStep,
    type,
    isFirst,
    setIsFirst,
    setSOF,
    userState,
    canGoToMenu,
    secondFinishCheck,
}) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { pid } = useParams();
    const [menu, setMenu] = useState([]);

    useEffect(() => {
        fetchGetMenu(type).then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    setMenu(data);
                });
            }
        });
    }, []);

    const handleListItemClick = (step, isFirstMenu) => {
        let first = isFirst ? '1' : '2';
        loggingSOFAccess(first + '-' + type + '-' + curStep + '-out');
        if (!isFirst && isFirstMenu) {
            fetchInitSOF(type + '1', userState.uid).then((response) => {
                if (!response.ok) {
                    response.json().then((data) => {
                        console.log('init', data);
                    });
                }
            });
        } else if (isFirst && !isFirstMenu) {
            fetchFinishSOF(type + '1', userState.uid).then((response) => {
                if (!response.ok) {
                    response.json().then((data) => {
                        console.log('init', data);
                    });
                }
            });
        }

        if ((isFirst && isFirstMenu) || !(isFirst || isFirstMenu)) {
            setStep(step - 1);
        } else if (!isFirst && isFirstMenu) {
            setIsFirst(true);
            if (type == 's') {
                fetchGetAllS1().then((response) => {
                    if (response.ok) {
                        response.json().then((data) => {
                            setSOF(data);
                            setStep(step - 1);
                        });
                    }
                });
            } else if (type == 'o') {
                fetchGetAllO1().then((response) => {
                    if (response.ok) {
                        response.json().then((data) => {
                            setSOF(data);
                            setStep(step - 1);
                        });
                    }
                });
            } else if (type == 'f') {
                fetchGetAllF1().then((response) => {
                    if (response.ok) {
                        response.json().then((data) => {
                            setSOF(data);
                            setStep(step - 1);
                        });
                    }
                });
            }
        } else if (isFirst && !isFirstMenu) {
            setIsFirst(false);
            if (type == 'o') {
                fetchGetAllO2().then((response) => {
                    if (response.ok) {
                        response.json().then((data) => {
                            if(secondFinishCheck){
                                const tmp_Data = data=data.filter((item) => item.type !== 'feedback');
                                data = tmp_Data;
                            }
                            setSOF(data);
                            setStep(step - 1);
                        });
                    }
                });
            } else if (type == 's') {
                fetchGetAllS2().then((response) => {
                    if (response.ok) {
                        response.json().then((data) => {
                            if(secondFinishCheck){
                                const tmp_Data = data=data.filter((item) => item.type !== 'feedback');
                                data = tmp_Data;
                            }
                            setSOF(data);
                            setStep(step - 1);
                        });
                    }
                });
            } else if (type == 'f') {
                fetchGetAllF2().then((response) => {
                    if (response.ok) {
                        response.json().then((data) => {
                            if(secondFinishCheck){
                                const tmp_Data = data=data.filter((item) => item.type !== 'feedback');
                                data = tmp_Data;
                            }
                            setSOF(data);
                            setStep(step - 1);
                        });
                    }
                });
            }
        }
    };

    return (
        <div onMouseEnter={toggleNavbar} onMouseLeave={toggleNavbar}>
            <div>
                <Tooltip title="Toggle Navbar" arrow>
                    <Button>
                        <MenuIcon
                            color="black"
                            sx={{ width: '52pt', height: '51pt' }}
                        />
                    </Button>
                </Tooltip>
            </div>

            <div
                className="flex flex-col justify-center duration-300"
                style={{
                    backgroundColor: '#526C6F',
                    color: 'white',
                    width: '250px',
                }}
            >
                {isExpanded ? (
                    <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        sx={{ height: '100%', width: '250px' }}
                    >
                        {menu.map((item) => (
                            <ListItemButton
                                key={item.id}
                                selected={
                                    pathname === `/Project/${pid}/${item.label}`
                                }
                                disabled={
                                    (!item.first && canGoToMenu[0] == 1) ||
                                    (item.first &&
                                        canGoToMenu[0] == 1 &&
                                        item.goto > canGoToMenu[1]) ||
                                    (!item.first &&
                                        canGoToMenu[0] == 2 &&
                                        item.goto > canGoToMenu[1])
                                }
                                onClick={() =>
                                    handleListItemClick(item.goto, item.first)
                                }
                                sx={{ mb: 1 }}
                            >
                                <Box>
                                    {item.label && (
                                        <Typography
                                            sx={{ fontSize: '20pt', mt: 4 }}
                                        >
                                            {item.label}
                                        </Typography>
                                    )}
                                    {item.sub && (
                                        <Typography sx={{ fontSize: '16pt' }}>
                                            {item.sub}
                                        </Typography>
                                    )}
                                </Box>
                            </ListItemButton>
                        ))}
                    </List>
                ) : null}
            </div>
        </div>
    );
};

export default VerticalNavbar;
