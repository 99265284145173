import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, TextField, Typography, Divider } from '@mui/material';

import forward from '../../assets/images/ui/2. forward.png';
import smodule from '../../assets/images/module/1. Smodule.png';
import omodule from '../../assets/images/module/5. Omodule.png';
import fmodule from '../../assets/images/module/7. Fmodule.png';

import { fetchUpdateIsFirstLogin } from '../../services/UserServices';

const Help = () => {
    const navigate = useNavigate();
    const [currentComponent, setCurrentComponent] = useState(0);
    const onClickConfirm = () => {
        fetchUpdateIsFirstLogin();
        navigate('/');
    };

    const component = [
        <div key="module0" className="flex flex-col w-full items-center">
            <Typography
                fontSize="20pt"
                fontWeight="400"
                lineHeight="50px"
                textAlign="center"
                paddingBottom="30px"
            >
                어느 날 갑자기 암이 내게로 왔습니다. <br />
                그리고 암은 나의 일상, 일 그리고 주변 사람들과의 관계까지 흔들어
                놓았습니다.
                <br />
                ‘왜 하필 나에게, 왜 하필 지금’, ‘내가 뭘 잘못했길래 이런 병에
                걸린 것일까’
                <br />
                충격받은 마음을 추스를 틈도 없이 항암 치료를 시작해야 했습니다.
                <br />
                치료 부작용으로 체중도 줄고 머리카락도 빠집니다.
                <br />
                문득 거울 속에 비춰진 내 모습이 낯설고 불편합니다.
            </Typography>
        </div>,
        <div key="module1" className="flex flex-col w-full items-center">
            <Typography
                fontSize="20pt"
                fontWeight="400"
                lineHeight="50px"
                textAlign="center"
                paddingBottom="30px"
            >
                그렇게 집중 치료가 끝나고 퇴원하여 집으로 돌아왔습니다.
                <br /> 이제 힘든 치료는 끝났고 이전의 일상으로 돌아갈 수 있을
                거라 기대했습니다.
                <br /> 그러나 기력은 여전히 회복하기가 쉽지 않습니다.
                <br /> 그리고 진단 받고 정신없이 치료 받을 때는 생각할 겨를도
                없었는데,
                <br /> 막상 집으로 돌아오니 여러 마음이 들기 시작합니다.
                <br /> 암이 재발하지는 않을지 두렵고 앞날은 불확실하기만 합니다.
            </Typography>
        </div>,
        <div key="module2" className="flex flex-col w-full items-center">
            <Typography
                fontSize="20pt"
                fontWeight="400"
                lineHeight="50px"
                textAlign="center"
                paddingBottom="30px"
            >
                &quot;세이브&quot;에서는 이렇게 암환자 분들께서 경험하실 수 있는
                여러 힘든 생각과 감정들을 다룹니다.
                <br />
                세이브는{' '}
                <span
                    style={{
                        fontWeight: '700',
                        textDecoration: 'underline',
                        textUnderlineOffset: '8px',
                    }}
                >
                    총 3개 모듈(한 개 모듈 당 약 40분)이 순서대로 진행
                </span>
                됩니다.
                <br />한 개 모듈은{' '}
                <span
                    style={{
                        fontWeight: '700',
                        textDecoration: 'underline',
                        textUnderlineOffset: '8px',
                    }}
                >
                    2부(1부 당 약 20분)로 구성
                </span>
                되어 있습니다.
            </Typography>
            <div>
                <div className="pb-10">
                    <div className="flex flex-row">
                        <img
                            src={smodule}
                            style={{ width: '70px', height: '70px' }}
                        />
                        <Typography
                            fontSize="18pt"
                            fontWeight="400"
                            lineHeight="35px"
                            textAlign="left"
                            paddingLeft={3}
                        >
                            1. &apos;나의 시간&apos;에서는 내 자신에 대해 경험할
                            수 있는 힘든 생각과 감정을 다룹니다.
                            <br /> &nbsp;&nbsp;&nbsp;&nbsp;암으로 인해 변화된 내
                            모습을 포함하여 있는 그대로 내 자신을 수용하는
                            방법에
                            <br /> &nbsp;&nbsp;&nbsp;&nbsp;대해 살펴보겠습니다.
                        </Typography>
                    </div>
                </div>
                <div className="pb-10">
                    <div className="flex flex-row">
                        <img
                            src={omodule}
                            style={{ width: '70px', height: '70px' }}
                        />
                        <Typography
                            fontSize="18pt"
                            fontWeight="400"
                            lineHeight="35px"
                            textAlign="left"
                            paddingLeft={3}
                        >
                            2. &apos;우리의 시간&apos;에서는 관계 속에서 겪을 수
                            있는 어려움을 다룹니다.
                            <br /> &nbsp;&nbsp;&nbsp;&nbsp;우리에게 소중한
                            사람들과 서로의 마음을 공감하고 나누는 방법에 대해
                            살펴보겠습니다.
                        </Typography>
                    </div>
                </div>
                <div>
                    <div className="flex flex-row">
                        <img
                            src={fmodule}
                            style={{ width: '70px', height: '70px' }}
                        />
                        <Typography
                            fontSize="18pt"
                            fontWeight="400"
                            lineHeight="35px"
                            textAlign="left"
                            paddingLeft={3}
                        >
                            3. &apos;삶의 시간&apos;에서는 삶이 지닌 불확실성이
                            가져오는 어려움과 의미에 대해 다룹니다.
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;지금 이 순간에 온전히
                            머물며, 내 삶의 의미와 가치를 찾는 방법에 대해
                            살펴보겠습니다.
                        </Typography>
                    </div>
                </div>
            </div>
            <Typography
                fontSize="20pt"
                fontWeight="400"
                lineHeight="50px"
                textAlign="center"
                paddingTop="50px"
            >
                각 부는 암환자 분들이 경험하시는 어려움에 대한 사례, 교육과
                연습으로 구성되어 있습니다.
            </Typography>
        </div>,
        <div key="module3">
            <Typography
                fontSize="20pt"
                fontWeight="400"
                lineHeight="50px"
                textAlign="center"
                paddingTop="30px"
            >
                한 모듈을 완료하시면, 그 다음날부터 바로 다음 모듈에 참여하실 수
                있습니다.
                <br /> 완료한 모듈은 언제든지 다시 하실 수 있습니다.
                <br /> 상단의 [마이페이지]에서 모듈 안에서 작성하신 내용을
                다운로드 받으실 수 있습니다.
            </Typography>
            <Typography
                fontSize="20pt"
                fontWeight="400"
                lineHeight="50px"
                textAlign="center"
                paddingTop="30px"
            >
                <span
                    style={{
                        fontWeight: '700',
                        textDecoration: 'underline',
                        textUnderlineOffset: '8px',
                    }}
                >
                    매 주 적어도 한 개 모듈
                </span>
                에 참여해 주십시오.
                <br /> 일주일이 지나도 한 모듈을 완료하지 못하셨을 때는 입력하신
                연락처로 알림문자가 전송됩니다.
                <br />
                <span
                    style={{
                        fontWeight: '700',
                        textDecoration: 'underline',
                        textUnderlineOffset: '8px',
                    }}
                >
                    하루에 최대 한 개 모듈(2부)
                </span>
                까지 진행하실 수 있으며,
                <br />
                편하신 일정과 속도에 맞추어 진행하시면 됩니다.
            </Typography>
            <Typography
                fontSize="20pt"
                fontWeight="400"
                lineHeight="50px"
                textAlign="center"
                paddingTop="30px"
            >
                진행 중{' '}
                <span
                    style={{
                        fontWeight: '700',
                        textDecoration: 'underline',
                        textUnderlineOffset: '8px',
                    }}
                >
                    20분 이상 아무런 활동이 없다면 자동 로그아웃
                </span>
                이 됩니다.
                <br />
                상단의 [로그인 연장] 버튼을 누르시면 시간을 연장할 수 있습니다.
                <br />
                자동 로그아웃이 되어도 이전까지 작성하셨던 내용은 모두
                저장됩니다.
            </Typography>
        </div>,
        <div key="module4" className="flex flex-col w-full items-center">
            <Typography
                fontSize="20pt"
                fontWeight="400"
                lineHeight="50px"
                textAlign="center"
                paddingBottom="50px"
            >
                프로그램은 가급적 방해받지 않으시고 집중할 수 있는 공간에서
                진행해 주십시오.
                <br /> 이외 문의사항은 상단의 [게시판]에 남겨주세요.
            </Typography>
            <Typography
                fontSize="20pt"
                fontWeight="400"
                lineHeight="50px"
                textAlign="center"
                paddingBottom="50px"
            >
                준비가 되셨나요?
                <br /> 그럼 &quot;세이브&quot;를 시작하겠습니다.
            </Typography>
            <Typography
                fontSize="20pt"
                fontWeight="400"
                lineHeight="50px"
                textAlign="center"
                paddingBottom="50px"
            >
                내용을 모두 확인하셨다면 아래 [확인] 버튼을 눌러주세요.
            </Typography>
            <Button
                variant="contained"
                color="primary"
                style={{
                    backgroundColor: '#526C6F',
                    color: 'white',
                    fontSize: '24px',
                    fontWeight: '400',
                    width: '147px',
                    borderRadius: '20px',
                }}
                onClick={onClickConfirm}
            >
                확인
            </Button>
        </div>,
    ];

    const handlePrev = () => {
        // 마지막 요소일 때는 더 이상 증가하지 않음
        if (currentComponent > 0) {
            setCurrentComponent((prevIndex) => prevIndex - 1);
        }
    };

    const handleNext = () => {
        // 마지막 요소일 때는 더 이상 증가하지 않음
        if (currentComponent < component.length - 1) {
            setCurrentComponent((prevIndex) => prevIndex + 1);
        }
    };

    return (
        <div className="flex w-full flex-row items-center justify-center py-14">
            {currentComponent === 0 ? (
                <div style={{ width: '45px' }} />
            ) : (
                <img
                    src={forward}
                    style={{
                        width: '45px',
                        height: '44px',
                        rotate: '180deg',
                        // shapeOutside: 'inherit',
                        // filter: 'drop-shadow(-3px -3px 2px rgba(0, 0, 0, 0.3))',
                        cursor: 'pointer',
                    }}
                    onClick={handlePrev}
                />
            )}

            <div className="flex flex-col w-982px px-5">
                <Typography
                    paddingBottom="15px"
                    fontSize="36pt"
                    fontWeight="700"
                    textAlign="left"
                >
                    시작하기 전에
                </Typography>

                <Divider
                    style={{
                        border: '1px solid #ABABAB',
                        width: '1250px',
                    }}
                />
                <div className="pb-5" />
                <div
                    style={{
                        height: '650px',
                        alignContent: 'center',
                        // marginBottom: '150px',
                    }}
                >
                    {component[currentComponent]}
                </div>
                <Typography
                    textAlign="right"
                    fontSize="15pt"
                    fontWeight="400"
                    paddingRight="80px"
                    // paddingTop="150px"
                >
                    ({currentComponent + 1}/{component.length})
                </Typography>
                <div className="pb-10" />
                <Divider
                    style={{
                        border: '1px solid #ABABAB',
                        width: '1250px',
                    }}
                />
            </div>

            {currentComponent === 4 ? (
                <div style={{ width: '45px' }} />
            ) : (
                <img
                    src={forward}
                    style={{
                        width: '45px',
                        height: '44px',
                        // shapeOutside: 'inherit',
                        // filter: 'drop-shadow(-3px 3px 2px rgba(0, 0, 0, 0.3))',
                        cursor: 'pointer',
                    }}
                    onClick={handleNext}
                />
            )}
        </div>
    );
};
export default Help;
