import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';

const column = '친구의 말\n생각\n감정\n행동';
const row = `"요새 의술이 좋아져서 암도 금방 치료된다"\n\n내가 겪은 일이 아무것도 아니라는 말이구나\n금방 나을 수 있으니 걱정하지 말라는 말이구나\n서운함, 화남\n고마움, 안심됨\n그런 말 하지 말라며 소리를 버럭 지르고 자리를 피함\n안심이 된다며 친구 어깨를 토닥이고 함께 웃음`;

const parsingCR = () => {
  const columns = column.split('\n');
  const rows = row.split('\n');

  const parsedRows = [];
  for (let i = 0; i < rows.length; i += 2) {
    parsedRows.push([rows[i], rows[i + 1]]);
  }

  return { columns, parsedRows };
}

const Table1 = () => {
  const { columns, parsedRows } = parsingCR();

  return (
    <TableContainer component={Paper} sx={{width:'90%'}}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle1" style={{ fontWeight: 'bold', textAlign:'center' }}>친구의 말</Typography>
            </TableCell>
            <TableCell colSpan={2}>
              <Typography variant="subtitle1" style={{ fontWeight: 'bold', textAlign:'center'}}>{parsedRows[0][0]}</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {parsedRows.slice(1).map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              <TableCell>
                <Typography variant="subtitle1" style={{ fontWeight: 'bold', textAlign:'center' }}>{columns[rowIndex + 1]}</Typography>
              </TableCell>
              {row.map((cell, cellIndex) => (
                <TableCell key={cellIndex}>
                  <Typography sx={{textAlign:'center'}}>{cell}</Typography>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Table1;
