import React from 'react';
import Box3 from './Box3';

import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

// 문자열에서 ***와 &&&를 찾아 input 및 select 컴포넌트로 변환
const parsetext = (content, answer, handleInputChange, idx) => {
    let parts = content.split(/(\*\*|&&&[^&]+&&&)/).map((part, index) => {
        if(part === '**') {
            return <input key={`input-${index}`} type="text" style={{ flex:'1', backgroundColor: '', border: '1px solid #000', marginRight: 5, fontSize:'20pt', marginBottom:'10px', marginLeft:'10px'}} value={answer[idx]} onChange={(e) => handleInputChange(idx, e)}/>;
        } else if(part.startsWith('&&&') && part.endsWith('&&&')) {
            const options = part.slice(3, -3).split(';');
            return (
                <Select
                    key={`select-${index}`}
                    value={answer[idx] || ''}
                    onChange={(e) => handleInputChange(idx, e)}
                    style={{ backgroundColor: '', border: '1px solid #000', margin: '5px', minWidth:400, height:50, marginLeft:'10px', fontSize:'20pt'}}
                >
                    {options.map((option, i) => (
                        <MenuItem key={`option-${i}`} value={option} sx={{fontSize:'20pt'}}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            );
        } else {
            return <span key={`text-${index}`}>{part}</span>;
        }
    });
    return parts;
}

// 파싱된 텍스트 컴포넌트
export default function Quiz11Module ({ content, subtitle, subcontent, quiz, answer, handleInputChange}) {

    const quizlist = quiz.map((q, index) => {
        const parts = parsetext(q, answer, handleInputChange, index);
        return (
            <div key={index}>
                <Typography display={'flex'} sx={{fontSize:'20pt'}}>{parts}</Typography>
            </div>
        )
    });

    return (
        <div style={{width:'90%'}}>
            {content}
            <Box3 title='실습' content={quizlist} />
        </div>
    )
};
