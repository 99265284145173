import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Typography, Divider } from '@mui/material';
import { AuthContext } from '../../../core/auth';
import { UserContext } from '../../../core/user';

import { fetchCertificate } from '../../../services/Certificate';

import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { BorderAll } from '@mui/icons-material';

import {
    fetchGetCertificate,
    fetchCreateCertificate,
    fetchDownloadCertificate,
} from '../../../services/Certificate';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

const Certificate = () => {
    const navigate = useNavigate();

    const { userState } = useContext(UserContext);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [certificate, setCertificate] = useState(null);
    const [download, setDownload] = useState(null);

    useEffect(() => {
        const fetchCertificate = async () => {
            try {
                const createResponse = await fetchCreateCertificate();
                if (createResponse.ok) {
                    console.log('Certificate created successfully');

                    checkCertificate(); // 인증서 확인
                } else {
                    console.log('Certificate creation failed');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchCertificate();
    }, []);

    const checkCertificate = async () => {
        try {
            const getResponse = await fetchGetCertificate();
            if (getResponse.ok) {
                const blob = await getResponse.blob();
                const url = window.URL.createObjectURL(blob);
                setCertificate(url);
                console.log('Certificate fetched successfully');
            } else {
                console.log('Certificate fetch failed, retrying...');
                setTimeout(checkCertificate, 1000); // 1초 후에 다시 시도
            }
        } catch (error) {
            console.error('Error:', error);
            setTimeout(checkCertificate, 1000); // 1초 후에 다시 시도
        }
        fetchDownloadCertificate().then((response) => {
            if (response.ok) {
                response.blob().then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    setDownload(url);
                });
                console.log('Downloaded successfully');
            }
        });
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const handleDownloadCert = () => {
        const link = document.createElement('a');
        link.href = download;
        link.setAttribute('download', `certificate_${userState.uid}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(download);

        console.log('Download successful');
    };

    return (
        <div className="flex w-full flex-row items-center justify-center">
            <div className="basis-1/6" />
            <div className="flex w-full flex-col ">
                <Typography
                    paddingBottom="5px"
                    fontSize="36px"
                    fontWeight="700"
                    textAlign="left"
                >
                    수료증 발급
                </Typography>

                <Divider
                    style={{
                        border: '1px solid #ABABAB',
                        width: '100%',
                    }}
                />
                <div className="pb-5" />
                <Typography textAlign="center" fontSize="20px">
                    모든 활동이 종료되었습니다.
                    <br />
                    <br />
                    아래 &apos;다운로드&apos; 버튼을 통해 파일을 저장하실 수
                    있습니다.
                    <br />
                    <br />
                    <br />
                </Typography>

                {certificate !== null ? (
                    <div className="flex w-full justify-center items-center">
                        <img
                            src={certificate}
                            alt="Certificate"
                            style={{
                                width: '867px',
                                height: '552px',
                                border: '1px solid black',
                            }}
                        />
                    </div>
                ) : (
                    <div></div>
                )}
                <div className="p-5" />
                <Divider
                    style={{
                        border: '1px solid #ABABAB',
                        width: '100%',
                    }}
                />
                <div className="flex w-full items-center justify-center pt-10">
                    <Button
                        className="pr-10 mr-10"
                        variant="contained"
                        color="primary"
                        style={{
                            backgroundColor: '#526C6F',
                            color: 'white',
                            marginRight: '50px',
                            width: '150px',
                            height: '50px',
                            fontWeight: '400',
                            fontSize: '24px',
                            borderRadius: '15px',
                        }}
                        onClick={handleDownloadCert}
                    >
                        다운로드
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{
                            backgroundColor: '#526C6F',
                            color: 'white',
                            width: '150px',
                            height: '50px',
                            fontWeight: '400',
                            fontSize: '24px',
                            borderRadius: '15px',
                        }}
                        onClick={() => navigate('/')}
                    >
                        종료
                    </Button>
                </div>
            </div>
            <div className="basis-1/6" />
        </div>
    );
};

export default Certificate;
