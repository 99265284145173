import React from 'react';
import Typography from '@mui/material/Typography';

import Letter2Background from '../../../assets/images/sofimage/letter2Background.png';

const Letter2 = ({handleInputChange, answer}) => {
  return (
      <div 
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundImage: `url(${Letter2Background})`,
          backgroundSize: '100% 100%',  // 이미지가 요소 크기에 정확히 맞춰집니다
          backgroundRepeat: 'no-repeat',
          padding: '40px',
          width: 'auto',  // width와 height를 이미지 크기에 맞춰 자동으로 설정
          height: 'auto',
          margin: '20px auto',
          fontFamily: 'cursive',
        }}
      >
        <div style={{ alignSelf: 'flex-start', marginBottom: '20px' }}>
        <Typography sx={{fontSize:'20pt'}}>
            사랑하는 <input type="text" style={{ border: '1px solid #000', width:'100px' }} onChange={(event) => handleInputChange(0, event)} value={answer[0]} /> 에게
        </Typography>
        </div>

        <Typography style={{ marginBottom: '20px', width: '80%', fontSize:'20pt' }}>
            <textarea 
              style={{
                  width: '90%', 
                  height: '300px', 
                  fontFamily: 'inherit', 
                  border: '1px solid #000', 
                  padding: '10px', 
                  boxSizing: 'border-box', 
                  resize: 'none',  // 크기 조절 막기
                  outline: 'none' // 포커스 시 외곽선 제거
              }} 
              onChange={(event) => handleInputChange(1, event)}
              value={answer[1]}
            />
        </Typography>

        <Typography style={{ textAlign: 'right', width: '100%', fontSize:'20pt', marginRight:'230px'}} >
          당신을 소중하게 생각하는 사람으로부터
        </Typography>
    </div>
  );
};

export default Letter2;
