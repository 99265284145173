import React, { useContext, useState, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import { Button, TextField, Typography, InputAdornment } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';

import { fetchUpdatePassword } from '../../services/AuthServices';

const UpdatePassword = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { uid } = location.state;

    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('');

    const onClickFindId = () => {
        if (password !== passwordConfirm) {
            setErrorText('비밀번호가 일치하지 않습니다.');
            setIsError(true);
            return;
        }
        fetchUpdatePassword(uid, password)
            .then((response) => {
                if (response.ok) {
                    response.json().then((data) => {
                        navigate('/login');
                    });
                } else if (response.status === 404) {
                    setIsError(true);
                } else {
                    console.log(response);
                    response.json().then((data) => {
                        setErrorText(data.detail[0].msg);
                        setIsError(true);
                    });
                }
            })
            .catch((error) => {
                alert('Network error. Please try again later.');
                console.log(error);
            });
    };

    return (
        <div className="flex w-full">
            <div className="flex w-full items-center justify-center flex-col">
                <Typography
                    fontWeight="700"
                    fontSize="24px"
                    className="pb-5"
                    fullWidth
                >
                    변경할 비밀번호를 입력하세요
                </Typography>
                <div className="pb-5">
                    <Typography style={{ fontSize: '24px' }}>
                        비밀번호
                    </Typography>
                    <TextField
                        // label="연락처"
                        id="outlined-required"
                        onChange={(e) => setPassword(e.target.value)}
                        style={{ width: '545px' }}
                        type="password"
                    />
                </div>
                <div className="pb-5">
                    <Typography style={{ fontSize: '24px' }}>
                        비밀번호 확인
                    </Typography>
                    <TextField
                        // label="연락처"
                        id="outlined-required"
                        style={{ width: '545px' }}
                        type="password"
                        onChange={(e) => setPasswordConfirm(e.target.value)}
                    />
                </div>
                {isError && (
                    <Typography color="error" className="pb-5">
                        {errorText}
                    </Typography>
                )}
                <Button
                    variant="contained"
                    color="primary"
                    style={{
                        backgroundColor: '#526C6F',
                        color: 'white',
                        fontSize: '24px',
                        fontWeight: '400',
                        borderRadius: '15px',
                        width: '232px',
                        height: '48px',
                        padding: '0px',
                        marginTop: '40px',
                    }}
                    onClick={onClickFindId}
                >
                    &nbsp;&nbsp;비밀번호 재설정&nbsp;&nbsp;
                </Button>
            </div>
        </div>
    );
};
export default UpdatePassword;
