import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, } from 'react-router-dom';

import { Button, Typography, Snackbar, styled } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Radio, FormControlLabel } from '@mui/material';
import { fetchSurvey, fetchPostSurveyResult } from '../../services/SurveyServices';
import { fetchFinishSOF } from '../../services/SOFServices';

import { UserContext } from '../../core/user';


const PostSurvey = () => {
    const navigate = useNavigate();
    const [surveyResults, setSurveyResults] = useState([]);
    const [question, setQuestion] = useState([]);
    const { userState } = useContext(UserContext);
    const [open, setOpen] = useState(false);
    const [missItem, setMissItem] = useState(0);

    const loadSurvey = () => {
        fetchSurvey().then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    setQuestion(data);
                });
            } else {
                setQuestion([]);
            }
        });
    };
    
    useEffect(() => {
        loadSurvey();

    }, []);

    const gotoFeedback = () => {
        if (surveyResults.length == 20) {
            console.log("result", surveyResults);
            const surveyInfo = {
                uid: userState.uid,
                result: surveyResults,
            };
            fetchFinishSOF('f2', userState.uid);
            fetchPostSurveyResult(surveyInfo)
            .then((response) => {
                if (response.ok) {
                    response.json().then((data) => {
                        console.log("post: ", data);
                    });
                } else {
                    console.log('post save error');
                }
            })
            navigate('/SOF/post-feedback');
        }
        else
            alert("모든 문항을 체크하세요.");

    };

    const  handleOpen = (index) => {
        setOpen(true);
        setMissItem(index);
      };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

    const handleSurveyChange = (index, value) => {
        const newResults = [...surveyResults];

        if (index > 0)
            if (surveyResults[index - 1] == null) {
                 handleOpen(index);        
            }
            else {
                newResults[index] = value;
                setSurveyResults(newResults);
            }
        if (index == 0) {
            newResults[index] = value;
            setSurveyResults(newResults);
        }
    };

    const HighlightedText = styled('span')({
        textDecoration: 'underline',
        fontWeight: '700',
        fontSize: '24px',
    });

    const buttonStyle = {
        backgroundColor: '#526C6F',
        color: 'white',
        borderRadius: '15px',
        width: '120px',
        height: '48px',
        fontSize: '24px',
    };

    const tableCellStyle = {
        width: '95px',
        fontSize: '20px',
        textAlign: 'center',
        whiteSpace: 'pre-wrap',
        borderTop: '3px solid #315E97',
        borderBottom: '3px solid #315E97',
        borderLeft: '1px solid #EBF1F8',
    };

    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;

    const handleNextPage = () => {
        surveyResults.length < 10 ? alert("1 ~ 10 번 문항을 모두 체크하세요.") : setCurrentPage(currentPage + 1);
    };

    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
            }}
        >
            <div>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={open}
                    autoHideDuration={3000}
                    onClose={handleClose}
                    message={`${missItem}번 문항이 체크되지 않았습니다.`}
                />
            </div>

            <Typography
                align="left"
                sx={{
                    width: '1000px',
                    paddingTop:"87px",
                    paddingBottom:"15px",
                    fontSize:"36px",
                    fontWeight:"600",
                }}>
                    나의 스트레스 수준은?
            </Typography>
            <Typography
                align="left"
                sx={{
                    width: '1000px',
                    paddingBottom:"5px",
                    fontSize:"20px",
                    fontWeight:"700",
                    whiteSpace: 'pre-wrap',
                }}>
                    {`나의 스트레스를 수준을 다시 한번 확인해보겠습니다.
아래는 만성신체질환을 갖고 계신 분들이 경험하실 수 있는 어려움들이 기술되어 있습니다.
각 문항을 읽고`} <HighlightedText>지난 한 달 동안</HighlightedText> {` 당신의 상태를 잘 나타내는 번호에 표시(O)하십시오.`}
            </Typography>

        
            <div className="table" style={{ width: '1000px',}}>
                <TableContainer>
                    <Table>
                        <TableHead >
                            <TableRow>
                                <TableCell
                                    sx={{
                                        borderTop: '3px solid #315E97',
                                        borderBottom: '3px solid #315E97',
                                    }}
                                ></TableCell>
                                <TableCell
                                    sx={{
                                        borderTop: '3px solid #315E97',
                                        borderBottom: '3px solid #315E97',
                                    }}
                                ></TableCell>
                                <TableCell
                                    sx={tableCellStyle}
                                >{`전혀
그렇지
않다`}</TableCell>
                                <TableCell
                                    sx={tableCellStyle}
                                >{`조금
그렇다`}</TableCell>
                                <TableCell
                                    sx={tableCellStyle}
                                >{`꽤
그렇다`}</TableCell>
                                <TableCell
                                    sx={tableCellStyle}
                                >{`매우
그렇다`}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {[...Array(20)].slice(startIndex, endIndex).map((_, index) => (
                                <TableRow key={index}>
                                    <TableCell
                                        sx={{
                                            borderTop: '1px solid #EBF1F8',
                                            borderBottom: (index + startIndex) === 19 ? '3px solid #315E97' : '1px solid #EBF1F8',
                                            backgroundColor: (index) % 2 === 1 ? 'white' : '#E3EBF1',
                                            fontSize: '20px',
                                        }}>
                                        {index + 1 + startIndex}.
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            borderTop: '1px solid #EBF1F8',
                                            borderBottom: (index + startIndex) === 19 ? '3px solid #315E97' : '1px solid #EBF1F8',
                                            backgroundColor: (index) % 2 === 1 ? 'white' : '#E3EBF1',
                                            fontSize: '20px',
                                            width: '650px',
                                        }}>
                                        {0 || question?.[index + startIndex]?.question}{' '}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            border: '1px solid #EBF1F8',
                                            borderBottom: (index + startIndex) === 19 ? '3px solid #315E97' : '1px solid #EBF1F8',
                                            backgroundColor: (index) % 2 === 1 ? 'white' : '#E3EBF1',
                                            textAlign: 'center', verticalAlign: 'middle'
                                        }}>
                                        <input
                                            type="radio"
                                            value="전혀 그렇지 않다"
                                            checked={surveyResults[index + startIndex] === 0}
                                            onChange={() => handleSurveyChange(index + startIndex, 0, )}
                                            style={{width: '20px', height: '20px', cursor: 'pointer', verticalAlign: 'middle'}}
                                        />
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            border: '1px solid #EBF1F8',
                                            borderBottom: (index + startIndex) === 19 ? '3px solid #315E97' : '1px solid #EBF1F8',
                                            backgroundColor: (index) % 2 === 1 ? 'white' : '#E3EBF1',
                                            textAlign: 'center', verticalAlign: 'middle'
                                        }}>
                                        <input
                                            type="radio"
                                            value="조금 그렇다"
                                            checked={surveyResults[index + startIndex] === 1}
                                            onChange={() =>handleSurveyChange(index + startIndex, 1, )}
                                            style={{width: '20px', height: '20px', cursor: 'pointer', verticalAlign: 'middle'}}
                                        />
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            border: '1px solid #EBF1F8',
                                            borderBottom: (index + startIndex) === 19 ? '3px solid #315E97' : '1px solid #EBF1F8',
                                            backgroundColor: (index) % 2 === 1 ? 'white' : '#E3EBF1',
                                            textAlign: 'center', verticalAlign: 'middle'
                                        }}>
                                        <input
                                            type="radio"
                                            value="꽤 그렇다"
                                            checked={surveyResults[index + startIndex] === 2}
                                            onChange={() =>handleSurveyChange(index + startIndex, 2, )}
                                            style={{width: '20px', height: '20px', cursor: 'pointer', verticalAlign: 'middle'}}
                                        />
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            borderTop: '1px solid #EBF1F8',
                                            borderBottom: (index + startIndex) === 19 ? '3px solid #315E97' : '1px solid #EBF1F8',
                                            backgroundColor: (index) % 2 === 1 ? 'white' : '#E3EBF1',
                                            textAlign: 'center', verticalAlign: 'middle'
                                        }}>
                                        <input
                                            type="radio"
                                            value="매우 그렇다"
                                            checked={surveyResults[index + startIndex] === 3}
                                            onChange={() =>handleSurveyChange(index + startIndex, 3, )}
                                            style={{width: '20px', height: '20px', cursor: 'pointer', verticalAlign: 'middle'}}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            
            <div
                style={{
                    width: '1000px',
                    textAlign: 'right',
                    marginTop: '10px',
                    marginLeft: '30px',
                    marginRight: '30px',
                }}
            >
            </div>

            <div
                style={{
                    width: '1000px',
                    textAlign: 'right',
                    marginTop: '10px',
                    marginLeft: '30px',
                    marginRight: '30px',
                }}
            >
                {currentPage > 0 && (
                    
                    <Button 
                        variant="contained"
                        color="primary"
                        style={buttonStyle}
                        onClick={gotoFeedback}
                    >
                        제출
                    </Button>
               )}
                {currentPage < Math.ceil(20 / itemsPerPage) - 1 && (
                    <Button
                        variant="contained"
                        color="primary"
                        style={buttonStyle}
                        onClick={handleNextPage}
                    >
                        다음
                    </Button>
                )}
            </div>
        </div>
    );
};
export default PostSurvey;