import React, { useState } from 'react';
import Box2 from './Box2';
import Typography from '@mui/material/Typography';
import { IconButton, Tooltip } from '@mui/material'; // Tooltip 추가
import questionIcon from '../../../assets/images/ui/9. question.png';

import timelefticon from '../../../assets/images/etc/11_time-left.png';
import chaticon from '../../../assets/images/etc/12_chat.png';
import likeicon from '../../../assets/images/etc/13_like.png';
import loveicon from '../../../assets/images/etc/14_love.png';
import photoicon from '../../../assets/images/etc/15_photo.png';
import lotusicon from '../../../assets/images/etc/16_lotus.png';
import coupleicon from '../../../assets/images/etc/17_couple.png';
import familyicon from '../../../assets/images/etc/18_family.png';
import competitionicon from '../../../assets/images/etc/19_competition.png';
import bookicon from '../../../assets/images/etc/20_book.png';
import communityicon from '../../../assets/images/etc/21_community.png';
import hospitalicon from '../../../assets/images/etc/22_hospital.png';

const parsecontent1 = (content) => {
  return content.split('\n').map((line, index) => {
    const parts = line.split('$');
    return (
      <Typography key={index} variant='h6' style={{ marginTop: '10px', fontSize: '20pt' }}>
        {parts.map((part, idx) => (
          idx % 2 === 1 ? <span key={idx} className="blue-text">{part}</span> : part
        ))}
        <br />
      </Typography>
    );
  });
};

const parsecontent2 = (content, inputTexts, setInputTexts, setShowHints, showHints, exampleHint, index, answer, handleInputChange) => {
  return content.split('\n').map((line, idx) => {
    if (line.includes('**')) {
      return (
        <Typography key={idx} sx={{ textAlign: 'left', fontSize: '20pt', mb: 1 }}>
          {line.replace(/\*\*/g, '')}
          <input
            style={{ backgroundColor: '', border: '1px solid #000', width: '650px', marginRight: '10px' }}
            type="text"
            value={answer[index] || ''} // Bind input value for the specific question
            onChange={(e) => {
              const newInputTexts = [...inputTexts];
              newInputTexts[index] = e.target.value;
              setInputTexts(newInputTexts);
              handleInputChange(index, e);
            }} // Update inputTexts state for the specific question
          />
          <Tooltip title="예시보기"> {/* Tooltip 적용 */}
            <IconButton
              onClick={() => {
                const newShowHints = [...showHints];
                newShowHints[index] = !newShowHints[index]; // 클릭 시 토글
                setShowHints(newShowHints);
              }}
            >
              <img src={questionIcon} alt="question icon" width={24} />
            </IconButton>
          </Tooltip>

          {showHints[index] && (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography style={{ color: '#fff', marginRight: '10px', mb: 1, fontSize: '20pt' }}>
                {line.replace(/\*\*/g, '')}
              </Typography>
              <div style={{ border: '1px solid #000', width: '650px' }}>
                {exampleHint}
              </div>
            </div>
          )}
        </Typography>
      );
    } else {
      return (
        <Typography key={idx} sx={{ textAlign: 'left', fontSize: '20pt', mb: 1 }}>
          {line}
          <br />
        </Typography>
      );
    }
  });
};

export default function Quiz7Module({ content, subtitle, quiz, answer, handleInputChange, from_ }) {
  const [inputTexts, setInputTexts] = useState([]); // State to manage input values for all questions
  const [showHints, setShowHints] = useState([]); // State to control hint display for all questions
  const exampleHint = from_.split('\n');
  const components = [];
  const subtitleArray = subtitle.split('\n');

  components.push(parsecontent1(content.trim()));
  if (exampleHint.length !== 1) {
    quiz.map((question, index) => {
      components.push(
        <div style={{width:'90%', margin:'0 auto'}}>
          <Box2
            key={index}
            title={subtitleArray[index]}
            content={parsecontent2(question, inputTexts, setInputTexts, setShowHints, showHints, exampleHint[index], index, answer, handleInputChange)}
          />
        </div>
      );
      return null;
    });
  } else if (from_ === 'first') {
    const iconlist = [timelefticon, chaticon, likeicon, loveicon, photoicon, lotusicon];
    quiz.map((question, index) => {
      components.push(
        <div style={{width:'90%', margin:'0 auto'}}>
          <Box2
            key={index}
            title={subtitleArray[index]}
            content={parsecontent2(question, inputTexts, setInputTexts, setShowHints, showHints, exampleHint[0], index, answer, handleInputChange)}
            icon={iconlist[index]}
          />
        </div>
      );
      return null;
    });
  } else if (from_ === 'second') {
    const iconlist = [coupleicon, familyicon, competitionicon, bookicon, communityicon, hospitalicon];
    quiz.map((question, index) => {
      if (index === 0) {
        components.push(
          <Typography key={index} sx={{ textAlign: 'left', fontSize: '24pt', fontWeight: 'bold', mt: 5, ml: 8 }}>
            · 나
          </Typography>
        );
      }
      if (index === 3) {
        components.push(
          <Typography key={index} sx={{ textAlign: 'left', fontSize: '24pt', fontWeight: 'bold', mt: 5, ml: 8 }}>
            · 우리
          </Typography>
        );
      }
      components.push(
        <div style={{width:'90%', margin:'0 auto'}}>
          <Box2
            key={index}
            title={subtitleArray[index]}
            content={parsecontent2(question, inputTexts, setInputTexts, setShowHints, showHints, exampleHint[1], index, answer, handleInputChange)}
            icon={iconlist[index]}
          />
        </div>
      );
      return null;
    });
  }

  return <div style={{ width: '100%' }}>{components}</div>;
}
