// QuizBox2.js
import React, { useState } from "react";
import Box1 from "./Box1";
import { Typography, Button, Tooltip } from "@mui/material";
import plusButton from "../../../assets/images/plusButton.png";

export default function QuizBox2({ title, content, quizlist, answer, setAnswer, handleInputChange, inputIndex }) {
    const [inputs, setInputs] = useState([]);

    const addInput = () => {
        setInputs([...inputs, {}]);
        setAnswer([...answer, '']);
    };

    const removeInput = (index) => {
        setInputs(inputs.filter((_, i) => i !== index));
        setAnswer(answer.filter((_, i) => i !== inputIndex + quizlist.length + index));
    };

    const combinedContent = [
        <Typography key="main-content">{content}</Typography>,
        ...quizlist.map((q, index) => {
            const currentIndex = inputIndex + index;
            return (
                <div key={index} style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                    <Typography>{q}</Typography>
                    <input 
                      style={{ flex: '1', backgroundColor: '', border: '1px solid #000' }} 
                      type="text" 
                      value={answer[currentIndex]} 
                      onChange={(e) => handleInputChange(currentIndex, e)}
                    />
                </div>
            );
        }),
        ...inputs.map((_, index) => {
            const currentIndex = inputIndex + quizlist.length + index;
            return (
                <div key={currentIndex} style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                    <Typography>{quizlist[quizlist.length - 1]}</Typography>
                    <input
                      style={{ flex: '1', backgroundColor: '', border: '1px solid #000' }}
                      type="text"
                      value={answer[currentIndex]}
                      onChange={(e) => handleInputChange(currentIndex, e)}
                    />
                    <Button onClick={() => removeInput(index)} style={{color:'#000', fontWeight:'bold'}}>ㅡ</Button>
                </div>
            );
        })
    ];

    return (
        <>
            <Box1 title={title} content={combinedContent}>
                <Tooltip title="칸 추가">
                    <Button onClick={addInput} style={{ display: 'flex', marginRight: 'auto', color:'#000', fontWeight:'bold' }}>
                        <img src={plusButton} alt="칸추가 버튼" width={20} style={{marginRight:'10px'}}/>칸 추가
                    </Button>
                </Tooltip>
            </Box1>
        </>
    );
}
