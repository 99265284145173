import React, { useState, useEffect, useContext } from 'react';

import smodule from '../../assets/images/module/1. Smodule.png';
import omodule from '../../assets/images/module/5. Omodule.png';
import fmodule from '../../assets/images/module/7. Fmodule.png';
import { Typography } from '@mui/material';

const AdminSOF = () => {
    return (
        <div
            className="flex flex-row w-full h-full items-center justify-center"
            style={{ minHeight: '70vh' }}
        >
            <div className="flex flex-col h-full items-center justify-center">
                <img
                    src={smodule}
                    style={{
                        width: '350px',
                        height: '350px',
                        borderRadius: '50%', // 이미지가 완전한 원이 아닌 경우 추가
                        filter: 'drop-shadow(8px 4px 4px rgba(0, 0, 0, 0.25))',
                    }}
                />
                <Typography fontSize="30px" fontWeight="400" paddingTop={3}>
                    나의 시간
                </Typography>
            </div>
            <div className="flex flex-col items-center justify-center px-20">
                <img
                    src={omodule}
                    style={{
                        width: '350px',
                        height: '350px',
                        borderRadius: '50%', // 이미지가 완전한 원이 아닌 경우 추가
                        filter: 'drop-shadow(8px 4px 4px rgba(0, 0, 0, 0.25))',
                    }}
                />
                <Typography fontSize="30px" fontWeight="400" paddingTop={3}>
                    우리의 시간
                </Typography>
            </div>
            <div className="flex flex-col items-center justify-center">
                <img
                    src={fmodule}
                    style={{
                        width: '350px',
                        height: '350px',
                        borderRadius: '50%', // 이미지가 완전한 원이 아닌 경우 추가
                        filter: 'drop-shadow(8px 4px 4px rgba(0, 0, 0, 0.25))',
                    }}
                />
                <Typography fontSize="30px" fontWeight="400" paddingTop={3}>
                    삶의 시간
                </Typography>
            </div>
        </div>
    );
};

export default AdminSOF;
