import React, { useContext, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import '../../index.css';

import {
    AppBar,
    Box,
    Toolbar,
    Typography,
    Button,
    Divider,
} from '@mui/material';

import { AuthContext } from '../../core/auth';
import { UserContext } from '../../core/user';
import {
    fetchSignOut,
    fetchTokenTimeLeft,
    fetchRefresh,
} from '../../services/AuthServices';

const AdminHeader = () => {
    const navigate = useNavigate();

    const { setAuthState, tokenTimeLeft, setTokenTimeLeft } =
        useContext(AuthContext);
    const { userState } = useContext(UserContext);

    const [isLogoutClicked, setIsLogoutClicked] = useState(false);
    const [isTokenTimeLeftAlerted, setIsTokenTimeLeftAlerted] = useState(false);
    const intervalRef = useRef(null);

    const onClickSignOut = async () => {
        setAuthState({ isLoading: true });
        await fetchSignOut().finally(() => {
            setAuthState({
                isAuthenticated: false,
                isLoading: false,
            });

            setIsLogoutClicked(true);
            navigate('/');
        });
        setAuthState({ isLoading: false });
    };

    const onClickTokenRefresh = () => {
        fetchRefresh()
            .then((response) => {
                if (response.ok) {
                    fetchTokenTimeLeft().then((timeResponse) => {
                        if (timeResponse.ok) {
                            timeResponse.json().then((data) => {
                                setTokenTimeLeft(data.time_left);
                                setAuthState({
                                    isAuthenticated: true,
                                    isLoading: false,
                                });
                            });
                        }
                    });
                } else {
                    setAuthState({
                        isAuthenticated: false,
                        isLoading: false,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const updateTokenTimeLeft = async () => {
        setTokenTimeLeft((prevTime) => {
            if (prevTime === null) return null;

            const newTime = prevTime - 1;

            if (isTokenTimeLeftAlerted && newTime > 60) {
                setIsTokenTimeLeftAlerted(false);
            }

            if (!isTokenTimeLeftAlerted && 0 < newTime && newTime <= 60) {
                setIsTokenTimeLeftAlerted(true);
                navigate('/logout/alert');
            }
            if (newTime <= 0) {
                fetchSignOut().finally(() => {
                    setAuthState({
                        isAuthenticated: false,
                        isLoading: false,
                    });
                });
                clearInterval(intervalRef.current);
                navigate('/logout');
            }

            return newTime > 0 ? newTime : 0;
        });
    };

    useEffect(() => {
        fetchTokenTimeLeft().then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    setTokenTimeLeft(data.time_left);
                });
            }
        });

        intervalRef.current = setInterval(updateTokenTimeLeft, 1000); // Fetch every second

        return () => clearInterval(intervalRef.current);
    }, []);

    useEffect(() => {
        if (isLogoutClicked) {
            navigate('/login');
            setIsLogoutClicked(false);
        }
    }, [isLogoutClicked]);

    const formatTime = (totalSeconds) => {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = Math.floor(totalSeconds % 60);
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const buttonStyle = {
        color: 'black',
        fontSize: '24px',
        fontWeight: '400',
    };

    const customDivider = () => {
        return (
            <div className="px-10">
                <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    style={{
                        border: '1px solid #ABABAB',
                        height: '12px',
                    }}
                />
            </div>
        );
    };

    return (
        <div className="flex flex-col">
            <Box sx={{ flexGrow: 1 }}>
                <AppBar
                    position="static"
                    style={{
                        backgroundColor: 'rgba(0,0,0, 0)',
                        boxShadow: 'none',
                        borderBottom: '1px solid #D9D9D9',
                    }}
                    // elevation={0}
                    // boxshadow="none"
                >
                    <Toolbar>
                        <Button color="inherit" onClick={() => navigate('/')}>
                            <div className="flex flex-col">
                                <Typography
                                    style={{ fontWeight: '700', lineHeight: 1 }}
                                >
                                    <span
                                        style={{
                                            fontSize: '48px',
                                            display: 'block',
                                            color: 'black',
                                        }}
                                    >
                                        SAVE
                                    </span>
                                    <span
                                        style={{
                                            fontSize: '13px',
                                            fontWeight: '400',
                                            textTransform: 'none',
                                            color: 'black',
                                        }}
                                    >
                                        Yes, To Life Always
                                    </span>
                                </Typography>
                            </div>
                        </Button>
                        <div className="flex flex-grow" />
                        <div>{customDivider()}</div>
                        <Button
                            color="inherit"
                            className="mx-3"
                            style={buttonStyle}
                            onClick={() => navigate('/admin/usersinfo')}
                        >
                            회원 정보
                        </Button>
                        <div>{customDivider()}</div>
                        <Button
                            color="inherit"
                            className="mx-3"
                            style={buttonStyle}
                            onClick={() =>
                                navigate('/admin/activityinfo/accessinfo')
                            }
                        >
                            활동 내용
                        </Button>
                        <div>{customDivider()}</div>
                        <Button
                            color="inherit"
                            className="mx-3"
                            style={buttonStyle}
                            onClick={() => navigate('/board')}
                        >
                            게시판
                        </Button>

                        <div>{customDivider()}</div>

                        {/* <Button
                            color="inherit"
                            className="mx-3"
                            style={buttonStyle}
                            onClick={() => navigate('/admin/moduleinfo')}
                        >
                            모듈 관리
                        </Button> */}
                        {/* <div>{customDivider()}</div> */}
                        <div className="grow"></div>

                        <div>{customDivider()}</div>
                        <Button
                            color="inherit"
                            style={buttonStyle}
                            onClick={onClickSignOut}
                        >
                            로그아웃
                        </Button>
                        <div>{customDivider()}</div>

                        <Typography
                            style={{
                                color: 'black',
                                fontSize: '16px',
                                fontWeight: '400',
                            }}
                        >
                            {formatTime(tokenTimeLeft)}
                        </Typography>
                        <div className="pr-20">
                            <Button
                                color="inherit"
                                style={{
                                    color: 'black',
                                    fontSize: '16px',
                                    fontWeight: '400',
                                }}
                                onClick={onClickTokenRefresh}
                            >
                                로그인 연장
                            </Button>
                        </div>
                    </Toolbar>
                </AppBar>
            </Box>
        </div>
    );
};

export default AdminHeader;
