import React from 'react';
import Box3 from './Box3';
import Typography from '@mui/material/Typography';


const parsecontentBasic = (content) => {
    return content.split('\n').map((line, index) => {
      const parts = line.split('$');
      return (
        <Typography key={index}>
          {parts.map((part, idx) => (
            idx % 2 === 1 ? <span key={idx} style={{color:'blue'}}>{part}</span> : part
          ))}
          <br />
        </Typography>
      );
    });
  }

const parsecontent = (subtitle, content, quiz) => {
    return content.split('\n').map((line, index) => {
        if(line.includes('!@#')) {
            return <Box3 key={index} title={subtitle[0]} content={quiz[0]}/>;
        } else if(line.includes('$%^')) {
            return <Box3 key={index} title={subtitle[1]} content={parsecontentBasic(quiz[1])}/>;
        }
        return (
            <Typography key={index}>
                {line}
                <br />
            </Typography>
        );
    });
}

export default function Text3Module ({ content, subtitle, quiz }) {
    const titleArray = subtitle.split('\n');
    return (
        <div style={{width:'90%'}}>
            {parsecontent(titleArray, content.trim(), quiz)}  
        </div>
    );
}
