import React, { useState, useContext, useEffect, } from 'react';
import { useNavigate, } from 'react-router-dom';

import { Button, Typography, IconButton, Table, TableBody, TableCell, TableRow } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import { fetchBoard, fetchBoardContent, fetchUploadPhoto, fetchUpdateBoardPhoto } from '../../services/BoardServices';
import { UserContext } from '../../core/user';

const WriteBoard= () => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [fileName, setFileName] = useState([]);

    const navigate = useNavigate();
    const { userState } = useContext(UserContext);

    const loadBoard = () => {
        fetchBoard().then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    //console.log("board: ", data);
                });
            } else {
                console.log('board load error!');
            }
        });
      };
    
    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };
    const handleContentChange = (event) => {
        setContent(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const boardInfo = {
            uid: userState.uid,
            nickname: userState.nickname,
            title: title,
            content: content,
            file: [],
            is_notice: false,
        };

        if (title === '') {
            alert('제목을 입력하세요.');
            return;
        }
        else if (content === '') {
            alert('내용을 입력하세요.');
            return;
        }

        if (window.confirm('게시글을 등록하시겠습니까?')) {       
            fetchBoardContent(boardInfo)
                .then((response) => {
                    if (response.ok) {
                        response.json().then((data) => {
                            console.log(data);
                            let pid = data
                            uploadPhoto(pid)
                        });
                    } else {
                        console.log('board send error!');
                    }
                })
                setTitle('');
                setContent('');
                gotoBoard();
        }        
    };

    const gotoBoard = () => {
        navigate('/board');
    };

    const handletoDeletePhoto = (index) => {
        if (window.confirm('해당 사진을 삭제하시겠습니까?')) {
            const newFiles = selectedFiles.filter((_, i) => i !== index);
            setSelectedFiles(newFiles);
            setFileName(newFiles.map((file) => file[0].name));
        }
    }

    const buttonStyle = {
        backgroundColor: '#526C6F',
        color: 'white',
        borderRadius: '15px',
        minWidth: '120px',
        height: '48px',
        fontSize: '24px',
        padding: '0 14px 0 14px',
    };

    const saveFileImage = (event) => {
        setSelectedFiles([...selectedFiles, event.target.files]);
        setFileName([...fileName, event.target.files[0].name]);
    };
    
    const uploadPhoto = async (pid) => {

        let formData = new FormData();
        let filePath = [];

        for (let i = 0; i < selectedFiles.length; i++) {
            for (let j = 0; j < selectedFiles[i].length; j++) {
                formData.append('file', selectedFiles[i][j]);
                formData.append('pid', pid);
            }

            filePath.push(`Upload/${pid}/${selectedFiles[i][0].name}`);

            fetchUploadPhoto(formData).then((response) => {
                if (response.ok) {
                    response.json().then((data) => {
                        console.log("upload data: ", data);
                    });
                } else {
                    console.log('file upload error!');
                }
            });
            formData = new FormData();
            
        }
        
        fetchUpdateBoardPhoto(pid, filePath).then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    filePath=[];
                });
            } else {
                console.log('board update photo error!');
            }
        });
    };

    useEffect(() => {
        loadBoard();
    }, []);

    return (
        <div style={{ margin: '0 auto', width: '1269px', marginTop: '100px' }}>

            <div 
                style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between', // Align buttons to the right
                marginBottom: '15px',
                }}>
                <Typography sx={{fontWeight: '700', fontSize: '36px', }}>
                    게시판
                </Typography>             
            </div>
            <div style={{ display: 'flex', flexDirection: 'column',   border: '1px solid #000000' }}>
                <input
                    type="text"
                    value={title}
                    onChange={handleTitleChange}
                    placeholder="제목을 입력하세요."
                    style={{ height: '40px', paddingLeft: '10px',}}>

                </input>
                <textarea
                    value={content}
                    onChange={handleContentChange}
                    placeholder="내용을 입력하세요."
                    style={{ borderTop: '1px solid #000000', height: '300px', resize: 'none', paddingLeft: '10px', }}>

                </textarea>
            </div>
            <Table sx={{ borderRight: '1px solid #000000', borderLeft: '1px solid #000000' }}>
                <TableBody>
                    {fileName.map((fileName, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                    {fileName}
                            </TableCell>
                            <TableCell sx={{ width: '8%' }}>
                                <IconButton aria-label="clear" sx={{ mr: 1, }} onClick={()=>handletoDeletePhoto(index)} >
                                    <ClearIcon/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '10px',
                    justifyContent: 'space-between', // Align buttons to the right
                    border: '1px solid #000000',
                    verticalAlign: 'center',
                    borderTop: 'none',
                }}>
                <div>
                    <input
                        type='file'
                        name='images'
                        // onChange={onSelectFile}
                        accept='.png, .jpg,image/*'
                        // multiple ref={testInput} 
                        onChange={saveFileImage}
                        />
                </div>                
            </div>

            <div 
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between', // Align buttons to the right
                    marginTop: '15px',
                }}>
                    
                <Button
                    variant="contained"
                    color="primary"
                    style={buttonStyle}                    
                    onClick={gotoBoard}
                    >
                    목록
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    style={buttonStyle}
                    onClick={handleSubmit}
                    >
                    저장
                </Button>
                
            </div>
        </div>
    );
}

export default WriteBoard;