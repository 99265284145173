import React from 'react';
import Table1 from './Table1';
import Typography from '@mui/material/Typography';

const parsecontent = (content) => {
    return content.split('\n').map((line, index) => {
        if(line.includes('!@#')) {
            return <Table1 key={index}  />;
        }
        return (
            <Typography key={index}>
                {line}
                <br />
            </Typography>
        );
    });
}

export default function Text2Module ({ content, subtitle, subcontent }) {

    return (
        <>
            {parsecontent(content.trim())}
            
        </>
    );
}
