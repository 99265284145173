import AppConfig from '../core/config';

const fetchCreateCertificate = async () => {
    const url = `${AppConfig.api_base_url}/certificate/create`;

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    });

    return response;
};

const fetchGetCertificate = async () => {
    const url = `${AppConfig.api_base_url}/certificate/get`;

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    });
    return response;
};

const fetchDownloadCertificate = async () => {
    const url = `${AppConfig.api_base_url}/certificate/download`;

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    });
    return response;
};

export {
    fetchCreateCertificate,
    fetchGetCertificate,
    fetchDownloadCertificate,
};
