import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, TextField, Typography, Divider } from '@mui/material';

import { AuthContext } from '../../core/auth';
import { UserContext } from '../../core/user';

const Logout = () => {
    const navigate = useNavigate();

    const { setAuthState, tokenTimeLeft, setTokenTimeLeft } =
        useContext(AuthContext);

    return (
        <div
            className="flex w-full items-center justify-center "
            style={{ marginTop: '60px' }}
        >
            <div className="flex w-5/6 flex-col items-center justify-center">
                <Divider
                    style={{
                        border: '1px solid #2F2F2F',
                        width: '100%',
                    }}
                />

                <Typography
                    fontSize="24px"
                    fontWeight="400"
                    textAlign="center"
                    paddingTop="100px"
                    paddingBottom="100px"
                >
                    일정시간 미사용으로 로그아웃되었습니다.
                    <br />
                    <br />
                    [확인] 버튼을 누르시면 로그인 페이지로 이동합니다
                </Typography>
                <Button
                    variant="containeed"
                    color="primary"
                    style={{
                        backgroundColor: '#526c6f',
                        color: 'white',
                        fontSize: '24px',
                        fontWeight: '400',
                        borderRadius: '15px',
                        width: '147px',
                        height: '48px',
                        padding: '0px',
                    }}
                    onClick={() => navigate('/')}
                >
                    확인
                </Button>
                <div style={{ paddingBottom: '70px' }} />
                <Divider
                    style={{
                        border: '1px solid #2F2F2F',
                        width: '100%',
                    }}
                />
            </div>
        </div>
    );
};
export default Logout;
