import React from 'react';
import Box1 from './Box1';
import Typography from '@mui/material/Typography';


// 파싱된 텍스트 컴포넌트
export default function Quiz2Module ({ quiz, handleInputChange, answer, download }) {
    return (
        <>
            <Box1 content={quiz.map((q, index) => {
                return (
                    <>
                        {!download ? 
                            <div key={index} style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                <Typography sx={{fontSize:'20pt'}}>{q}</Typography>
                                <input
                                    style={{ marginLeft:'10px',flex: '1', backgroundColor: '', border: '1px solid #000', fontSize:'16pt' }}
                                    type="text"
                                    onChange={(event) => handleInputChange(index, event)}
                                    value={answer[index]}
                                />
                            </div>
                            : 
                            <div key={index} style={{ width: '100%', alignItems: 'center' }}>
                                <Typography sx={{fontSize:'20pt', textAlign:'start'}}>{q}</Typography>
                                <Typography sx={{fontSize:'20pt', textAlign:'start', border:'1px solid #000', m:1, p:1}}>{answer[index]}</Typography>
                            </div>
                        }
                    </>
                );
            })} />
        </>
    )
};