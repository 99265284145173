import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { Button, TextField, Typography, Divider } from '@mui/material';

import { AuthContext } from '../../core/auth';
import { UserContext } from '../../core/user';

import { fetchSignOut } from '../../services/AuthServices';

const LogoutAlert = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { setAuthState, tokenTimeLeft, setTokenTimeLeft } =
        useContext(AuthContext);

    const onClickSignOut = () => {
        fetchSignOut().then(() => {
            setAuthState({
                isAuthenticated: false,
                isLoading: false,
            });

            navigate('/');
        });
    };

    const formatTime = (totalSeconds) => {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = Math.floor(totalSeconds % 60);
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    return (
        <div
            className="flex w-full items-center justify-center "
            style={{ marginTop: '60px' }}
        >
            <div className="flex w-5/6 flex-col items-center justify-center">
                <Divider
                    style={{
                        border: '1px solid #2F2F2F',
                        width: '100%',
                    }}
                />
                <Typography
                    fontSize="24px"
                    fontWeight="400"
                    textAlign="center"
                    paddingTop="30px"
                    paddingBottom="50px"
                >
                    자동 로그아웃 예정입니다
                </Typography>
                <div>
                    <div
                        className="flex items-center justify-center"
                        style={{ backgroundColor: 'rgba(217, 217, 217, 1)' }}
                    >
                        <Typography
                            fontSize="24px"
                            fontWeight="400"
                            textAlign="center"
                        >
                            남은 시간 {formatTime(tokenTimeLeft)}
                        </Typography>
                    </div>

                    <Typography
                        fontSize="24px"
                        fontWeight="400"
                        textAlign="center"
                        paddingTop="50px"
                        paddingBottom="50px"
                    >
                        SAVE 로그인 후 20분 동안 활동이 없을 경우, 자동
                        로그아웃됩니다.
                        <br />
                        계속 진행하시려면 [로그인 연장] 버튼을 눌러주세요.
                    </Typography>
                </div>
                <div
                    className="flex flex-row"
                    style={{ paddingBottom: '50px' }}
                >
                    <Button
                        variant="containeed"
                        color="primary"
                        style={{
                            backgroundColor: '#526c6f',
                            color: 'white',
                            fontSize: '24px',
                            fontWeight: '400',
                            borderRadius: '15px',
                            width: '147px',
                            height: '48px',
                            padding: '0px',
                        }}
                        onClick={() => navigate(-1)}
                    >
                        로그인 연장
                    </Button>
                    <div style={{ width: '100px' }} />
                    <Button
                        variant="containeed"
                        color="primary"
                        style={{
                            backgroundColor: '#526c6f',
                            color: 'white',
                            fontSize: '24px',
                            fontWeight: '400',
                            borderRadius: '20px',
                            width: '147px',
                            height: '48px',
                        }}
                        onClick={onClickSignOut}
                    >
                        로그아웃
                    </Button>
                </div>
                <Divider
                    style={{
                        border: '1px solid #2F2F2F',
                        width: '100%',
                    }}
                />
            </div>
        </div>
    );
};
export default LogoutAlert;
