import React, { useState, useContext, useEffect, } from 'react';
import { useNavigate, useParams, } from 'react-router-dom';

import { Button, Typography, IconButton, Table, TableBody, TableCell, TableRow } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import { fetchBoardByPid, fetchUpdateBoardByPid, fetchUploadPhoto, fetchUpdateBoardPhoto, } from '../../services/BoardServices';

import { UserContext } from '../../core/user';

const WriteBoard= () => {
    const pid = useParams().pid;    
    const navigate = useNavigate();
    const { userState } = useContext(UserContext);

    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [files, setFiles] = useState([]);
    const [isNotice, setIsNotice] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const loadBoard = () => {
        fetchBoardByPid(pid).then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    console.log("modi:", data);
                    setTitle(data.title);
                    setContent(data.content);
                    setFiles(data.file);
                    setIsNotice(data.is_notice);
                });
            } else {
                console.log('board mod error!');
            }
        });
    };
    
      useEffect(() => {
          loadBoard();
      }, []);
    
    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };
    const handleContentChange = (event) => {
        setContent(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const boardInfo = {
            uid: userState.uid,
            nickname: userState.nickname,
            title: title,
            content: content,
            file: files.length === 0 ? [] : files,
            is_notice: isNotice,
        };

        fetchUpdateBoardByPid(pid, boardInfo)
            .then((response) => {
                if (response.ok) {
                    response.json().then((data) => {
                        //console.log("recent data:", data);
                        uploadPhoto(pid)
                    });
                } else {
                    console.log('board modi error!');
                }
            })
            setTitle('');
            setContent('');
            gotoBoard();
    };

    const gotoBoard = () => {
        navigate('/board');
    };

    const handletoDeletePhoto = (index) => {
        if (window.confirm('해당 사진을 삭제하시겠습니까?')) {
            const newFiles = files.filter((_, i) => i !== index);
            setFiles(newFiles);
            console.log(newFiles);
        }
    }

    const saveFileImage = (event) => {
        setSelectedFiles([...selectedFiles, event.target.files]);
    };

    const uploadPhoto = async (pid) => {

        let formData = new FormData();

        for (let i = 0; i < selectedFiles.length; i++) {
            for (let j = 0; j < selectedFiles[i].length; j++) {
                formData.append('file', selectedFiles[i][j]);
                formData.append('pid', pid);
            }

            files.push(`Upload/${pid}/${selectedFiles[i][0].name}`);
 
            fetchUploadPhoto(formData).then((response) => {
                if (response.ok) {
                    response.json().then((data) => {
                        console.log("upload data: ", data);
                    });
                } else {
                    console.log('file upload error!');
                }
            });
            formData = new FormData();
            
        }

        fetchUpdateBoardPhoto(pid, files).then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    //filePath=[];
                });
            } else {
                console.log('board update photo error!');
            }
        });
    };

    const buttonStyle = {
        backgroundColor: '#526C6F',
        color: 'white',
        borderRadius: '15px',
        minWidth: '120px',
        height: '48px',
        fontSize: '24px',
        padding: '0 14px 0 14px',
    };

    return (
        <div style={{ margin: '0 auto', width: '1269px', marginTop: '100px'}}>
            <div 
                style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between', // Align buttons to the right
                marginBottom: '15px',
                }}>
            <Typography variant="h4"
                sx={{fontWeight: '700',  marginBottom: '15px',fontSize: '36px', }}>
                게시판
            </Typography>                
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid #000000' }}>
                <input
                    type="text"
                    value={title}
                    onChange={handleTitleChange}
                    placeholder="제목을 입력하세요."
                    style={{ height: '40px',  paddingLeft: '10px', }}>

                </input>
                <textarea
                    value={content}
                    onChange={handleContentChange}
                    placeholder="내용을 입력하세요."
                    style={{ borderTop: '1px solid #000000', height: '300px', paddingLeft: '10px', resize: 'none'}}>

                </textarea>
                <Table sx={{ borderTop: '1px solid #000000',}}>
                    <TableBody>
                        {files.map((files, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                        {files.split('/')[2]}
                                </TableCell>
                                <TableCell sx={{ width: '8%' }}>
                                    <IconButton aria-label="clear" sx={{ mr: 1, }}  onClick={()=>handletoDeletePhoto(index)}>
                                        <ClearIcon/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '10px',
                        justifyContent: 'space-between',
                        verticalAlign: 'center',
                        borderTop: '1px solid black',
                    }}>
                    <div>
                        <input
                            type='file'
                            name='images'
                            accept='.png, .jpg,image/*'
                            onChange={saveFileImage}
                            />
                    </div>                

                </div>
            </div>
            <div 
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between', // Align buttons to the right
                    marginTop: '15px',
                }}>
                <Button
                    variant="contained"
                    color="primary"
                    style={buttonStyle}                    
                    onClick={gotoBoard}
                    >
                    목록
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    style={buttonStyle}
                    onClick={handleSubmit}
                    >
                    저장
                </Button>
            </div>
        </div>
    );
}

export default WriteBoard;