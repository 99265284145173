import AppConfig from '../core/config';

const fetchGetExcel = async () => {
    const url = `${AppConfig.api_base_url}/excel/get`;

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    });
    return response;
};

const fetchSurveyExcel = async () => {
    const url = `${AppConfig.api_base_url}/excel/get/survey`;

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    });
    return response;
};
export { fetchGetExcel, fetchSurveyExcel };
