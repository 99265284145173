import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Typography } from '@mui/material';
import { AuthContext } from '../../../core/auth';
import { UserContext } from '../../../core/user';

import {
    fetchGetCertificate,
    fetchDownloadCertificate,
} from '../../../services/Certificate';

import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

const MypageCert = () => {
    const navigate = useNavigate();

    const { userState } = useContext(UserContext);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [certificate, setCertificate] = useState(null);
    const [download, setDownload] = useState(null);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    useEffect(() => {
        fetchGetCertificate().then((response) => {
            if (response.ok) {
                response.blob().then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    setCertificate(url);
                });
                console.log('Certificate fetched successfully');
            }
        });
        fetchDownloadCertificate().then((response) => {
            if (response.ok) {
                response.blob().then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    setDownload(url);
                });
                console.log('Downloaded successfully');
            }
        });
    }, []);

    const handleDownloadCert = () => {
        const link = document.createElement('a');
        link.href = download;
        link.setAttribute('download', `certificate_${userState.uid}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(download);

        console.log('Download successful');
    };

    return (
        <div className="flex w-full flex-row">
            <div className="flex w-full justify-center items-center flex-col">
                {/* <div className="border border-black"> */}
                {/* <Document
                        file={certificate} // Ensure this path is correct
                        onLoadSuccess={onDocumentLoadSuccess}
                        onLoadError={(error) =>
                            console.error('error is ', error)
                        }
                    >
                        <Page pageNumber={pageNumber} />
                    </Document> */}
                {certificate && (
                    <img
                        src={certificate}
                        alt="Certificate"
                        style={{
                            width: '867px',
                            height: '552px',
                            border: '1px solid black',
                        }}
                    />
                )}
                {/* </div> */}
                <div className="pl-5">
                    <Button
                        variant="contained"
                        color="primary"
                        style={{
                            backgroundColor: '#526C6F',
                            color: 'white',
                            marginTop: '30px',
                            width: '150px',
                            height: '50px',
                            fontWeight: '400',
                            fontSize: '24px',
                            borderRadius: '15px',
                        }}
                        onClick={handleDownloadCert}
                    >
                        다운로드
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default MypageCert;
