import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, TextField, Typography } from '@mui/material';

import agreement1 from '../../assets/images/policy/개인정보처리방침001.png';
import agreement2 from '../../assets/images/policy/개인정보처리방침002.png';
import agreement3 from '../../assets/images/policy/개인정보처리방침003.png';
import agreement4 from '../../assets/images/policy/개인정보처리방침004.png';
import agreement_service1 from '../../assets/images/policy/이용약관001.png';
import agreement_service2 from '../../assets/images/policy/이용약관002.png';
import agreement_service3 from '../../assets/images/policy/이용약관003.png';
import agreement_service4 from '../../assets/images/policy/이용약관004.png';
import agreement_service5 from '../../assets/images/policy/이용약관005.png';
import { Padding } from '@mui/icons-material';

const Agreement = () => {
    const navigate = useNavigate();
    const agreement_privay = [agreement1, agreement2, agreement3, agreement4];
    const agreement_service = [
        agreement_service1,
        agreement_service2,
        agreement_service3,
        agreement_service4,
        agreement_service5,
    ];

    const styles = {
        container: {
            width: '925px',
            height: '300px',
            overflow: 'auto',
            marginBottom: '60px',
        },
        image: {
            width: '100%',
            height: 'auto',
        },
    };

    return (
        <div className="flex w-full">
            <div className="flex w-full items-center justify-center flex-col">
                <Typography
                    style={{
                        width: '925px',
                        fontSize: '24px',
                        fontWeight: '700',
                        textAlign: 'left',
                        marginBottom: '20px',
                    }}
                >
                    개인정보 보호정책
                </Typography>

                <div style={styles.container}>
                    {agreement_privay.map((agreement, index) => (
                        <img
                            key={index}
                            src={agreement}
                            alt={`agreement${index}`}
                            style={styles.image}
                        />
                    ))}
                </div>

                <Typography
                    style={{
                        width: '925px',
                        fontSize: '24px',
                        fontWeight: '700',
                        textAlign: 'left',
                        marginBottom: '20px',
                    }}
                >
                    이용약관
                </Typography>

                <div style={styles.container}>
                    {agreement_service.map((agreement, index) => (
                        <img
                            key={index}
                            src={agreement}
                            alt={`agreement${index}`}
                            style={styles.image}
                        />
                    ))}
                </div>

                <div
                    style={{ width: '925px' }}
                    className="flex flex-row justify-end"
                >
                    <Typography
                        style={{
                            fontSize: '16px',
                            fontWeight: '400',
                            paddingRight: '20px',
                            alignSelf: 'center',
                        }}
                    >
                        개인정보 보호정책 이용약관에 동의합니다.
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{
                            backgroundColor: '#526C6F',
                            color: 'white',
                            fontSize: '20px',
                            fontWeight: '700',
                            borderRadius: '20px',
                        }}
                        onClick={() => navigate('/signup')}
                    >
                        동의
                    </Button>
                </div>
            </div>
        </div>
    );
};
export default Agreement;
