import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import DownIcon from '../../../assets/images/ui/5. down.png';

const parsecontent = (content) => {
  const lines = content.split('\n'); // 줄바꿈 기준으로 분리

  return lines.map((line, index) => {
      
      // 텍스트 부분 처리
      const parts = line.split('*');
      return (
          <Typography key={index} style={{ whiteSpace: 'pre-line', marginTop:'10px', fontSize:'20pt', textAlign:'start'}}>
              {parts.map((part, idx) =>
                  idx % 2 === 1 ? <u key={idx}>{part}</u> : part,
              )}
              <br />
          </Typography>
      );
  });
};

export default function textBox(subtitle, subcontent, quizlist, handleInputChange, answer) {
  return (
    <div>
      <Accordion sx={{border:'none',boxShadow:'none'}}>
        <AccordionSummary
          expandIcon={
              <img src={DownIcon} alt="Up Arrow" style={{ width: '36px', height: '41px', padding:0 }} />
          }
          sx={{ backgroundColor: '#5F6F5F66', border: '1px solid #000'}}
        >
          <Typography sx={{fontWeight:'bold', fontSize:'24pt'}}>{subtitle}</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{ border: 'none', padding: '0px'}}
        >
          <div
            style={{ backgroundColor: '#FFF', border: '1px solid #000', width:'100%', padding: '0 20px', paddingBottom:'20pt'}}
          >
            {parsecontent(subcontent)}
          </div>
          {quizlist.map((q, index) => {
                    return (
                        <div
                            key={index}
                            style={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '10px',
                            }}
                        >
                            <Typography sx={{ fontSize: '20pt', mr:2 }}>
                                {q}
                            </Typography>
                            <input
                                style={{
                                    flex: '1',
                                    // backgroundColor: '#D0D0D0',
                                    border: '1px solid #000',
                                    fontSize: '20pt',
                                }}
                                type="text"
                                onChange={(event) =>
                                    handleInputChange(index, event)
                                }
                                value={answer[index]}
                            />
                        </div>
                    );
                })}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}