import React, { useContext } from 'react';

import { UserContext } from '../../core/user';
import { AuthContext } from '../../core/auth';

import NoAuthenticatedHeader from './NoAuthenticatedHeader';
import AuthenticatedHeader from './AuthenticatedHeader';
import AdminHeader from './AdminHeader';

const Header = () => {
    const { userState } = useContext(UserContext);
    const { authState } = useContext(AuthContext);

    if (authState.loading) {
        return <div>Loading...</div>;
    }

    if (!authState.isAuthenticated) {
        return <NoAuthenticatedHeader />;
    } else {
        if (userState.is_admin) {
            return <AdminHeader />;
        } else {
            return <AuthenticatedHeader />;
        }
    }
};

export default Header;
