import React, { useState, useEffect } from 'react';
import Box3 from './Box3';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import plusImage from '../../../assets/images/ui/7. add.png';
import minusImage from '../../../assets/images/ui/8. minus.png';

export default function Quiz8Module({ content, subcontent, quiz, answer, setAnswer, download }) {
    const [inputSets, setInputSets] = useState([]);

    useEffect(() => {
        initializeSets();
    }, []); 

    const initializeSets = () => {
        const sets = new Array(quiz.length).fill('');
        setInputSets(sets);
        setAnswer(sets); 
    };

    const createNewSet = () => {
        return ['', '', ''];
    };

    const handleAddSet = () => {
        const newSet = createNewSet();
        setInputSets((prevSets) => [...prevSets, ...newSet]);
        setAnswer((prevAnswer) => [...prevAnswer, ...newSet]);
    };

    const handleRemoveSet = (startIndex) => {
        if (startIndex === 0) return; // 첫 번째 셋은 삭제 불가
        const updatedSets = inputSets.filter(
            (input, index) => index < startIndex || index >= startIndex + 3
        );
        setInputSets(updatedSets);
        setAnswer(answer.filter(
            (input, index) => index < startIndex || index >= startIndex + 3
        ));
    };

    const handleInputChange = (index, value) => {
        const updatedSets = [...inputSets];
        updatedSets[index] = value;
        setInputSets(updatedSets);

        const updatedAnswer = [...answer];
        updatedAnswer[index] = value;
        setAnswer(updatedAnswer);
    };

    const quizz = inputSets.map((input, index) => {
        if (index % 3 === 0) {
            return (
                <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', position: 'relative' }}>
                    <div style={{ width: '100%' }}>
                        {[0, 1, 2].map((offset) => {
                            const idx = index + offset;
                            if (idx < inputSets.length) {
                                return (
                                    <div key={idx} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                        <Typography textAlign="left" sx={{ fontSize: '20pt', flex: '0 0 auto', marginRight: '10px' }}>
                                            {quiz[idx % 3]}
                                        </Typography>
                                        {!download ? 
                                            <input
                                                style={{
                                                    backgroundColor: '',
                                                    border: '1px solid #000',
                                                    flexGrow: 1,
                                                    height: '30px',
                                                }}
                                                type="text"
                                                value={answer[idx]}
                                                onChange={(e) => handleInputChange(idx, e.target.value)}
                                            />
                                            :
                                            <>
                                                <Typography sx={{ fontSize: '20pt', textAlign: 'start', border: '1px solid #000', m: 1, p: 1, flex: '1', minHeight:'24pt' }}>
                                                    {answer[idx]}
                                                </Typography>
                                            </>
                                        }
                                    </div>
                                );
                            }
                            return null;
                        })}
                        <br />
                    </div>

                    {index > 3 && ( // 첫 번째 셋은 삭제 버튼을 표시하지 않음
                        <IconButton
                            onClick={() => handleRemoveSet(index)}
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                right: 0,
                                marginRight: '10px',
                                marginBottom: '0px',
                            }}
                        >
                            <img src={minusImage} width={24} style={{ filter: 'invert(27%) sepia(85%) saturate(6919%) hue-rotate(354deg) brightness(99%) contrast(127%)' }} />
                            <Typography sx={{ color: '#000', fontSize: '16pt', ml: 1 }}>삭제</Typography>
                        </IconButton>
                    )}
                </div>
            );
        }
        return null;
    });

    quizz.push(
        <>
            {!download &&
                <IconButton onClick={handleAddSet}>
                    <img src={plusImage} width={24} style={{ filter: 'invert(45%) sepia(82%) saturate(1857%) hue-rotate(161deg) brightness(94%) contrast(101%)' }} />
                    <Typography sx={{ color: '#000', fontSize: '16pt', ml: 1 }}>추가</Typography>
                </IconButton>
            }
        </>
    );

    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
            {content}
            <Box3 title='실습' content={quizz} />
            <br />
            <br />
            {subcontent}
        </div>
    );
}
