import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import { Typography, Button, Divider } from '@mui/material';

import { fetchMypageResult } from '../../services/Mypage';
import { fetchGetCertificate } from '../../services/Certificate';
import { fetchGetSOFWithAnswer } from '../../services/SOFServices';

import { UserContext } from '../../core/user';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

const s1Download = [6, 7];
const s2Download = [5, 7];
const o1Download = [6, 8];
const o2Download = [5, 7];
const f1Download = [7, 9];
const f2Download = [4, 7];

const MyPage = () => {
    const navigate = useNavigate();
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [surveyResult, setSurveyResult] = useState([]);
    const [certificate, setCertificate] = useState(null);
    const [download, setDownload] = useState(null);
    const [s1, setS1] = useState([]);
    const [s2, setS2] = useState([]);
    const [o1, setO1] = useState([]);
    const [o2, setO2] = useState([]);
    const [f1, setF1] = useState([]);
    const [f2, setF2] = useState([]);

    const { userState } = useContext(UserContext);

    useEffect(() => {
        fetchMypageResult()
            .then((response) => response.json())
            .then((data) => {
                console.log('mypage result data is ', data);
                processSurveyResult(data);
            })
            .catch((error) => {
                console.error('error is ', error);
            });

        fetchGetCertificate()
            .then((response) => {
                if (response.ok) {
                    console.log('response', response);
                    response.blob().then((blob) => {
                        const url = window.URL.createObjectURL(blob);
                        setCertificate(url);
                        console.log(url);
                    });
                }
            })
            .catch((error) => {
                console.error('error is ', error);
            });
    }, []);

    useState(() => {
        fetchGetSOFWithAnswer('s1', userState.uid).then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    // data 중에서, s1id 가 s1Download에 있는 것만 setS1에 넣어주기
                    const s1 = data.filter((s) => s1Download.includes(s.s1id));
                    setS1(s1);
                });
            }
        });

        fetchGetSOFWithAnswer('s2', userState.uid).then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    // data 중에서, s2id 가 s2Download에 있는 것만 setS2에 넣어주기
                    const s2 = data.filter((s) => s2Download.includes(s.s2id));
                    setS2(s2);
                });
            }
        });

        fetchGetSOFWithAnswer('o1', userState.uid).then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    const o1 = data.filter((o) => o1Download.includes(o.o1id));
                    setO1(o1);
                });
            }
        });

        fetchGetSOFWithAnswer('o2', userState.uid).then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    const o2 = data.filter((o) => o2Download.includes(o.o2id));
                    setO2(o2);
                });
            }
        });

        fetchGetSOFWithAnswer('f1', userState.uid).then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    const f1 = data.filter((f) => f1Download.includes(f.f1id));
                    setF1(f1);
                });
            }
        });

        fetchGetSOFWithAnswer('f2', userState.uid).then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    const f2 = data.filter((f) => f2Download.includes(f.f2id));
                    setF2(f2);
                });
            }
        });
    }, []);

    const processSurveyResult = (result) => {
        if (surveyResult.length === 0) {
            surveyResult.push(calculateSurveyResult(result['pre_result']));
            surveyResult.push(calculateSurveyResult(result['post_result']));
            console.log('SurveyResult is ', surveyResult);
        }
    };

    const calculateSurveyResult = (surveyResult) => {
        if (surveyResult === null) {
            return null;
        }
        let result = {};
        let sum = 0;
        surveyResult['result'].forEach((element) => {
            sum += element;
        });
        console.log(sum);
        let state = '위험';
        if (sum <= 10) state = '양호';
        else if (sum <= 15) state = '주의';
        else if (sum <= 25) state = '경고';
        result['state'] = state;

        let date = surveyResult['date'].split('T')[0].split('-');
        result['date'] = `${date[1]}/${date[2]}`;
        return result;
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    return (
        <div className="flex w-full items-center justify-center">
            <div className="flex w-5/6 flex-col items-center justify-center">
                <div className="flex w-full">
                    <Typography
                        paddingBottom="10px"
                        fontSize="32px"
                        fontWeight="700"
                        textAlign="left"
                    >
                        마이 페이지
                    </Typography>
                </div>
                <Divider
                    style={{
                        border: '4px solid #526c6f',
                        width: '100%',
                    }}
                />
                <div className="flex w-full flex-row pt-2">
                    <div className="basis-1/4 flex-col">
                        <div className="pb-14">
                            <Typography
                                paddingBottom="5px"
                                fontSize="32px"
                                fontWeight="700"
                                textAlign="left"
                            >
                                나의 스트레스 수준
                            </Typography>
                            <div className="flex flex-row">
                                <Typography fontSize="24px" paddingRight="25px">
                                    날짜
                                </Typography>
                                <Divider
                                    orientation="vertical"
                                    variant="middle"
                                    flexItem
                                    style={{
                                        border: '1px solid #ABABAB',
                                        height: '24px',
                                    }}
                                />
                                <Typography fontSize="24px" paddingLeft="25px">
                                    범위
                                </Typography>
                            </div>
                            {surveyResult.map((element, index) => {
                                if (element !== null) {
                                    return (
                                        <div
                                            className="flex flex-row"
                                            key={index}
                                        >
                                            <Button
                                                color="primary"
                                                style={{
                                                    fontSize: '24px',
                                                    fontWeight: '400',
                                                    color: 'black',
                                                    padding: '0',
                                                }}
                                                onClick={() =>
                                                    index === 0
                                                        ? navigate(
                                                              '/SOF/Feedback',
                                                              {
                                                                  state: {
                                                                      key: 'mypage',
                                                                  },
                                                              },
                                                          )
                                                        : navigate(
                                                              '/SOF/post-feedback',
                                                              {
                                                                  state: {
                                                                      key: 'mypage',
                                                                  },
                                                              },
                                                          )
                                                }
                                            >
                                                {element['date']}
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                {element['state']}
                                            </Button>
                                            {/* <Typography variant="h6">
                                                    {element['date']}
                                                </Typography>
                                                <div
                                                    style={{ width: '50px' }}
                                                />
                                                <Typography variant="h6">
                                                    {element['state']}
                                                </Typography>
                                                <Button
                                                    color="primary"
                                                    style={{
                                                        textDecoration:
                                                            'underline',
                                                        fontWeight: 'bold',
                                                        color: 'black',
                                                    }}
                                                    className="mr-10"
                                                    onClick={() =>
                                                        index === 0
                                                            ? navigate(
                                                                  '/SOF/Feedback',
                                                                  {
                                                                      state: {
                                                                          key: 'mypage',
                                                                      },
                                                                  },
                                                              )
                                                            : navigate(
                                                                  '/SOF/post-feedback',
                                                                  {
                                                                      state: {
                                                                          key: 'mypage',
                                                                      },
                                                                  },
                                                              )
                                                    }
                                                >
                                                    자세히 보기
                                                </Button> */}
                                        </div>
                                    );
                                }
                            })}
                        </div>

                        <div>
                            <Typography
                                paddingBottom="5px"
                                fontSize="32px"
                                fontWeight="700"
                                textAlign="left"
                            >
                                수료증
                            </Typography>
                            <div className="pr-5">
                                {certificate !== null ? (
                                    <img
                                        src={certificate}
                                        alt="Certificate"
                                        style={{
                                            width: '326px',
                                            height: '218px',
                                            border: '1px solid black',
                                        }}
                                        onClick={() => {
                                            navigate('/mypage/certificate');
                                        }}
                                    />
                                ) : (
                                    <div></div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div>
                        <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                            style={{
                                border: '1px solid #6A6A6A',
                                height: '100%',
                                shadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                            }}
                        />
                    </div>
                    <div className="basis-1/4 items-center justify-center">
                        <Typography
                            paddingBottom="7px"
                            fontSize="32px"
                            fontWeight="700"
                            textAlign="center"
                        >
                            나의 시간
                        </Typography>

                        <div
                            className="flex flex-col mx-4"
                            style={{
                                backgroundColor: 'rgba(210, 173, 138, 0.3)',
                                borderRadius: '50px',
                                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                            }}
                        >
                            <div className="mx-7 my-8">
                                <Typography
                                    paddingBottom="15px"
                                    fontSize="20px"
                                    fontWeight="400"
                                    textAlign="left"
                                >
                                    1부. 나를 안아주세요
                                </Typography>
                                {s1.map((s1_, index) => {
                                    return (
                                        <>
                                            <Button
                                                key={index}
                                                // color="primary"
                                                style={{
                                                    color: 'black',
                                                    fontSize: '20px',
                                                    textDecoration: 'underline',
                                                    textUnderlineOffset: '5px',
                                                    padding: '0',
                                                    margin: '0',
                                                    paddingBottom: '10px',
                                                }}
                                                onClick={() => {
                                                    navigate(
                                                        `/download/s/1/${s1_.s1id}`,
                                                    );
                                                }}
                                                // className="mr-10"
                                                // onClick={() =>
                                                //     navigate('/SOF/Feedback', {
                                                //         // state: { key: 'mypage' },
                                                //     })
                                                // }
                                            >
                                                {s1_.category}
                                            </Button>
                                            <br />
                                        </>
                                    );
                                })}
                                <Typography
                                    paddingTop="50px"
                                    paddingBottom="15px"
                                    fontSize="20px"
                                    fontWeight="400"
                                    textAlign="left"
                                >
                                    2부. 여전히 괜찮은 사람
                                </Typography>
                                {s2.map((s2_, index) => {
                                    return (
                                        <>
                                            <Button
                                                key={index}
                                                color="primary"
                                                style={{
                                                    color: 'black',
                                                    fontSize: '20px',
                                                    textDecoration: 'underline',
                                                    textUnderlineOffset: '5px',
                                                    padding: '0',
                                                    margin: '0',
                                                    paddingBottom: '10px',
                                                }}
                                                onClick={() => {
                                                    navigate(
                                                        `/download/s/2/${s2_.s2id}`,
                                                    );
                                                }}
                                                // className="mr-10"
                                                // onClick={() =>
                                                //     navigate('/SOF/Feedback', {
                                                //         // state: { key: 'mypage' },
                                                //     })
                                                // }
                                            >
                                                {s2_.category}
                                            </Button>
                                            <br />
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="basis-1/4 items-center justify-center">
                        <Typography
                            paddingBottom="7px"
                            fontSize="32px"
                            fontWeight="700"
                            textAlign="center"
                        >
                            우리의 시간
                        </Typography>
                        <div
                            className="flex flex-col mx-3"
                            style={{
                                backgroundColor: 'rgba(237, 227, 189, 0.3)',
                                borderRadius: '50px',
                                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                            }}
                        >
                            <div className="mx-7 my-8">
                                <Typography
                                    paddingBottom="15px"
                                    fontSize="20px"
                                    fontWeight="400"
                                    textAlign="left"
                                >
                                    3부. 내 마음이 들리나요?
                                </Typography>
                                {o1.map((o1_, index) => {
                                    return (
                                        <>
                                            <Button
                                                key={index}
                                                color="primary"
                                                style={{
                                                    color: 'black',
                                                    fontSize: '20px',
                                                    textDecoration: 'underline',
                                                    textUnderlineOffset: '5px',
                                                    padding: '0',
                                                    margin: '0',
                                                    paddingBottom: '10px',
                                                }}
                                                onClick={() => {
                                                    navigate(
                                                        `/download/o/1/${o1_.o1id}`,
                                                    );
                                                }}
                                                // className="mr-10"
                                                // onClick={() =>
                                                //     navigate('/SOF/Feedback', {
                                                //         // state: { key: 'mypage' },
                                                //     })
                                                // }
                                            >
                                                {o1_.category}
                                            </Button>
                                            <br />
                                        </>
                                    );
                                })}
                                <Typography
                                    paddingTop="50px"
                                    paddingBottom="15px"
                                    fontSize="20px"
                                    fontWeight="400"
                                    textAlign="left"
                                >
                                    4부. 지금 만나러 갑니다
                                </Typography>
                                {o2.map((o2_, index) => {
                                    return (
                                        <>
                                            <Button
                                                key={index}
                                                color="primary"
                                                style={{
                                                    color: 'black',
                                                    fontSize: '20px',
                                                    textDecoration: 'underline',
                                                    textUnderlineOffset: '5px',
                                                    padding: '0',
                                                    margin: '0',
                                                    paddingBottom: '10px',
                                                }}
                                                onClick={() => {
                                                    navigate(
                                                        `/download/o/2/${o2_.o2id}`,
                                                    );
                                                }}
                                                // className="mr-10"
                                                // onClick={() =>
                                                //     navigate('/SOF/Feedback', {
                                                //         // state: { key: 'mypage' },
                                                //     })
                                                // }
                                            >
                                                {o2_.category}
                                            </Button>
                                            <br />
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="basis-1/4 items-center justify-center">
                        <Typography
                            paddingBottom="7px"
                            fontSize="32px"
                            fontWeight="700"
                            textAlign="center"
                        >
                            삶의 시간
                        </Typography>
                        <div
                            className="flex flex-col mx-3"
                            style={{
                                backgroundColor: 'rgba(154, 193, 150, 0.3)',
                                borderRadius: '50px',
                                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                            }}
                        >
                            <div className="mx-7 my-8">
                                <Typography
                                    paddingBottom="15px"
                                    fontSize="20px"
                                    fontWeight="400"
                                    textAlign="left"
                                >
                                    5부. 지금 이 순간
                                </Typography>
                                {f1.map((f1_, index) => {
                                    return (
                                        <>
                                            <Button
                                                key={index}
                                                color="primary"
                                                style={{
                                                    color: 'black',
                                                    fontSize: '20px',
                                                    textDecoration: 'underline',
                                                    textUnderlineOffset: '5px',
                                                    padding: '0',
                                                    margin: '0',
                                                    paddingBottom: '10px',
                                                }}
                                                onClick={() => {
                                                    navigate(
                                                        `/download/f/1/${f1_.f1id}`,
                                                    );
                                                }}
                                                // className="mr-10"
                                                // onClick={() =>
                                                //     navigate('/SOF/Feedback', {
                                                //         // state: { key: 'mypage' },
                                                //     })
                                                // }
                                            >
                                                {f1_.category}
                                            </Button>
                                            <br />
                                        </>
                                    );
                                })}
                                <Typography
                                    paddingTop="50px"
                                    paddingBottom="15px"
                                    fontSize="20px"
                                    fontWeight="400"
                                    textAlign="left"
                                >
                                    6부. 삶이 그대를 속일지라도
                                </Typography>
                                {f2.map((f2_, index) => {
                                    return (
                                        <>
                                            <Button
                                                key={index}
                                                color="primary"
                                                style={{
                                                    color: 'black',
                                                    fontSize: '20px',
                                                    textDecoration: 'underline',
                                                    textUnderlineOffset: '5px',
                                                    padding: '0',
                                                    margin: '0',
                                                    paddingBottom: '10px',
                                                }}
                                                onClick={() => {
                                                    navigate(
                                                        `/download/f/2/${f2_.f2id}`,
                                                    );
                                                }}
                                                // className="mr-10"
                                                // onClick={() =>
                                                //     navigate('/SOF/Feedback', {
                                                //         // state: { key: 'mypage' },
                                                //     })
                                                // }
                                            >
                                                {f2_.category}
                                            </Button>
                                            <br />
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <div
                    // className="flex w-full items-center justify-center flex-col"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                        width: '1250px',
                        height: '950px',
                        backgroundImage: `url(${mypagebackground})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        overflow: 'hidden',
                    }}
                >
                    <div>
                        <div
                            className="flex flex-col"
                            style={{
                                width: '450px',
                                height: '450px',
                                display: 'flex',
                                alignItems: 'center',
                                // justifyContent: 'center',
                                paddingTop: '70px',
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                position: 'absolute',
                                top: '5%',
                                left: '3%',
                                backgroundImage: `url(${memo1})`,
                            }}
                        >
                            <Typography className="pb-3" variant="h4">
                                나의 상태
                            </Typography>
                            <div className="flex flex-row">
                                <Typography variant="h6">날짜</Typography>
                                <div style={{ width: '50px' }} />
                                <Typography variant="h6">범위</Typography>
                                <div style={{ width: '150px' }} />
                            </div>

                            {surveyResult.map((element, index) => {
                                if (element !== null) {
                                    return (
                                        <div
                                            className="flex flex-row"
                                            key={index}
                                        >
                                            <Typography variant="h6">
                                                {element['date']}
                                            </Typography>
                                            <div style={{ width: '50px' }} />
                                            <Typography variant="h6">
                                                {element['state']}
                                            </Typography>
                                            <div style={{ width: '50px' }} />
                                            <Button
                                                color="primary"
                                                style={{
                                                    textDecoration: 'underline',
                                                    fontWeight: 'bold',
                                                    color: 'black',
                                                }}
                                                className="mr-10"
                                                onClick={() =>
                                                    index === 0
                                                        ? navigate(
                                                              '/SOF/Feedback',
                                                              {
                                                                  state: {
                                                                      key: 'mypage',
                                                                  },
                                                              },
                                                          )
                                                        : navigate(
                                                              '/SOF/post-feedback',
                                                              {
                                                                  state: {
                                                                      key: 'mypage',
                                                                  },
                                                              },
                                                          )
                                                }
                                            >
                                                자세히 보기
                                            </Button>
                                            <div style={{ width: '25px' }} />
                                        </div>
                                    );
                                }
                            })}
                        </div>
                        <div
                            className="flex flex-col"
                            style={{
                                ...memoStyle,
                                top: '5%',
                                left: '35%',
                                backgroundImage: `url(${memo2})`,
                                paddingTop: '15px',
                            }}
                        >
                            <Typography
                                className="pb-14"
                                style={{ color: 'white' }}
                                variant="h5"
                            >
                                S 모듈&nbsp;&nbsp;&nbsp;
                            </Typography>
                            {s1.map((s1_, index) => {
                                return (
                                    <Button
                                        key={index}
                                        color="primary"
                                        style={{
                                            color: 'black',
                                            height: '45px',
                                            fontSize: '32px',
                                        }}
                                        onClick={() => {
                                            navigate(
                                                `/download/s/1/${s1_.s1id}`,
                                            );
                                        }}
                                        // className="mr-10"
                                        // onClick={() =>
                                        //     navigate('/SOF/Feedback', {
                                        //         // state: { key: 'mypage' },
                                        //     })
                                        // }
                                    >
                                        {s1_.title}
                                    </Button>
                                );
                            })}
                            {s2.map((s2_, index) => {
                                return (
                                    <Button
                                        key={index}
                                        color="primary"
                                        style={{
                                            color: 'black',
                                            height: '45px',
                                            fontSize: '32px',
                                        }}
                                        onClick={() => {
                                            navigate(
                                                `/download/s/2/${s2_.s2id}`,
                                            );
                                        }}
                                        // className="mr-10"
                                        // onClick={() =>
                                        //     navigate('/SOF/Feedback', {
                                        //         // state: { key: 'mypage' },
                                        //     })
                                        // }
                                    >
                                        {s2_.title}
                                    </Button>
                                );
                            })}
                        </div>
                        <div
                            className="flex flex-col"
                            style={{
                                ...memoStyle,
                                top: '5%',
                                left: '65%',
                                backgroundImage: `url(${memo3})`,
                                paddingTop: '23px',
                            }}
                        >
                            <Typography className="pb-11" variant="h5">
                                O 모듈&nbsp;
                            </Typography>
                            {o1.map((o1_, index) => {
                                return (
                                    <Button
                                        key={index}
                                        color="primary"
                                        style={{
                                            color: 'black',
                                            height: '45px',
                                            fontSize: '32px',
                                        }}
                                        onClick={() => {
                                            navigate(
                                                `/download/o/1/${o1_.o1id}`,
                                            );
                                        }}
                                        // className="mr-10"
                                        // onClick={() =>
                                        //     navigate('/SOF/Feedback', {
                                        //         // state: { key: 'mypage' },
                                        //     })
                                        // }
                                    >
                                        {o1_.title}
                                    </Button>
                                );
                            })}
                            {o2.map((o2_, index) => {
                                return (
                                    <Button
                                        key={index}
                                        color="primary"
                                        style={{
                                            color: 'black',
                                            height: '45px',
                                            fontSize: '32px',
                                        }}
                                        onClick={() => {
                                            navigate(
                                                `/download/o/2/${o2_.o2id}`,
                                            );
                                        }}
                                        // className="mr-10"
                                        // onClick={() =>
                                        //     navigate('/SOF/Feedback', {
                                        //         // state: { key: 'mypage' },
                                        //     })
                                        // }
                                    >
                                        {o2_.title}
                                    </Button>
                                );
                            })}
                        </div>
                    </div>
                    <div
                        className="flex flex-col"
                        style={{
                            width: '400px',
                            height: '400px',
                            display: 'flex',
                            alignItems: 'start',
                            justifyContent: 'center',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            position: 'absolute',
                            top: '50%',
                            left: '20%',
                            backgroundImage: `url(${memo4})`,

                            paddingLeft: '20px',
                        }}
                    >
                        {certificate !== null ? (
                            <div className="mt-10  border border-black">
                                <Document
                                    file={certificate} // Ensure this path is correct
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    onLoadError={(error) =>
                                        console.error('error is ', error)
                                    }
                                    onClick={() => {
                                        navigate('/mypage/certificate');
                                    }}
                                >
                                    <Page
                                        pageNumber={pageNumber}
                                        width={340}
                                        height={100}
                                        // onClick={() => {
                                        //     navigate('/mypage/certificate');
                                        // }}
                                    />
                                </Document>
                            </div>
                        ) : (
                            <div></div>
                        )}
                    </div>
                    <div
                        className="flex flex-col"
                        style={{
                            ...memoStyle,
                            top: '50%',
                            left: '55%',
                            backgroundImage: `url(${memo5})`,
                            paddingTop: '10px',
                        }}
                    >
                        <Typography className="pb-14" variant="h5">
                            F 모듈&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </Typography>
                        {f1.map((f1_, index) => {
                            return (
                                <Button
                                    key={index}
                                    color="primary"
                                    style={{
                                        color: 'black',
                                        height: '45px',
                                        fontSize: '32px',
                                    }}
                                    onClick={() => {
                                        navigate(`/download/f/1/${f1_.f1id}`);
                                    }}
                                    // className="mr-10"
                                    // onClick={() =>
                                    //     navigate('/SOF/Feedback', {
                                    //         // state: { key: 'mypage' },
                                    //     })
                                    // }
                                >
                                    {f1_.title}
                                </Button>
                            );
                        })}
                        {f2.map((f2_, index) => {
                            return (
                                <Button
                                    key={index}
                                    color="primary"
                                    style={{
                                        color: 'black',
                                        height: '45px',
                                        fontSize: '32px',
                                    }}
                                    onClick={() => {
                                        navigate(`/download/f/2/${f2_.f2id}`);
                                    }}
                                    // className="mr-10"
                                    // onClick={() =>
                                    //     navigate('/SOF/Feedback', {
                                    //         // state: { key: 'mypage' },
                                    //     })
                                    // }
                                >
                                    {f2_.title}
                                </Button>
                            );
                        })}
                    </div>
                </div>
            </div> */}
            </div>
        </div>
    );
};

export default MyPage;
