import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { Button, Typography, Divider } from '@mui/material';

import feedback from '../../assets/images/feedback/color_face.png';
import pen from '../../assets/images/feedback/pen.png';
import arrow from '../../assets/images/feedback/arrow.png';

import { UserContext } from '../../core/user';

import { fetchPostSurveyResultScore, fetchSurveyResultScore, } from '../../services/SurveyServices';

const Feedback = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const returnAddress = location.state;

    const { userState } = useContext(UserContext);
    const [preScore, setPreScore] = useState(0);
    const [postScore, setPostScore] = useState(0);

    const uid = userState.uid;

    const angle = [-10, 40, 90, 140];
    const penLocate = [35, 40, 45, 47];

    const loadSurveyResult = () => {
        fetchPostSurveyResultScore(uid).then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    // console.log("post score: ", data.result);
                    setPostScore(data.result);
                });
            } else {
                console.log('score error');
            }
        });

        fetchSurveyResultScore(uid).then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    // console.log("pre score: ", data.result);
                    setPreScore(data.result);

                });
            } else {
                console.log('score error');
            }
        });
    };

    const sumofResult = (score) => {
        let sum = 0;
        for (let i = 0; i < score.length; i++) {
            sum += score[i];
        }
        return sum;
    };

    const postSum = sumofResult(postScore);
    const preSum = sumofResult(preScore);

    const thermometerFeedback = (sum) => {
        let state = 0;
        // state 0 = 양호, 1 = 주의, 2 = 경고, 3 = 위험
        if (sum <= 10) state = 0;
        else if (sum <= 15) state = 1;
        else if (sum <= 25) state = 2;
        else state = 3;
        
        return state;
    };

    const preState = thermometerFeedback(preSum);
    const postState = thermometerFeedback(postSum);
   
    const buttonStyle = {
        backgroundColor: '#526C6F',
        color: 'white',
        borderRadius: '15px',
        width: '120px',
        height: '48px',
        fontSize: '24px',
    };

    const stateString = (myState) => {
        //case 0: get better, 1: same before, 2: get worse
        switch (myState) {
            case 0:
                return `이번 프로그램에서 배운 방법들은 앞으로도 지속적으로 실천하시기 바랍니다.`;
            case 1:
                return `이번 프로그램에서 배운 방법들을 앞으로도 꾸준히 실천해 보시기 바랍니다.`;
            case 2:
                return `꾸준히 스트레스를 관리하시기 바랍니다.
프로그램에서 배운 방법들을 일상생활에서 꾸준히 적용해 보세요.
그리고 규칙적인 식사와 수면, 적절한 신체 활동도 도움이 될 수 있습니다.`;
            default:
                return `No statement`;
        }
    }

    const compareState = (preState, postState) => {
        let diff = 0;
        if (preState > postState) diff = 0;
        else if (preState == postState) diff = 1;
        else diff = 2;

        return diff;
    }

    const diff = compareState(preState, postState);

    const gotoNextpage = () => {
        if (returnAddress !== null) navigate('/' + returnAddress.key);
        else {
            navigate('/SOF/certificate');
        }
    };

    useEffect(() => {
        loadSurveyResult();

    }, []);    

    return (

        <div className="flex w-full flex-row items-center justify-center">
            <div className="flex flex-col w-982px px-5">
                <Typography
                    paddingTop="87px"
                    paddingBottom="15px"
                    fontSize="36px"
                    fontWeight="600"
                    textAlign="left"
                >
                    나의 스트레스 수준은?
                </Typography>

                <Divider
                    style={{
                        border: '1px solid #ABABAB',
                        width: '982px',
                    }}
                />
                <div className="pb-5" />

                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '54px', width: '982px', height: '112px', }}>
                        <Typography
                            variant="h6"
                            align="center"
                            sx={{ whiteSpace: 'pre-wrap', fontSize: '24px', }}
                        >
                            {stateString(diff)}
                        </Typography>
                    </div>
                    <div
                        style={{
                            marginTop: '5%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        <div>
                            <Typography
                                sx={{
                                    textAlign: 'center',
                                    marginBottom: '20px',
                                    fontSize: '20px',
                                }}
                            >
                                개입 전
                            </Typography>

                            <div
                                style={{
                                    width: '425px',
                                    height: '202px',
                                    marginRight: '10px',
                                    position: 'relative',
                                    zIndex: 1,
                                    backgroundImage: `url(${feedback})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}>
                                <img
                                    src={pen}
                                    style={{
                                        width: '75px',
                                        position: 'absolute',
                                        bottom: '0%',
                                        transform: `rotate(${angle[preState]}deg)`,
                                        left: `${penLocate[preState]}%`,
                                        zIndex: 2,
                                    }}
                                />
                            </div>
                        </div>

                        <img
                            src={arrow}
                            style={{
                                width: '120px',
                                bottom: '10%',
                                left: '0%',
                            }}
                        />
                        <div>
                            <Typography
                                sx={{
                                    textAlign: 'center',
                                    marginBottom: '20px',
                                    fontSize: '20px',
                                }}>
                                개입 후
                            </Typography>
                            <div
                                style={{
                                    width: '425px',
                                    height: '202px',
                                    marginLeft: '10px',
                                    position: 'relative',
                                    zIndex: 1,
                                    backgroundImage: `url(${feedback})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}>
                                <img
                                    src={pen}
                                    style={{
                                        width: '75px',
                                        position: 'absolute',
                                        bottom: '0%',
                                        transform: `rotate(${angle[postState]}deg)`,
                                        left: `${penLocate[postState]}%`,
                                        zIndex: 2,
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <Typography
                        variant="h6"
                        sx={{ widht: '687px', mt: 5, fontSize: '24px', }}
                    >
                        해당 결과는 마이페이지 ‘나의 상태’ 에서 다시 확인하실 수 있습니다.             
                    </Typography>
                </div>
                
                <div
                    className="button"
                    style={{
                        textAlign: 'right',
                        marginTop: '50px',
                        marginRight: '35px',
                    }}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={buttonStyle}
                        onClick={gotoNextpage}
                    >
                        확인
                    </Button>
                </div>

                <div className="pb-5" />
                <Divider
                    style={{
                        border: '1px solid #ABABAB',
                        width: '982px',
                    }}
                />
            </div>
        </div>       
    );
};

export default Feedback;
