import AppConfig from '../../core/config';

import React, { useContext, useEffect, useState, } from 'react';
import { useNavigate, useParams, } from 'react-router-dom';

import { Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import Comment from './Comment';
import { UserContext } from '../../core/user';


import { fetchBoardByPid, fetchDeleteBoardByPid, fetchDeleteCommentByPid, } from '../../services/BoardServices';

const ContentBoard = () => {
    const navigate = useNavigate();
    const pid = useParams().pid;
    const { userState } = useContext(UserContext);    
    const [board, setBoard] = useState([]);
    const [files, setFiles] = useState([]);

    const loadBoard = () => {
        fetchBoardByPid(pid).then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    console.log("content of board: ", data);
                    setBoard(data);
                    if (data.file.length != 0) {
                        setFiles(data.file);
                    }
                });
            } else {
                console.log('board load error!');
                setBoard([]);
            }
        });        
    };

    const handletoModify = (pid) => {
        navigate(`/board/modify-board/${pid}`);
    };

    const handletoDelete = (pid) => {
        if (window.confirm('해당 게시글을 삭제하시겠습니까?')) {
            fetchDeleteCommentByPid(pid).then((response) => {
                if (response.ok) {
                    response.json().then((data) => {
                        //console.log(data);
                    });
                } else {
                    console.log('comment delete error!');
                }
            });
            
            fetchDeleteBoardByPid(pid).then((response) => {
                if (response.ok) {
                    response.json().then((data) => {
                        //console.log(data);
                    });
                } else {
                    console.log('board delete error!');
                }
            });
            navigate('/board');
        }
    };
    

    const gotoBoard = () => {
        navigate('/board');
      };
    
    const buttonStyle = {
        backgroundColor: '#526C6F',
        color: 'white',
        borderRadius: '15px',
        minWidth: '120px',
        height: '48px',
        fontSize: '24px',
        padding: '0 14px 0 14px',
    };

    useEffect(() => {
        loadBoard();
    }, []);

    return (
        <div
            style={{ margin: '0 auto', width: '1269px', marginTop: '100px' }}>
            <div 
                style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between', // Align buttons to the right
                marginBottom: '10px',
                }}>
                <Typography sx={{fontWeight: '700', fontSize: '36px', }}>
                    게시판
                </Typography>
                {(board?.uid ==  userState?.uid || userState?.is_admin) && (
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        style={buttonStyle}
                        onClick={()=>handletoModify(pid)}>
                        수정
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{marginLeft: '10px'}}
                        style={buttonStyle}
                        onClick={()=>handletoDelete(pid)}>
                        삭제
                    </Button>
                </div>
            )}
        </div>

        <TableContainer component={Paper} sx={{border: '1px solid #000000',}}> 
            <Table sx={{ minWidth: 650, }} aria-label="simple table">
                <TableHead sx={{ background:'#d9d9d9', fontWeight: 'bold' }}>
                    <TableRow>
                    <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid #000000', fontWeight: 'bold', }} colSpan={2}>{0 || board?.title}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell sx={{borderBottom: '1px solid #000000', textAlign: 'left'}}> 작성자: {0 || board?.nickname}</TableCell>
                        <TableCell sx={{borderBottom: '1px solid #000000', textAlign: 'right'}}> 작성일시: {0 || (board?.date && board.date.split('T')[0])}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ height: '300px', borderBottom: '1px solid #000000',}} colSpan={2}> {0 || board?.content} </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Table>
                <TableBody>
                    {files.map((files, index) => (
                        <TableRow key={index}>
                            <TableCell sx={{ width: '10%' }}>
                                <a
                                    href={`${AppConfig.api_base_url}/board/download/${pid}/${files.split('/')[2]}`}
                                    download
                                    target='_blank'
                                    rel='noreferrer'>
                                    {files.split('/')[2]}
                                </a>
                            </TableCell>
                            <TableCell sx={{ display: 'flex', justifyContent: 'center' }}> <img src = {`${AppConfig.api_base_url}/board/download/${pid}/${files.split('/')[2]}`}/> </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

        <div 
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between', // Align buttons to the right
                    marginTop: '15px',
                }}>
                <Button
                    variant="contained"
                    color="primary"
                    style={buttonStyle}                    
                    onClick={gotoBoard}
                    >
                    목록
                </Button>

            </div>
            <Comment />
        </div>

    );
}

export default ContentBoard;