import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, TextField, Typography, Divider } from '@mui/material';

import { AuthContext } from '../../core/auth';
import { UserContext } from '../../core/user';

import { fetchSignIn } from '../../services/AuthServices';

const Survey = () => {
    const navigate = useNavigate();

    const { setAuthState } = useContext(AuthContext);
    const { setUserState } = useContext(UserContext);

    const [id, setId] = useState('');
    const [password, setPassword] = useState('');

    const [idError, setIdError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const onClickSignIn = () => {
        if (validate()) {
            fetchSignIn(id, password)
                .then((response) => {
                    if (response.ok) {
                        setUserState({ isLoading: true });

                        setAuthState({
                            isAuthenticated: true,
                            isLoading: false,
                        });

                        setPassword('');
                        // navigate('/Project');
                    } else if (response.status === 401) {
                        alert('Invalid id or password.');
                    } else {
                        alert('Unknown error. Please try again later.');
                    }
                })
                .catch((error) => {
                    alert('Network error. Please try again later.');
                    console.log(error);
                });
        }
    };

    const validate = () => {
        const isValidId = validateId();
        const isValidPassword = validatePassword();

        return isValidId && isValidPassword;
    };

    const validateId = () => {
        if (id.length === 0) {
            setIdError('Please enter your ID.');
            return false;
        }
        setIdError('');
        return true;
    };

    const validatePassword = () => {
        if (password.length === 0) {
            setPasswordError('Please enter your password.');
            return false;
        }
        setPasswordError('');
        return true;
    };

    return (
        <div className="flex w-full flex-col">
            <div className="flex w-full items-center justify-center my-20">
                <Typography variant="h1" fontWeight="bold">
                    S A V E
                </Typography>
            </div>
        </div>
    );
};
export default Survey;
