import AppConfig from '../core/config';

const fetchUsersInfo = async () => {
    const url = `${AppConfig.api_base_url}/admin/users-info`;

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    });

    return response;
};

const fetchSurveyResult = async () => {
    const url = `${AppConfig.api_base_url}/admin/survey-results`;

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    });

    return response;
};

const fetchSurveyResultsTableColumn = async () => {
    const url = `${AppConfig.api_base_url}/admin/survey-results-table-column`;

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    });

    return response;
};

export { fetchUsersInfo, fetchSurveyResult, fetchSurveyResultsTableColumn };
