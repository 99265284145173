import React, { useContext, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, TextField, Typography, InputAdornment } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';

import { fetchFindId } from '../../services/AuthServices';

const FindID = () => {
    const navigate = useNavigate();
    const [phone, setPhone] = useState('');
    const [isError, setIsError] = useState(false);
    const [id, setID] = useState(null);

    const onClickFindId = async () => {
        fetchFindId(phone)
            .then((response) => {
                if (response.ok) {
                    response.json().then((data) => {
                        setID(data.id);
                    });
                } else if (response.status === 404) {
                    setIsError(true);
                } else {
                    // alert('Unknown error. Please try again later.');
                }
            })
            .catch((error) => {
                alert('Network error. Please try again later.');
                console.log(error);
            });
    };

    if (id === null) {
        return (
            <div className="flex w-full">
                <div className="flex w-full items-center justify-center flex-col">
                    <div className="pb-5">
                        <Typography
                            fontWeight="bold"
                            fontSize="24px"
                            className="pb-5"
                        >
                            회원가입 시 입력한 연락처를 입력하세요
                        </Typography>

                        <TextField
                            label="연락처"
                            id="standard-basic"
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PhoneIcon color="black" />
                                    </InputAdornment>
                                ),
                            }}
                            style={{ width: '545px', fontSize: '24px' }}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </div>
                    {isError && (
                        <Typography color="error" className="pb-5">
                            연락처가 일치하지 않습니다. 다시 입력해주세요.
                        </Typography>
                    )}
                    <Button
                        variant="contained"
                        color="primary"
                        style={{
                            backgroundColor: '#526C6F',
                            color: 'white',
                            fontSize: '24px',
                            fontWeight: '400',
                            borderRadius: '20px',
                        }}
                        onClick={onClickFindId}
                    >
                        아이디 찾기
                    </Button>
                </div>
            </div>
        );
    } else {
        // console.log('Navigating to login with ID:', id); // Add logging here
        return (
            <div className="flex w-full">
                <div className="flex w-full items-center justify-center flex-col">
                    <Typography fontWeight="bold" className="pb-5">
                        아이디 : {id}
                    </Typography>
                    <Button
                        className="pt-5"
                        variant="contained"
                        color="primary"
                        style={{ backgroundColor: '#526C6F', color: 'white' }}
                        onClick={() => navigate('/login')}
                    >
                        로그인
                    </Button>
                </div>
            </div>
        );
    }
};
export default FindID;
