import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import VerticalNavbar from '../components/navbar/SOFNavbar';

const RMFLayout = () => {
    const [isNavExpanded, setIsNavExpanded] = useState(true);

    const toggleNavbar = () => {
        setIsNavExpanded(!isNavExpanded);
    };

    return (
        // <div className="flex flex-grow">
        //     <aside
        //         className={`transition-width duration-300 `}
        //     >
        //         <VerticalNavbar
        //             isExpanded={isNavExpanded}
        //             toggleNavbar={toggleNavbar}
        //         />
        //     </aside>
        //     <main className="flex-grow p-2 overfslow-auto">
        //         <div className="flex flex-col w-full h-full space-y-2">
        //             <div className="flex flex-col justify-start w-full h-full flex-grow">
                        <Outlet />
        //             {/* </div>
        //         </div>
        //     </main>
        // </div> */}
    );
};

export default RMFLayout;
