import React from 'react';

import ReactPlayer from 'react-player/lazy';

import firstmovie from '../../../assets/movie/(21)S-1_사례영상.mp4';
import secondmovie from '../../../assets/movie/(29)S-2_사례영상.mp4';
import thirdmovie from '../../../assets/movie/(43)O-1_사례영상.mp4';
import fourthmovie from '../../../assets/movie/(47)O-1_활동 1. 자기자비 명상.mp4';
import fifthmovie from '../../../assets/movie/(52)O-2_사례영상.mp4';
import sixthmovie from '../../../assets/movie/(64)F-1_사례영상.mp4';
import seventhmovie from '../../../assets/movie/(74)F-2_사례영상.mp4';

const movielist = [firstmovie, secondmovie, thirdmovie, fourthmovie, fifthmovie, sixthmovie, seventhmovie];

const MoviePlayer = ({index, autoplay}) => {
    return (
        <>
            <div className='player-wrapper' style={{width:'100%'}}>
                <ReactPlayer
                    className='react-player'
                    url={movielist[index]}    // 플레이어 url
                    width='100%'         // 플레이어 크기 (가로)
                    height='auto'        // 플레이어 크기 (세로)
                    playing={autoplay}        // 자동 재생 on
                    muted={false}          // 자동 음소거 off
                    controls={true}       // 플레이어 컨트롤 노출 여부
                    light={false}         // 플레이어 모드
                    pip={true}            // pip 모드 설정 여부
                    // poster={'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/images/BigBuckBunny.jpg'}   // 플레이어 초기 포스터 사진
                    // onEnded={() => handleVideo()}  // 플레이어 끝났을 때 이벤트
                />
            </div>
        </>
    )
}

export default MoviePlayer;