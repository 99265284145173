import AppConfig from '../core/config';

import { fetchRefresh } from './AuthServices';

const fetchBoard = async () => {
    const url = `${AppConfig.api_base_url}/board/display`;

    const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
    });

    return response;
};

const fetchBoardByPid = async (pid) => {
    const url = `${AppConfig.api_base_url}/board/display/${pid}`;

    const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
    });

    return response;
};

const fetchBoardContent = async (boardContent) => {
    const url = `${AppConfig.api_base_url}/board/save`;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(boardContent),
    });

    return response;
};

const fetchUpdateBoardByPid = async (pid, boardContent) => {
    const url = `${AppConfig.api_base_url}/board/update/${pid}`;

    const response = await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(boardContent),
    });

    return response;
};

const fetchUpdateBoardPhoto = async (pid, files) => {
    const url = `${AppConfig.api_base_url}/board/update-photo/${pid}`;

    const response = await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(files),
    });

    return response;
};
/* 
const fetchUpdateBoardNotice = async (checked) => {
    const url = `${AppConfig.api_base_url}/board/notice`;

    const response = await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(checked),

    });

    return response;
};
 */
const fetchUpdateBoardNotice = async (checked, is_notice) => {
    const url = `${AppConfig.api_base_url}/board/notice`;

    const response = await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ checked: checked, is_notice: is_notice }),
    });

    return response;
};

const fetchUploadPhoto = async (formData) => {
    const url = `${AppConfig.api_base_url}/board/upload`;
    const response = await fetch(url, {
        method: 'POST',
        body: formData,
        credentials: 'include',
    });
    return response;
};

const fetchDeleteBoardByPid = async (pid) => {
    const url = `${AppConfig.api_base_url}/board/delete/${pid}`;

    const response = await fetch(url, {
        method: 'DELETE',
        credentials: 'include',
    });

    return response;
};

const fetchDeleteMultipleBoard = async (checked) => {
    const url = `${AppConfig.api_base_url}/board/multiple`;
    const response = await fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(checked),
    });

    return response;
};

const fetchCommentByPid = async (pid) => {
    const url = `${AppConfig.api_base_url}/comment/display/${pid}`;

    const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
    });

    return response;
};

const fetchCommentContent = async (commentInfo) => {
    const url = `${AppConfig.api_base_url}/comment/save`;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(commentInfo),
    });

    return response;
};

const fetchDeleteCommentByPid = async (pid) => {
    const url = `${AppConfig.api_base_url}/comment/delete-pid/${pid}`;

    const response = await fetch(url, {
        method: 'DELETE',
        credentials: 'include',
    });

    return response;
}

const fetchDeleteCommentByCid = async (cid) => {
    const url = `${AppConfig.api_base_url}/comment/delete-cid/${cid}`;

    const response = await fetch(url, {
        method: 'DELETE',
        credentials: 'include',
    });

    return response;
}

const fetchUpdateCommentByCid = async (cid, commentContent) => {
    const url = `${AppConfig.api_base_url}/comment/update/${cid}`;

    const response = await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(commentContent),
    });

    return response;
}

const fetchDeleteMultipleComment = async (checked) => {
    const url = `${AppConfig.api_base_url}/comment/multiple`;
    const response = await fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(checked),
    });

    return response;
}

export {
    fetchBoard,
    fetchBoardByPid,
    fetchBoardContent,
    fetchUpdateBoardByPid,
    fetchUpdateBoardPhoto,
    fetchUpdateBoardNotice,
    fetchUploadPhoto,
    fetchDeleteBoardByPid,
    fetchDeleteMultipleBoard,
    fetchCommentByPid,
    fetchCommentContent,
    fetchDeleteCommentByPid,
    fetchDeleteCommentByCid,
    fetchUpdateCommentByCid,
    fetchDeleteMultipleComment,
};