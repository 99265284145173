import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, IconButton } from '@mui/material';
import textBox from './Module/textBox';
import Letter from './Module/Letter';
import ReadLetter from './Module/ReadLetter';
import MoviePlayer from './Module/MoviePlayer';
import MP3Player from './Module/MP3Player';
import Text2Module from './Module/Text2';
import Text3Module from './Module/Text3';
import Quiz2Moudle from './Module/Quiz2';
import Quiz3Module from './Module/Quiz3';
import Quiz4Module from './Module/Quiz4';
import Quiz5Module from './Module/Quiz5';
import Quiz6Module from './Module/Quiz6';
import Quiz7Module from './Module/Quiz7';
import Quiz8Module from './Module/Quiz8';
import Quiz9Module from './Module/Quiz9';
import Quiz10Module from './Module/Quiz10';
import Quiz11Module from './Module/Quiz11';
import Quiz12Module from './Module/Quiz12';
import FeedbackModule from './Module/feedbackModule';
import Letter2 from './Module/Letter2';
import ReadLetter2 from './Module/ReadLetter2';

import rectangleimage from '../../assets/images/sofimage/image.png';
import mindteaching from '../../assets/images/newsofimage/mindTeaching.png';
import pinkbackground from '../../assets/images/newsofimage/asd.png';

import ForwardIcon from '../../assets/images/ui/2. forward.png';

import { fetchUpdateUserSOF } from '../../services/UserServices';
import {
    fetchSetEndTime,
    loggingSOFAccess,
} from '../../services/SOFLogServices';
import { doc } from 'prettier';

function importAllImages(r) {
    let images = {};
    r.keys().map((item, index) => {
        images[item.replace('./', '').replace(/\.[^/.]+$/, '')] = r(item);
    });
    return images;
}

const importImage = importAllImages(
    require.context(
        '../../assets/images/newsofimage',
        false,
        /\.(png|jpe?g|svg)$/,
    ),
);

const parsecontent = (content, images) => {
    const lines = content.split('\n'); // 줄바꿈 기준으로 분리
    let imageIndex = 0; // 이미지 인덱스 초기화

    return lines.map((line, index) => {
        if (line.includes('##IMAGE##')) {
            // 이미지 태그를 생성
            const imgTMP = importImage[images[imageIndex]];
            imageIndex++; // 이미지 인덱스를 증가시켜 다음 이미지 사용
            if (images[0] === '9_상황') {
                return (
                    <div key={index} style={{ textAlign: 'center' }}>
                        <img
                            src={mindteaching}
                            alt={images[imageIndex - 1]}
                            style={{
                                width: '100%',
                                display: 'block',
                            }}
                        />
                    </div>
                );
            }
            if (images[0] === '18_나전달법 배경') {
                return (
                    <div
                        key={index}
                        style={{
                            backgroundImage: `url(${pinkbackground})`,
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            width: '90%',
                            paddingTop: '50px',
                            paddingBottom: '50px',
                            paddingLeft: '30px',
                            textAlign: 'left',
                        }}
                    >
                        <Typography sx={{ ml: 1, mb: 1, fontSize: '20pt' }}>
                            <b>·</b> 상황 : 상황을 객관적으로 묘사합니다.
                        </Typography>
                        <Typography sx={{ ml: 1, mb: 1, fontSize: '20pt' }}>
                            <b>·</b> 나의 감정 : 상대방의 행동으로 인해 내가
                            느낀 감정을 명확하게 표현합니다.
                        </Typography>
                        <Typography sx={{ ml: 1, mb: 1, fontSize: '20pt' }}>
                            <b>·</b> 내게 준 영향 : 상대방의 행동이 나에게 어떤
                            영향을 미쳤는지 설명합니다.
                        </Typography>
                        <Typography sx={{ ml: 1, mb: 1, fontSize: '20pt' }}>
                            <b>·</b> 내가 필요한 것 : 상대방에게 원하는 행동이나
                            기대하는 것을 구체적으로 제안합니다.
                        </Typography>
                    </div>
                );
            }
            return (
                <div key={index} style={{ textAlign: 'center' }}>
                    <img
                        src={imgTMP}
                        alt={images[imageIndex - 1]}
                        style={{
                            maxHeight: '35vh',
                            display: 'block',
                        }}
                    />
                </div>
            );
        }

        // 텍스트 부분 처리
        const parts = line.split('*');
        return (
            <Typography
                key={index}
                style={{ whiteSpace: 'pre-line', fontSize: '20pt' }}
                sx={{ mb: 2 }}
            >
                {parts.map((part, idx) =>
                    idx % 2 === 1 ? (
                        <u key={idx} style={{ textUnderlineOffset: '5px' }}>
                            {part}
                        </u>
                    ) : (
                        part
                    ),
                )}
                <br />
            </Typography>
        );
    });
};

const SOF = ({
    step,
    setStep,
    doc,
    sof,
    setSOF,
    answer,
    setAnswer,
    finishSOF,
    setIsFirst,
    isFirst,
    fetchGetAllSecond,
    handleSubmitAnswer,
    type,
    secondFinishCheck,
}) => {
    const navigate = useNavigate();
    const captureRef = useRef(null);

    useEffect(() => {
        let first = isFirst ? '1' : '2';
        loggingSOFAccess(first + '-' + type + '-' + step + '-in').then(
            (response) => {
                if (response.ok) {
                    if (doc.type === 'finish') {
                        // fetchSetEndTime(type);
                    }
                }
            },
        );
        fetchUpdateUserSOF(step, type);
    }, [step]);

    const handleInputChange = async (index, event) => {
        // Assuming you want to update the answer at a specific index
        await setAnswer((prevAnswers) => {
            const newAnswers = [...prevAnswers];
            newAnswers[index] = event.target.value;
            return newAnswers;
        });
        await handleSubmitAnswer();
    };

    const handleNext = async () => {
        // answer list 중에 하나라도 ''이면
        // console.log(doc.quiz.length);
        // if (answer.includes('')) {
        //     console.log('안돼. 돌아가');
        // } else {
        await handleSubmitAnswer();
        let first = isFirst ? '1' : '2';
        loggingSOFAccess(first + '-' + type + '-' + step + '-out').then(
            (response) => {
                if (response.ok) {
                    if (doc.type === 'feedback') {
                        fetchSetEndTime(type);
                    }
                    setStep(step + 1);
                }
            },
        );
    };

    const handlePrev = () => {
        if (step > 0) {
            setStep(step - 1);
        }
    };

    const blueInfo = () => {
        return (
            <Typography sx={{ my: 5, color: '#191FBF', fontSize: '20px' }}>
                작성하신 내용은 마이페이지 &apos;나의 활동&apos;에서 확인실 수
                있습니다.
            </Typography>
        );
    };

    const YoutubeSAVE = () => {
        return (
            <>
                <MoviePlayer index={doc.content} autoplay={true} />
            </>
            // <YouTube
            //     videoId={parseVideoId(doc.content)}
            //     opts={{
            //         height: '390',
            //         width: '640',
            //         playerVars: {
            //             autoplay: 1,
            //         },
            //     }}
            // />
        );
    };

    const Text = () => {
        return <>{parsecontent(doc.content, doc.images)}</>;
    };

    const Text2 = () => {
        return (
            <>
                <Text2Module
                    content={doc.content}
                    subtitle={doc.subtitle}
                    subcontent={doc.subcontent}
                />
            </>
        );
    };

    const Text3 = () => {
        return (
            <>
                <Text3Module
                    content={doc.content}
                    subtitle={doc.subtitle}
                    quiz={doc.quiz}
                />
            </>
        );
    };

    const Text4 = () => {
        return (
            <>
                {parsecontent(doc.content, doc.images)}
                <Typography
                    variant="caption"
                    sx={{ position: 'absolute', bottom: '5px', right: '5px' }}
                >
                    {doc.from_}
                </Typography>
            </>
        );
    };

    const Quiz = () => {
        return (
            <>
                {parsecontent(doc.content)}
                {textBox(
                    doc.subtitle,
                    doc.subcontent,
                    doc.quiz,
                    handleInputChange,
                    answer,
                )}
                <br />
            </>
        );
    };

    const Quiz2 = () => {
        return (
            <>
                {parsecontent(doc.content)}
                <div style={{ marginTop: '50px' }}>
                    <Quiz2Moudle
                        quiz={doc.quiz}
                        handleInputChange={handleInputChange}
                        answer={answer}
                    />
                </div>
            </>
        );
    };

    const Quiz3 = () => {
        return (
            <>
                <Quiz3Module
                    content={doc.content}
                    subtitle={doc.subtitle}
                    subcontent={doc.subcontent}
                    quiz={doc.quiz}
                    answer={answer}
                    setAnswer={setAnswer}
                    handleInputChange={handleInputChange}
                />
            </>
        );
    };

    const Quiz4 = () => {
        return (
            <>
                <Quiz4Module
                    content={doc.content}
                    subtitle={doc.subtitle}
                    subcontent={doc.subcontent}
                    quiz={doc.quiz}
                    answer={answer}
                    setAnswer={setAnswer}
                    handleInputChange={handleInputChange}
                />
            </>
        );
    };

    const Quiz5 = () => {
        return (
            <>
                <Quiz5Module
                    content={doc.content}
                    subtitle={doc.subtitle}
                    subcontent={doc.subcontent}
                    quiz={doc.quiz}
                    from_={doc.from_}
                    answer={answer}
                    handleInputChange={handleInputChange}
                    setAnswer={setAnswer}
                />
            </>
        );
    };

    const Quiz6 = () => {
        return (
            <>
                <Quiz6Module
                    content={parsecontent(doc.content, doc.images)}
                    subtitle={doc.subtitle}
                    subcontent={doc.subcontent}
                    quiz={doc.quiz}
                    answer={answer}
                    setAnswer={setAnswer}
                    handleInputChange={handleInputChange}
                />
            </>
        );
    };

    const Quiz7 = () => {
        return (
            <>
                <Quiz7Module
                    content={doc.content}
                    subtitle={doc.subtitle}
                    quiz={doc.quiz}
                    answer={answer}
                    handleInputChange={handleInputChange}
                    from_={doc.from_}
                />
            </>
        );
    };

    const Quiz8 = () => {
        return (
            <>
                <Quiz8Module
                    content={parsecontent(doc.content)}
                    subcontent={parsecontent(doc.subcontent)}
                    quiz={doc.quiz}
                    answer={answer}
                    setAnswer={setAnswer}
                    handleInputChange={handleInputChange}
                />
            </>
        );
    };

    const Quiz9 = () => {
        return (
            <>
                <Quiz9Module
                    content={doc.content}
                    subcontent={doc.subcontent}
                    subtitle={doc.subtitle}
                    quiz={doc.quiz}
                    answer={answer}
                    handleInputChange={handleInputChange}
                />
                {doc.images && doc.images.length > 0 ? (
                    <div style={{ textAlign: 'center', margin: '20px 0' }}>
                        <br />
                        <img
                            src={importImage[doc.images[0]]}
                            alt={doc.images[0]}
                            style={{
                                maxHeight: '35vh',
                                display: 'block',
                                margin: 'auto',
                            }}
                        />
                    </div>
                ) : null}
            </>
        );
    };

    const Quiz10 = () => {
        return (
            <>
                <Quiz10Module
                    content={parsecontent(doc.content)}
                    subtitle={doc.subtitle}
                    subcontent={parsecontent(doc.subcontent)}
                    quiz={doc.quiz}
                    answer={answer}
                    handleInputChange={handleInputChange}
                    images={doc.images}
                />
                {doc.images && doc.images.length > 0 ? (
                    <div style={{ textAlign: 'center', margin: '20px 0' }}>
                        <br />
                        <img
                            src={importImage[doc.images[0]]}
                            alt={doc.images[0]}
                            style={{
                                maxHeight: '20vh',
                                display: 'block',
                                margin: 'auto',
                            }}
                        />
                    </div>
                ) : null}
            </>
        );
    };

    const Quiz11 = () => {
        return (
            <>
                <Quiz11Module
                    content={parsecontent(doc.content)}
                    subtitle={doc.subtitle}
                    subcontent={parsecontent(doc.subcontent)}
                    quiz={doc.quiz}
                    answer={answer}
                    handleInputChange={handleInputChange}
                />
            </>
        );
    };

    const Quiz12 = () => {
        return (
            <>
                <Quiz12Module
                    content={doc.content}
                    subtitle={doc.subtitle}
                    subcontent={doc.subcontent}
                    quiz={doc.quiz}
                    answer={answer}
                    setAnswer={setAnswer}
                    handleInputChange={handleInputChange}
                />
                {doc.images && doc.images.length > 0 ? (
                    doc.images[0] === '5_생각구름' ? (
                        <div style={{ textAlign: 'center' }}>
                            <br />
                            <img
                                src={importImage[doc.images[0]]}
                                alt={doc.images[0]}
                                style={{
                                    maxHeight: '35vh',
                                    display: 'block',
                                    marginTop: '-70px',
                                }}
                            />
                        </div>
                    ) : (
                        <div style={{ textAlign: 'center' }}>
                            <br />
                            <img
                                src={importImage[doc.images[0]]}
                                alt={doc.images[0]}
                                style={{
                                    maxHeight: '35vh',
                                    display: 'block',
                                }}
                            />
                        </div>
                    )
                ) : null}
            </>
        );
    };

    const Feedback = () => {
        return (
            <>
                <FeedbackModule
                    content={parsecontent(doc.content)}
                    quiz={doc.quiz}
                    answer={answer}
                    handleInputChange={handleInputChange}
                    setAnswer={setAnswer}
                    handleNext={handleNext}
                />
            </>
        );
    };

    const LetterType = () => {
        return (
            <>
                {parsecontent(doc.content)}
                <div style={{ textAlign: 'left', width: '90%' }}>
                    <Letter
                        context={doc.subcontent}
                        from={doc.from_}
                        handleInputChange={handleInputChange}
                        answer={answer}
                    />
                </div>
            </>
        );
    };

    const Letter2Type = () => {
        return (
            <>
                {parsecontent(doc.content)}
                <div style={{ textAlign: 'left', width: '90%' }}>
                    <Letter2
                        handleInputChange={handleInputChange}
                        answer={answer}
                    />
                </div>
            </>
        );
    };

    const ReadLetterType = () => {
        return (
            <>
                {parsecontent(doc.content)}
                <div
                    ref={captureRef}
                    style={{ textAlign: 'left', width: '90%' }}
                >
                    <ReadLetter
                        context={doc.subcontent}
                        from={doc.from_}
                        answer={answer}
                    />
                </div>
            </>
        );
    };

    const ReadLetter2Type = () => {
        return (
            <>
                {parsecontent(doc.content)}
                <div style={{ textAlign: 'left', width: '90%' }}>
                    <ReadLetter2 answer={answer} />
                </div>
            </>
        );
    };

    const End = () => {
        return (
            <>
                {parsecontent(doc.content)}
                <div>
                    <Button
                        onClick={handleNext}
                        sx={{
                            backgroundColor: '#526C6F',
                            color: '#FFFFFF',
                            '&:hover': {
                                backgroundColor: '#003F5E',
                            },
                            '&:active': {
                                backgroundColor: '#004777',
                            },
                            ml: 5,
                            mt: 10,
                            mb: type === 'f' && isFirst ? 5 : 0,
                            mr: 20,
                            paddingX: '24px',
                            fontSize: '24pt',
                            borderRadius: '15px',
                        }}
                    >
                        다음에 하기
                    </Button>
                    <Button
                        onClick={() => {
                            let first = isFirst ? '1' : '2';
                            loggingSOFAccess(
                                first + '-' + type + '-' + step + '-out',
                            );
                            fetchGetAllSecond().then((response) => {
                                if (response.ok) {
                                    response
                                        .json()
                                        .then((data) => {
                                            if (secondFinishCheck) {
                                                const tmp_data = data.filter(
                                                    (item) =>
                                                        item.type !==
                                                        'feedback',
                                                );
                                                data = tmp_data;
                                            }
                                            setSOF(data);
                                            setStep(0);
                                            return data;
                                        })
                                        .then((data) => {
                                            finishSOF();
                                            console.log(data);
                                            setIsFirst(false);
                                        });
                                }
                            });
                        }}
                        sx={{
                            backgroundColor: '#526C6F',
                            color: '#FFFFFF',
                            '&:hover': {
                                backgroundColor: '#003F5E',
                            },
                            '&:active': {
                                backgroundColor: '#004777',
                            },
                            mt: 10,
                            mr: 5,
                            mb: type === 'f' && isFirst ? 5 : 0,
                            paddingX: '24px',
                            fontSize: '24pt',
                            borderRadius: '15px',
                        }}
                    >
                        {type === 's' && isFirst
                            ? '2부 시작하기'
                            : type === 'o' && isFirst
                              ? '4부 시작하기'
                              : type === 'f' && isFirst
                                ? '6부 시작하기'
                                : '2부 시작하기'}
                    </Button>
                    <Typography
                        variant="caption"
                        sx={{
                            position: 'absolute',
                            bottom: '5px',
                            right: '5px',
                        }}
                    >
                        {doc.from_}
                    </Typography>
                </div>
            </>
        );
    };

    const Finished = () => {
        return (
            <>
                {parsecontent(doc.content)}
                <Button
                    onClick={() => {
                        let first = isFirst ? '1' : '2';
                        loggingSOFAccess(
                            first + '-' + type + '-' + step + '-out',
                        );

                        if (type === 'f' && !isFirst) {
                            if(secondFinishCheck){
                                navigate('/sof');
                            } else {
                                fetchUpdateUserSOF('postsurvey');
                                navigate('/SOF/post-survey');
                                return;
                            }
                        } else {
                            // fetchUpdateUserSOF(0);
                            navigate('/sof');
                        }
                        finishSOF();
                    }}
                    sx={{
                        backgroundColor: '#526C6F',
                        color: '#FFFFFF',
                        '&:hover': {
                            backgroundColor: '#003F5E',
                        },
                        '&:active': {
                            backgroundColor: '#004777',
                        },
                        mt: 12,
                        width: '184pt',
                        height: '48pt',
                        fontSize: '24pt',
                        borderRadius: '15px',
                    }}
                >
                    확인
                </Button>
            </>
        );
    };

    return (
        <div className="flex justify-center items-center">
            <div
                className="flex flex-col items-center"
                style={{ width: '100%' }}
            >
                <div
                    className="background-outer flex-col items-center"
                    style={{
                        marginTop: '150px',
                        width: '982pt',
                        minHeight: '540pt',
                        height: 'auto',
                        position: 'relative',
                        borderTop: '1.5px solid #000',
                        borderBottom: '1.5px solid #000',
                        padding: '20px',
                        marginBottom: '30px',
                    }}
                >
                    <Typography
                        sx={{
                            position: 'absolute',
                            left: '0px',
                            top: '-80px',
                            mb: 2,
                            textAlign: 'left',
                            alignSelf: 'flex-start',
                            fontSize: '36pt',
                            fontWeight: 'bold',
                        }}
                    >
                        {doc.title}
                    </Typography>

                    {doc.category && (
                        <Typography
                            sx={{
                                alignSelf: 'flex-start',
                                fontSize: '24pt',
                                fontWeight: 'bold',
                                display: 'flex',
                            }}
                        >
                            <img
                                src={rectangleimage}
                                alt=""
                                style={{
                                    height: '36pt',
                                    marginRight: '10px',
                                    marginTop: '2pt',
                                }}
                            />
                            {doc.category}
                        </Typography>
                    )}
                    <IconButton
                        sx={{
                            position: 'absolute',
                            left: '-50pt',
                            top: '230pt',
                            transform: 'rotate(180deg)',
                        }}
                        disabled={step === 0 || doc.type === 'finish'}
                        onClick={handlePrev}
                    >
                        {step === 0 || doc.type === 'finish' ? null : (
                            <img
                                src={ForwardIcon}
                                alt=""
                                style={{ width: '50px' }}
                            />
                        )}
                    </IconButton>

                    {doc.type === 'finish' ||
                    doc.type === 'url' ||
                    doc.type === 'feedback' ||
                    !doc.mp3 ? null : (
                        <>
                            <MP3Player mp3={doc.mp3} />
                        </>
                    )}

                    <div
                        className="flex flex-col items-center justify-center"
                        style={{
                            width: '100%',
                            minHeight: '540pt',
                            height: '100%',
                            padding: '20px',
                            textAlign: 'center',
                        }}
                    >
                        {doc.type == 'text'
                            ? Text()
                            : doc.type == 'text2'
                              ? Text2()
                              : doc.type == 'text3'
                                ? Text3()
                                : doc.type == 'text4'
                                  ? Text4()
                                  : doc.type == 'url'
                                    ? YoutubeSAVE()
                                    : doc.type == 'quiz'
                                      ? Quiz()
                                      : doc.type == 'quiz2'
                                        ? Quiz2()
                                        : doc.type == 'quiz3'
                                          ? Quiz3()
                                          : doc.type == 'quiz4'
                                            ? Quiz4()
                                            : doc.type == 'quiz5'
                                              ? Quiz5()
                                              : doc.type == 'quiz6'
                                                ? Quiz6()
                                                : doc.type == 'quiz7'
                                                  ? Quiz7()
                                                  : doc.type == 'quiz8'
                                                    ? Quiz8()
                                                    : doc.type == 'quiz9'
                                                      ? Quiz9()
                                                      : doc.type == 'quiz10'
                                                        ? Quiz10()
                                                        : doc.type == 'quiz11'
                                                          ? Quiz11()
                                                          : doc.type == 'quiz12'
                                                            ? Quiz12()
                                                            : doc.type == 'end'
                                                              ? End()
                                                              : doc.type ==
                                                                  'letter'
                                                                ? LetterType()
                                                                : doc.type ==
                                                                    'readLetter'
                                                                  ? ReadLetterType()
                                                                  : doc.type ==
                                                                      'feedback'
                                                                    ? Feedback()
                                                                    : doc.type ==
                                                                        'finish'
                                                                      ? Finished()
                                                                      : doc.type ==
                                                                          'letter2'
                                                                        ? Letter2Type()
                                                                        : doc.type ==
                                                                            'readletter2'
                                                                          ? ReadLetter2Type()
                                                                          : null}
                        {/* {doc.blue ? blueInfo() : null} */}
                    </div>
                    <IconButton
                        sx={{
                            position: 'absolute',
                            right: '-50pt',
                            top: '230pt',
                        }}
                        disabled={
                            doc.type === 'end' ||
                            doc.type === 'finish' ||
                            doc.type === 'feedback'
                        }
                        onClick={handleNext}
                    >
                        {doc.type === 'end' ||
                        doc.type === 'finish' ||
                        doc.type === 'feedback' ? null : (
                            <img
                                src={ForwardIcon}
                                alt=""
                                style={{ width: '50px' }}
                            />
                        )}
                    </IconButton>
                </div>
            </div>
        </div>
    );
};

export default SOF;
