import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import RedirectionRoute from '../components/route/RedirectionRoute';
import ProtectedRoute from '../components/route/ProtectedRoute';

import MainLayout from '../layout/MainLayout';
import ErrorLayout from '../layout/ErrorLayout';
import SOFLayout from '../layout/SOFLayout';

import Home from '../pages/Home/Home';
import Help from '../pages/Help/Help';

// Authentication
import Login from '../pages/Authentication/Login';
import Agreement from '../pages/Authentication/Signup_Agreement';
import Signup from '../pages/Authentication/Signup';
import FindID from '../pages/Authentication/FindId';
import FindPassword from '../pages/Authentication/FindPassword';
import UpdatePassword from '../pages/Authentication/UpdatePassword';

// Policy & Terms
import PrivacyPolicy from '../pages/PolicyTerms/PrivacyPolicy';
import TermsOfPolicy from '../pages/PolicyTerms/TermsOfService';

// Logout
import Logout from '../pages/Logout/Logout';
import LogoutAlert from '../pages/Logout/LogoutAlert';

// SOF
import Survey from '../pages/Survey/Survey';
import SOFStart from '../pages/SOF/StartSOF';
import S from '../pages/SOF/S/S';
import O from '../pages/SOF/O/O';
import F from '../pages/SOF/F/F';
import Feedback from '../pages/SOF/Feedback';
import PreSurvey from '../pages/SOF/PreSurvey';
import Certificate from '../pages/SOF/Certificate/Certificate';
import PostFeedback from '../pages/SOF/PostFeedback';
import SDownload from '../pages/SOF/S/SDownload';
import ODownload from '../pages/SOF/O/ODownloads';
import FDownload from '../pages/SOF/F/FDownload';
import SView from '../pages/SOF/S/SView';
import OView from '../pages/SOF/O/OView';
import FView from '../pages/SOF/F/FView';

// Mypage
import PostSurvey from '../pages/SOF/PostSurvey';
import MyPage from '../pages/MyPage/MyPage';
import MypageCert from '../pages/SOF/Certificate/MypageCert';

//admin
import AdminSOF from '../pages/Admin/AdminSOF';
import UsersInfo from '../pages/Admin/UsersInfo';
import AccessInfo from '../pages/Admin/AccessInfo';
import SurveyInfo from '../pages/Admin/SurveyInfo';
import HomeworkInfo from '../pages/Admin/HomeworkInfo';
import AdminLayout from '../layout/AdminLayout';

//board
import Board from '../pages/Board/Board';
import WriteBoard from '../pages/Board/WriteBoard';
import ContentBoard from '../pages/Board/ContentBoard';
import ModifyBoard from '../pages/Board/ModifyBoard';
import ModuleUpdate from '../pages/Admin/ModuleUpdate';

const router = createBrowserRouter([
    {
        path: '/',
        element: <MainLayout />,
        errorElement: <ErrorLayout />,
        children: [
            {
                index: true,
                element: <RedirectionRoute />,
            },
            { path: '/login', element: <Login /> },
            {
                path: '/signup',
                children: [
                    {
                        path: '',
                        index: true,
                        element: <Signup />,
                    },
                    {
                        path: 'agreement',
                        element: <Agreement />,
                    },
                ],
            },
            { path: 'logout', element: <Logout /> },
            { path: 'update-password', element: <UpdatePassword /> },
            { path: 'find-Password', element: <FindPassword /> },
            { path: 'find-Id', element: <FindID /> },
            { path: '/help', element: <Help /> },
            {
                path: 'policy',
                children: [
                    {
                        path: 'privacy',
                        element: <PrivacyPolicy />,
                    },
                    {
                        path: 'terms',
                        element: <TermsOfPolicy />,
                    },
                ],
            },
            {
                element: (
                    <ProtectedRoute require_admin={false}></ProtectedRoute>
                ),
                children: [
                    {
                        path: '/home',
                        element: <Home />,
                    },
                    {
                        path: '/mypage',
                        children: [
                            {
                                path: '',
                                element: <MyPage />,
                            },
                            {
                                path: 'certificate',
                                element: <MypageCert />,
                            },
                        ],
                    },
                    {
                        path: '/logout',
                        children: [
                            {
                                path: 'alert',
                                element: <LogoutAlert />,
                            },
                        ],
                    },
                    {
                        path: '/SOF',
                        // index: true,
                        // element: <SOFStart />,
                        children: [
                            {
                                path: '',
                                element: <SOFStart />,
                            },
                            {
                                path: 'PreSurvey',
                                element: <PreSurvey />,
                            },
                            { path: 'Feedback', element: <Feedback /> },
                            { path: 'certificate', element: <Certificate /> },
                            { path: 'post-survey', element: <PostSurvey /> },
                            {
                                path: 'post-feedback',
                                element: <PostFeedback />,
                            },
                        ],
                    },
                    {
                        path: 'survey',
                        element: <SOFLayout />,

                        children: [
                            {
                                index: true,
                                element: <SOFStart />,
                            },
                            {
                                path: 'survey',
                                element: <SOFLayout />,
                                children: [
                                    {
                                        index: true,
                                        element: <Survey />,
                                    },
                                ],
                            },
                            {
                                path: 'S',
                                element: <S />,
                            },
                            {
                                path: 'O',
                                element: <O />,
                            },
                            {
                                path: 'F',
                                element: <F />,
                            },
                            // { path: '/:id', element: <SOF /> },
                        ],
                    },
                    { path: '/Feedback', element: <Feedback /> },
                    { path: '/PreSurvey', element: <PreSurvey /> },
                ],
            },
            {
                path: '/admin',
                element: <ProtectedRoute require_admin={true}></ProtectedRoute>,
                children: [
                    {
                        path: 'home',
                        element: <AdminSOF />,
                    },
                    {
                        path: 'usersinfo',
                        element: <UsersInfo />,
                    },
                    {
                        path: 'activityinfo',
                        element: <AdminLayout />,
                        children: [
                            {
                                path: 'accessinfo',
                                element: <AccessInfo />,
                            },
                            {
                                path: 'surveyinfo',
                                element: <SurveyInfo />,
                            },
                            {
                                path: 'homeworkinfo',
                                element: <HomeworkInfo />,
                            },
                            {
                                path: 's/:uid/:s1ors2/:sid',
                                element: <SView />,
                            },
                            {
                                path: 'o/:uid/:o1oro2/:oid',
                                element: <OView />,
                            },
                            {
                                path: 'f/:uid/:f1orf2/:fid',
                                element: <FView />,
                            },
                        ],
                    },
                    {
                        path: 'moduleinfo',
                        element: <ModuleUpdate />,
                    },
                ],
            },
            //{ path: 'board', element: <Board/>},
            //  { path: 'write-board', element: <WriteBoard/>},
            {
                path: '/download',
                children: [
                    {
                        path: 's/:s1ors2/:sid',
                        element: <SDownload />,
                    },
                    {
                        path: 'o/:o1oro2/:oid',
                        element: <ODownload />,
                    },
                    {
                        path: 'f/:f1orf2/:fid',
                        element: <FDownload />,
                    },
                ],
            },
            {
                path: '/board',
                children: [
                    {
                        path: '',
                        index: true,
                        element: <Board />,
                    },
                    {
                        path: 'content-board/:pid',
                        element: <ContentBoard />,
                    },
                    {
                        path: 'write-board',
                        element: <WriteBoard />,
                    },
                    {
                        path: 'modify-board/:pid',
                        element: <ModifyBoard />,
                    },
                ],
            },
        ],
    },
]);

export default router;
