import AppConfig from '../core/config';

import { fetchRefresh } from './AuthServices';

const fetchSurvey = async () => {
    const url = `${AppConfig.api_base_url}/survey/example`;

    const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
    });

    return response;
};

const fetchSurveyResult = async (result) => {
    const url = `${AppConfig.api_base_url}/result/save`;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(result),
    });

    return response;
};

const fetchPostSurveyResult = async (result) => {
    const url = `${AppConfig.api_base_url}/post/save`;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(result),
    });

    return response;
};

const fetchSurveyResultScore = async (uid) => {
    const url = `${AppConfig.api_base_url}/result/score`;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uid: uid }),
    });

    return response;
};

const fetchPostSurveyResultScore = async (uid) => {
    const url = `${AppConfig.api_base_url}/post/score`;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uid: uid }),
    });

    return response;
};

export {
    fetchSurvey,
    fetchSurveyResult,
    fetchPostSurveyResult,
    fetchSurveyResultScore,
    fetchPostSurveyResultScore,
};
