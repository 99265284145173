import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

// 확장 안되는 테두리
export default function Box3({title, content, children}) {
  return (
    <div style={{width:'100%', alignSelf:'center'}}>
        <div
          style={{ backgroundColor: '#5F6F5F66', marginTop:'30pt', paddingLeft:'15px', paddingTop:'5px', paddingBottom:'5px', borderLeft: '2px solid #000', borderRight: '2px solid #000', borderTop:'2px solid #000'}}
        >
          <Typography sx={{fontSize:'24pt', textAlign:'left'}}>{title}</Typography>
        </div>
        <div
            style={{ backgroundColor: '#FFF', borderLeft:'2px solid #000', borderRight:'2px solid #000', borderBottom:'2px solid #000', padding:'20px'}}
        >
          <Typography sx={{textAlign:'left', fontSize:'20pt'}}>
            {content}
            {children}
          </Typography>
        </div>
    </div>
  );
}