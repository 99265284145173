import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import DownIcon from '../../../assets/images/ui/5. down.png';

// 확장 및 축소 가능한 테두리
export default function Box2({icon, title, content}) {
  return (
    <div style={{marginTop:'25px', width:'100%'}}>
      <Accordion>
        <AccordionSummary
          expandIcon={
              <img src={DownIcon} alt="Up Arrow" style={{ width: '36px', height: '41px', padding:0 }} />
          }
          sx={{ backgroundColor: '#5F6F5F66', border: '1px solid #000', height: '60px'}}
        >
          {icon && <img src={icon} alt="icon" style={{ width: '45px', height: '45px', marginRight: '10px' }} />}
          <Typography sx={{fontSize:'24pt'}}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails
            sx={{ backgroundColor: '#FFF', border: '1px solid #000'}}
        >
          <Typography sx={{fontSize:'20pt'}}>
            {content}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}