import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Typography } from '@mui/material';

import agreement1 from '../../assets/images/policy/이용약관001.png';
import agreement2 from '../../assets/images/policy/이용약관002.png';
import agreement3 from '../../assets/images/policy/이용약관003.png';
import agreement4 from '../../assets/images/policy/이용약관004.png';
import agreement5 from '../../assets/images/policy/이용약관005.png';

const TermsOfPolicy = () => {
    const navigate = useNavigate();

    const styles = {
        container: {
            width: '925px',
            height: '490px',
            overflow: 'auto',
        },
        image: {
            width: '100%',
            height: 'auto',
        },
    };

    return (
        <div className="flex w-full items-center justify-center flex-col">
            <Typography
                style={{
                    width: '925px',
                    fontSize: '36px',
                    fontWeight: '700',
                    textAlign: 'left',
                    marginBottom: '20px',
                }}
            >
                이용약관
            </Typography>
            <div style={styles.container}>
                <img src={agreement1} alt="agreement1" style={styles.image} />
                <img src={agreement2} alt="agreement1" style={styles.image} />
                <img src={agreement3} alt="agreement1" style={styles.image} />
                <img src={agreement4} alt="agreement1" style={styles.image} />
                <img src={agreement5} alt="agreement1" style={styles.image} />
            </div>
        </div>
    );
};
export default TermsOfPolicy;
