import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
    Button,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
} from '@mui/material';

import { fetchUsersInfo } from '../../services/AdminServices';
import { fetchGetAllSOFWithAnswer } from '../../services/SOFServices';

const s1Download = [6, 7];
const s2Download = [5, 7];
const o1Download = [6, 8];
const o2Download = [5, 7];
const f1Download = [7, 9];
const f2Download = [4, 7];


function createData(id, password, phone, birth, uid) {
    return { id, password, phone, birth, uid };
}

const homework = [
    '과제1',
    '과제2',
    '과제3',
    '과제4',
    '과제5',
    '과제6',
    '과제7',
    '과제8',
    '과제9',
    '과제10',
    '과제11',
    '과제12',
];
const number = ['1', '2', '3', '4', '5', '6'];

const HomeworkInfo = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [visibleUsers, setVisibleUsers] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [s1, setS1] = useState([]);
    const [s2, setS2] = useState([]);
    const [o1, setO1] = useState([]);
    const [o2, setO2] = useState([]);
    const [f1, setF1] = useState([]);
    const [f2, setF2] = useState([]);

    useState(() => {
        fetchGetAllSOFWithAnswer('s1').then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    // data 중에서, s1id 가 s1Download에 있는 것만 setS1에 넣어주기
                    const s1 = data.filter((s) => s1Download.includes(s.s1id));
                    setS1(s1);
                });
            }
        });

        fetchGetAllSOFWithAnswer('s2').then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    // data 중에서, s2id 가 s2Download에 있는 것만 setS2에 넣어주기
                    const s2 = data.filter((s) => s2Download.includes(s.s2id));
                    setS2(s2);
                });
            }
        });

        fetchGetAllSOFWithAnswer('o1').then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    const o1 = data.filter((o) => o1Download.includes(o.o1id));
                    setO1(o1);
                });
            }
        });

        fetchGetAllSOFWithAnswer('o2').then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    const o2 = data.filter((o) => o2Download.includes(o.o2id));
                    setO2(o2);
                });
            }
        });

        fetchGetAllSOFWithAnswer('f1').then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    const f1 = data.filter((f) => f1Download.includes(f.f1id));
                    setF1(f1);
                });
            }
        });

        fetchGetAllSOFWithAnswer('f2').then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    const f2 = data.filter((f) => f2Download.includes(f.f2id));
                    setF2(f2);
                });
            }
        });
    }, []);

    useEffect(() => {
        fetchUsersInfo().then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    createRows(data);
                    console.log(data);
                });
            } else {
                alert('Failed to fetch users info');
            }
        });
    }, []);

    const createRows = (usersInfo) => {
        const rows = [];
        usersInfo.forEach((user) => {
            rows.push(
                createData(
                    user.id,
                    user.password,
                    user.phone,
                    user.birth,
                    user.uid,
                ),
            );
        });
        setUsers(rows);
    };

    useEffect(() => {
        createVisibleRows();
    }, [page, rowsPerPage, users]);

    const createVisibleRows = () => {
        const start = page * rowsPerPage;
        const end = start + rowsPerPage;
        setVisibleUsers(users.slice(start, end));
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const goToButton = (handleOnClick) => {
        return (
            <Button
                variant="contained"
                style={{ margin: '0px', padding: '0px' }}
                sx={{
                    backgroundColor: '#004777',
                    color: '#FFFFFF',
                    '&:hover': {
                        backgroundColor: '#003F5E',
                    },
                    '&:active': {
                        backgroundColor: '#004777',
                    },
                }}
                onClick={() => handleOnClick()}
            >
                이동
            </Button>
        );
    };

    return (
        <div className="flex w-full flex-col pr-10">
            <div className="mb-7">
                <Typography fontSize="36px" fontWeight="700">
                    과제
                </Typography>
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                align="center"
                                style={{
                                    borderBottom: '0px solid',
                                    borderLeft: '1px solid',
                                    borderRight: '1px solid',
                                    borderTop: '1px solid',
                                }}
                            >
                                아이디
                            </TableCell>

                            <TableCell
                                align="center"
                                colSpan={4}
                                style={{ border: '1px  solid #000000' }}
                            >
                                S
                            </TableCell>
                            <TableCell
                                align="center"
                                colSpan={4}
                                style={{ border: '1px  solid #000000' }}
                            >
                                O
                            </TableCell>
                            <TableCell
                                align="center"
                                colSpan={4}
                                style={{ border: '1px  solid #000000' }}
                            >
                                F
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                style={{
                                    borderBottom: '0px solid',
                                    borderLeft: '1px solid',
                                    borderRight: '1px solid',
                                    borderTop: '0px',
                                }}
                            />
                            {number.map((num, index) => (
                                <TableCell
                                    align="center"
                                    style={{ border: '1px solid #000000' }}
                                    colSpan={2}
                                    key={index}
                                >
                                    {num}
                                </TableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                            <TableCell
                                style={{
                                    borderBottom: '1px solid',
                                    borderLeft: '1px solid',
                                    borderRight: '1px solid',
                                    borderTop: '0px',
                                }}
                            />
                            {homework.map((homeworkItem, index) => (
                                <TableCell
                                    align="center"
                                    style={{ border: '1px solid #000000' }}
                                    key={index}
                                >
                                    {homeworkItem}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {visibleUsers.map((user) => (
                            <TableRow
                                key={user.id}
                                sx={{
                                    '&:last-child td, &:last-child th': {
                                        border: 0,
                                    },
                                }}
                            >
                                <TableCell
                                    align="center"
                                    style={{ border: '1px solid #000000' }}
                                >
                                    {user.id}
                                </TableCell>
                                {s1.map((s1_, index) => {
                                    if (s1_.uid === user.uid) {
                                        return (
                                            <TableCell
                                                key={index}
                                                align="center"
                                                style={{
                                                    border: '1px solid #000000',
                                                }}
                                            >
                                                {goToButton(() => {
                                                    navigate(
                                                        `/admin/activityinfo/s/${user.uid}/1/${s1_.s1id}`,
                                                    );
                                                })}
                                            </TableCell>
                                        );
                                    }
                                })}
                                {s2.map((s2_, index) => {
                                    if (s2_.uid === user.uid) {
                                        return (
                                            <TableCell
                                                key={index}
                                                align="center"
                                                style={{
                                                    border: '1px solid #000000',
                                                }}
                                            >
                                                {goToButton(() => {
                                                    navigate(
                                                        `/admin/activityinfo/s/${user.uid}/2/${s2_.s2id}`,
                                                    );
                                                })}
                                            </TableCell>
                                        );
                                    }
                                })}
                                {o1.map((o1_, index) => {
                                    if (o1_.uid === user.uid) {
                                        return (
                                            <TableCell
                                                key={index}
                                                align="center"
                                                style={{
                                                    border: '1px solid #000000',
                                                }}
                                            >
                                                {goToButton(() => {
                                                    navigate(
                                                        `/admin/activityinfo/o/${user.uid}/1/${o1_.o1id}`,
                                                    );
                                                })}
                                            </TableCell>
                                        );
                                    }
                                })}
                                {o2.map((o2_, index) => {
                                    if (o2_.uid === user.uid) {
                                        return (
                                            <TableCell
                                                key={index}
                                                align="center"
                                                style={{
                                                    border: '1px solid #000000',
                                                }}
                                            >
                                                {goToButton(() => {
                                                    navigate(
                                                        `/admin/activityinfo/o/${user.uid}/2/${o2_.o2id}`,
                                                    );
                                                })}
                                            </TableCell>
                                        );
                                    }
                                })}
                                {f1.map((f1_, index) => {
                                    if (f1_.uid === user.uid) {
                                        return (
                                            <TableCell
                                                key={index}
                                                align="center"
                                                style={{
                                                    border: '1px solid #000000',
                                                }}
                                            >
                                                {goToButton(() => {
                                                    navigate(
                                                        `/admin/activityinfo/f/${user.uid}/1/${f1_.f1id}`,
                                                    );
                                                })}
                                            </TableCell>
                                        );
                                    }
                                })}
                                {f2.map((f2_, index) => {
                                    if (f2_.uid === user.uid) {
                                        return (
                                            <TableCell
                                                key={index}
                                                align="center"
                                                style={{
                                                    border: '1px solid #000000',
                                                }}
                                            >
                                                {goToButton(() => {
                                                    navigate(
                                                        `/admin/activityinfo/f/${user.uid}/2/${f2_.f2id}`,
                                                    );
                                                })}
                                            </TableCell>
                                        );
                                    }
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={users.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
            />
        </div>
    );
};
export default HomeworkInfo;
